@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital@0;1&display=swap);
.footer__wrapper {
  background: #1D1D1D;
  position: relative;
  bottom: 0;
  width: 100vw;
  max-width: 100%;
  padding: 2.5rem 8rem;
  color: white;
  padding-top: 6.5rem; }
  .footer__wrapper .border__right {
    margin: 0;
    padding: 0; }
    .footer__wrapper .border__right .text-grey {
      color: #666666;
      margin-bottom: 2rem; }
    .footer__wrapper .border__right > div {
      padding: 1% 3%;
      margin: 0 auto; }
      .footer__wrapper .border__right > div:not(:last-child) {
        border-right: 2px solid #fff; }
      .footer__wrapper .border__right > div .footer__logo {
        font-size: 1.8rem; }
      .footer__wrapper .border__right > div:first-child p {
        margin: 2rem 0;
        max-width: 80%; }
      .footer__wrapper .border__right > div .meet__wrapper .icon__wrapper {
        display: flex;
        justify-content: space-between;
        max-width: 40%; }
        .footer__wrapper .border__right > div .meet__wrapper .icon__wrapper > a > svg {
          color: #fff; }
          .footer__wrapper .border__right > div .meet__wrapper .icon__wrapper > a > svg:hover {
            cursor: pointer;
            scale: 110%; }
    .footer__wrapper .border__right .second-column a {
      display: block;
      color: white;
      margin-top: 1rem; }
      .footer__wrapper .border__right .second-column a:hover {
        text-decoration: none;
        font-size: 1.7rem; }
    .footer__wrapper .border__right .col-sm-4.col-xs-12.no-padding {
      padding: 1rem 1rem 0rem; }
    .footer__wrapper .border__right .no-decoration {
      text-decoration: none;
      color: white; }
  .footer__wrapper .copyright__container {
    margin: 3rem auto;
    font-size: 1.2rem;
    max-width: 35%;
    text-align: center; }

@media only screen and (max-width: 500px) {
  .footer__wrapper .border__right {
    border: none; }
    .footer__wrapper .border__right .col-sm-4 p {
      display: none; }
    .footer__wrapper .border__right .row {
      margin-bottom: 2rem; }
      .footer__wrapper .border__right .row .col-sm-3 {
        max-width: 30%; }
      .footer__wrapper .border__right .row .col-sm-9 {
        max-width: 30%; }
    .footer__wrapper .border__right .meet__wrapper {
      margin-left: 2rem; }
      .footer__wrapper .border__right .meet__wrapper .bold-text {
        max-width: 50%; }
      .footer__wrapper .border__right .meet__wrapper .icon__wrapper {
        max-width: 70%; }
        .footer__wrapper .border__right .meet__wrapper .icon__wrapper a {
          margin: 1rem; }
    .footer__wrapper .border__right .row .msme-footer-btn {
      margin-bottom: 1.5rem; }
    .footer__wrapper .border__right .mobile-footer {
      margin-left: 1.5rem; }
  .footer__wrapper .copyright__container {
    min-width: 90%; } }

.header__container {
  max-width: 100%;
  margin: 0 auto;
  background-color: #001061;
  height: 9rem; }
  .header__container .Credochain {
    display: inline;
    color: #001061;
    font-size: 24px;
    font-weight: 500 !important;
    margin-left: 1rem; }
  .header__container a.nav-link {
    color: #fff !important;
    margin-right: 2rem;
    font-size: 1.8rem; }
    .header__container a.nav-link:last-child {
      border-radius: 0.5rem;
      background-color: #fff;
      color: #07cec0 !important;
      padding: 0.8rem 1.9rem;
      font-weight: 500; }
  .header__container .navbar-dark {
    background: #001061;
    padding: 1rem 12rem; }
  .header__container .navbar {
    font-size: 1.8rem;
    padding: 0.5% 9%; }
    .header__container .navbar .back-button__container {
      position: absolute;
      left: 0;
      top: 12px;
      font-size: 2rem;
      cursor: pointer; }
    .header__container .navbar .no-display {
      display: none; }
    .header__container .navbar .menu-items .active {
      position: absolute;
      height: 0.6rem;
      background: #07cec0;
      width: 8.6rem; }
      .header__container .navbar .menu-items .active.msme-loans {
        bottom: -60%;
        left: 8%; }
      .header__container .navbar .menu-items .active.contact-us {
        bottom: 0%;
        height: 7px; }
    .header__container .navbar .MSMELoan_dropdown {
      background: #ffffff;
      box-shadow: 0px 0px 50px rgba(255, 252, 252, 0.16);
      border-radius: 10px;
      color: #434343;
      padding: 2rem;
      width: 350%; }
      .header__container .navbar .MSMELoan_dropdown .horizontal-line {
        border-right: 1px solid #e5e5e5;
        width: 55%; }
      .header__container .navbar .MSMELoan_dropdown .triangle {
        width: 0;
        height: 0;
        border-left: 18px solid transparent;
        border-right: 18px solid transparent;
        border-bottom: 18px solid white;
        top: -7%;
        right: 8%;
        position: absolute; }
      .header__container .navbar .MSMELoan_dropdown .MSMELoan_dropdownItems {
        margin-top: 1rem; }
        .header__container .navbar .MSMELoan_dropdown .MSMELoan_dropdownItems:hover {
          color: #001061;
          background-color: #fff;
          border-radius: 0 20rem 20rem 0;
          font-weight: 600; }

div.input-range {
  margin: 3rem 0; }
  div.input-range .input-range__label.input-range__label--min .input-range__label-container,
  div.input-range .input-range__label.input-range__label--max .input-range__label-container {
    font-size: 12px;
    position: absolute;
    margin-top: -1rem;
    color: #434343; }
  div.input-range .input-range__label.input-range__label--max {
    transform: translateX(-15px); }
  div.input-range .input-range__track.input-range__track--background {
    height: 10px;
    border-radius: 100px; }
    div.input-range .input-range__track.input-range__track--background .input-range__track--active {
      background: linear-gradient(90deg, #00CDBE 0%, #A4A1FB 100%);
      height: 10px;
      border-radius: 200px; }
    div.input-range .input-range__track.input-range__track--background .input-range__slider-container {
      margin-top: -1rem; }
      div.input-range .input-range__track.input-range__track--background .input-range__slider-container .input-range__label--value {
        top: -2.5rem;
        font-size: 12px; }
        div.input-range .input-range__track.input-range__track--background .input-range__slider-container .input-range__label--value .input-range__label-container {
          display: none; }
      div.input-range .input-range__track.input-range__track--background .input-range__slider-container .input-range__slider {
        background: #A4A1FB;
        border: 5px solid #fff;
        height: 2rem;
        width: 2rem; }

.input-box {
  position: relative; }
  .input-box.input-disabled label {
    cursor: not-allowed; }
  .input-box label {
    display: inline-block;
    position: absolute;
    left: 12px;
    top: 13px;
    transition: all 150ms ease-in;
    font-size: 14px;
    color: #bcbcbc;
    margin-bottom: 0;
    cursor: text;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
    .input-box label::selection {
      background-color: transparent; }
    .input-box label.field-active {
      transform: translateY(-20px);
      font-size: 11px;
      color: #001061;
      background-color: #fff; }
      .input-box label.field-active span {
        color: #ed5a5a;
        transform: scale(1.5);
        display: inline-block; }
  .input-box .floating-label {
    -webkit-appearance: none !important; }

.content-modal-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  height: 100vh; }
  .content-modal-overlay .overview-applicant-modal {
    width: 75%;
    border-radius: 10px !important;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    padding: 25px;
    position: relative;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    outline: none;
    max-height: 90vh;
    overflow-y: auto; }
    .content-modal-overlay .overview-applicant-modal::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #F5F5F5; }
    .content-modal-overlay .overview-applicant-modal::-webkit-scrollbar {
      width: 10px;
      background-color: #F5F5F5; }
    .content-modal-overlay .overview-applicant-modal::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #001061; }
    .content-modal-overlay .overview-applicant-modal .cross-icon {
      position: absolute;
      right: 25px;
      top: 25px;
      color: #00cdbe;
      cursor: pointer; }
    .content-modal-overlay .overview-applicant-modal h3 {
      font-size: 28px;
      line-height: 1;
      letter-spacing: -0.2px;
      color: #00cdbe;
      margin: 0;
      padding-bottom: 5px;
      border-bottom: 1px solid #bcbcbc; }

@media screen and (max-width: 420px) {
  .content-modal-overlay .overview-applicant-modal {
    width: 75% !important; }
    .content-modal-overlay .overview-applicant-modal .btn-box .button__generic {
      margin-left: 1rem; } }

@media only screen and (max-width: 540px) {
  .content-modal-overlay .overview-applicant-modal.mobile-friendly {
    border-top: white 22px solid !important;
    border-bottom: white 22px solid !important;
    border-left: 0px;
    border-right: 0px;
    min-width: 90% !important; }
    .content-modal-overlay .overview-applicant-modal.mobile-friendly .tc.mobile-friendly .input__generic {
      width: 90%;
      height: 6rem; }
    .content-modal-overlay .overview-applicant-modal.mobile-friendly .tc.mobile-friendly .secondary-button__custom {
      width: 40%;
      height: 5rem;
      font-size: 20px; } }

@media screen and (max-width: 420px) {
  .content-modal-overlay .overview-applicant-modal {
    width: 75% !important; }
    .content-modal-overlay .overview-applicant-modal .btn-box .button__generic {
      margin-left: 1rem; } }

table.react-table {
  width: 100%;
  margin: 20px auto; }
  table.react-table thead.react-table-heading {
    background-color: #001061; }
  table.react-table tr.react-table-heading-row th.react-table-heading-cell {
    color: #fff;
    text-align: center;
    font-size: 1.4rem;
    border-right: 1px solid #fff;
    padding: 1.2rem 0; }
    table.react-table tr.react-table-heading-row th.react-table-heading-cell.system-decision-weight {
      font-weight: 500; }
  table.react-table tbody.react-table-body tr.react-table-body-row:nth-child(odd) {
    background-color: #F0F0F0; }
  table.react-table tbody.react-table-body tr.react-table-body-row td.react-table-body-cell {
    text-align: center;
    padding: 1.5rem 1rem;
    font-size: 1.4rem; }
    table.react-table tbody.react-table-body tr.react-table-body-row td.react-table-body-cell.custom-padding {
      padding: 2.5rem 0 !important; }

.can-toggle {
  position: relative; }
  .can-toggle *,
  .can-toggle *:before,
  .can-toggle *:after {
    box-sizing: border-box; }
  .can-toggle input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0; }
    .can-toggle input[type="checkbox"][disabled] ~ label {
      pointer-events: none; }
      .can-toggle input[type="checkbox"][disabled] ~ label .can-toggle__switch {
        opacity: 0.4; }
    .can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:before {
      content: attr(data-unchecked);
      left: 0; }
    .can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
      content: attr(data-checked); }
  .can-toggle label {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    position: relative;
    display: flex;
    align-items: center; }
    .can-toggle label .can-toggle__switch {
      position: relative; }
      .can-toggle label .can-toggle__switch:before {
        content: attr(data-checked);
        position: absolute;
        top: 0;
        text-transform: uppercase;
        text-align: center; }
      .can-toggle label .can-toggle__switch:after {
        content: attr(data-unchecked);
        position: absolute;
        text-transform: uppercase;
        text-align: center;
        background: white;
        transform: translate3d(0, 0, 0); }
  .can-toggle input[type="checkbox"][disabled] ~ label {
    color: rgba(119, 119, 119, 0.5); }
  .can-toggle input[type="checkbox"]:focus ~ label .can-toggle__switch,
  .can-toggle input[type="checkbox"]:hover ~ label .can-toggle__switch {
    background-color: #777; }
    .can-toggle input[type="checkbox"]:focus ~ label .can-toggle__switch:after,
    .can-toggle input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
      color: #5e5e5e; }
  .can-toggle input[type="checkbox"]:hover ~ label {
    color: #6a6a6a; }
  .can-toggle input[type="checkbox"]:checked ~ label:hover {
    color: #55bc49; }
  .can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch {
    background-color: #70c767; }
    .can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
      color: #4fb743; }
  .can-toggle input[type="checkbox"]:checked:focus ~ label .can-toggle__switch, .can-toggle input[type="checkbox"]:checked:hover ~ label .can-toggle__switch {
    background-color: #5fc054; }
    .can-toggle input[type="checkbox"]:checked:focus ~ label .can-toggle__switch:after, .can-toggle input[type="checkbox"]:checked:hover ~ label .can-toggle__switch:after {
      color: #47a43d; }
  .can-toggle label .can-toggle__switch {
    transition: background-color 0.3s cubic-bezier(0, 1, 0.5, 1);
    background: #848484; }
    .can-toggle label .can-toggle__switch:before {
      color: rgba(255, 255, 255, 0.5); }
    .can-toggle label .can-toggle__switch:after {
      transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1);
      color: #777; }
  .can-toggle input[type="checkbox"]:focus ~ label .can-toggle__switch:after,
  .can-toggle input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4); }
  .can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
    transform: translate3d(65px, 0, 0); }
  .can-toggle input[type="checkbox"]:checked:focus ~ label .can-toggle__switch:after, .can-toggle input[type="checkbox"]:checked:hover ~ label .can-toggle__switch:after {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4); }
  .can-toggle label {
    font-size: 14px; }
    .can-toggle label .can-toggle__switch {
      height: 36px;
      flex: 0 0 134px;
      border-radius: 4px; }
      .can-toggle label .can-toggle__switch:before {
        left: 67px;
        font-size: 12px;
        line-height: 36px;
        width: 67px;
        padding: 0 12px; }
      .can-toggle label .can-toggle__switch:after {
        top: 2px;
        left: 2px;
        border-radius: 2px;
        width: 65px;
        line-height: 32px;
        font-size: 12px; }
      .can-toggle label .can-toggle__switch:hover:after {
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4); }
  .can-toggle.demo-rebrand-1 input[type="checkbox"][disabled] ~ label {
    color: rgba(186, 186, 186, 0.5); }
  .can-toggle.demo-rebrand-1 input[type="checkbox"]:focus ~ label .can-toggle__switch,
  .can-toggle.demo-rebrand-1 input[type="checkbox"]:hover ~ label .can-toggle__switch {
    background-color: #bababa; }
    .can-toggle.demo-rebrand-1 input[type="checkbox"]:focus ~ label .can-toggle__switch:after,
    .can-toggle.demo-rebrand-1 input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
      color: #a1a1a1; }
  .can-toggle.demo-rebrand-1 input[type="checkbox"]:hover ~ label {
    color: #adadad; }
  .can-toggle.demo-rebrand-1 input[type="checkbox"]:checked ~ label:hover {
    color: #00beb0; }
  .can-toggle.demo-rebrand-1 input[type="checkbox"]:checked ~ label .can-toggle__switch {
    background-color: #00e7d6; }
    .can-toggle.demo-rebrand-1 input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
      color: #00b4a6; }
  .can-toggle.demo-rebrand-1 input[type="checkbox"]:checked:focus ~ label .can-toggle__switch, .can-toggle.demo-rebrand-1 input[type="checkbox"]:checked:hover ~ label .can-toggle__switch {
    background-color: #00cdbe; }
    .can-toggle.demo-rebrand-1 input[type="checkbox"]:checked:focus ~ label .can-toggle__switch:after, .can-toggle.demo-rebrand-1 input[type="checkbox"]:checked:hover ~ label .can-toggle__switch:after {
      color: #009a8f; }
  .can-toggle.demo-rebrand-1 label .can-toggle__switch {
    transition: background-color 0.3s ease-in-out;
    background: #c7c7c7; }
    .can-toggle.demo-rebrand-1 label .can-toggle__switch:before {
      color: rgba(255, 255, 255, 0.6); }
    .can-toggle.demo-rebrand-1 label .can-toggle__switch:after {
      transition: transform 0.3s ease-in-out;
      color: #bababa; }

.credit-navs-wrapper {
  color: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100; }
  .credit-navs-wrapper .navbar {
    background-color: #001061;
    border: none;
    margin-bottom: 0;
    border-radius: 0; }
    .credit-navs-wrapper .navbar.navbar-fixed-top {
      z-index: 1001; }
    .credit-navs-wrapper .navbar .credit-navs {
      width: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      cursor: pointer;
      padding: 0 6%; }
      .credit-navs-wrapper .navbar .credit-navs div.active, .credit-navs-wrapper .navbar .credit-navs div.active:hover, .credit-navs-wrapper .navbar .credit-navs div.active:focus {
        background-color: #00cdbe;
        border-radius: 25px;
        color: #ffffff;
        padding: 10px 20px; }
    .credit-navs-wrapper .navbar .navbar-collapse .navbar-nav {
      margin: 15px 0; }
      .credit-navs-wrapper .navbar .navbar-collapse .navbar-nav > li {
        margin: 0 20px; }
        .credit-navs-wrapper .navbar .navbar-collapse .navbar-nav > li.fixed {
          position: fixed;
          top: 0;
          right: 0;
          left: 0;
          z-index: 1001; }
        .credit-navs-wrapper .navbar .navbar-collapse .navbar-nav > li a,
        .credit-navs-wrapper .navbar .navbar-collapse .navbar-nav > li span {
          font-size: 18px;
          line-height: 1.21;
          letter-spacing: 0;
          color: #001061;
          padding: 0; }
        .credit-navs-wrapper .navbar .navbar-collapse .navbar-nav > li.dropdown {
          width: auto; }
          .credit-navs-wrapper .navbar .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu {
            padding: 10px 15px;
            border-radius: 5px; }
            .credit-navs-wrapper .navbar .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu li a {
              padding: 0 10px; }
            .credit-navs-wrapper .navbar .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu li:first-child > a {
              background: transparent; }
              .credit-navs-wrapper .navbar .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu li:first-child > a:hover, .credit-navs-wrapper .navbar .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu li:first-child > a:focus {
                background: transparent; }
      .credit-navs-wrapper .navbar .navbar-collapse .navbar-nav > .open > a {
        background: transparent; }
        .credit-navs-wrapper .navbar .navbar-collapse .navbar-nav > .open > a:hover, .credit-navs-wrapper .navbar .navbar-collapse .navbar-nav > .open > a:focus {
          background: transparent; }
  .credit-navs-wrapper .appNumber {
    max-width: 150px;
    background: #00cdbe;
    color: #fff;
    margin: 0 auto;
    text-align: center;
    padding: 5px;
    border-radius: 0px 0px 13px 13px;
    position: relative; }
    .credit-navs-wrapper .appNumber:hover {
      cursor: pointer; }
      .credit-navs-wrapper .appNumber:hover .textOnHover {
        visibility: visible; }
    .credit-navs-wrapper .appNumber .textOnHover {
      visibility: hidden;
      width: 120px;
      background-color: black;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px 0;
      position: absolute;
      z-index: 1;
      margin-left: 20px; }

.radio-box input[type="radio"] {
  display: none; }
  .radio-box input[type="radio"] + .radios .radio-checked {
    display: none; }
  .radio-box input[type="radio"] + .radios .radio-unchecked {
    display: block; }
  .radio-box input[type="radio"]:checked + .radios .radio-checked {
    display: block; }
  .radio-box input[type="radio"]:checked + .radios .radio-unchecked {
    display: none; }

.radio-box .radio-checked,
.radio-box .radio-unchecked {
  cursor: pointer; }

.radio-box svg {
  margin-top: -10px; }

.checkbox-box {
  display: flex;
  align-items: center; }
  .checkbox-box input[type="checkbox"] {
    display: none; }
    .checkbox-box input[type="checkbox"] + .checkbox .checkbox-checked {
      display: none; }
    .checkbox-box input[type="checkbox"] + .checkbox .checkbox-unchecked {
      display: block; }
    .checkbox-box input[type="checkbox"]:checked + .checkbox .checkbox-checked {
      display: block; }
    .checkbox-box input[type="checkbox"]:checked + .checkbox .checkbox-unchecked {
      display: none; }
  .checkbox-box .checkbox {
    margin: 0 5px 0 0; }
    .checkbox-box .checkbox .checkbox-checked,
    .checkbox-box .checkbox .checkbox-unchecked {
      cursor: pointer; }
  .checkbox-box label {
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 0; }

.m-0 {
  margin: 0px !important; }

.mt-0 {
  margin-top: 0px !important; }

.mt-1 {
  margin-top: 0.4em !important; }

.mt-2 {
  margin-top: 0.8em !important; }

.mt-3 {
  margin-top: 1.2em !important; }

.mr-0 {
  margin-right: 0px !important; }

.ml-0 {
  margin-left: 0px !important; }

.mb-0 {
  margin-bottom: 0px !important; }

.mb-1 {
  margin-bottom: 0.4em !important; }

.mb-2 {
  margin-bottom: 0.8em !important; }

.mb-3 {
  margin-bottom: 1.2em !important; }

.p-0 {
  padding: 0px !important; }

.pt-0 {
  padding-top: 0px !important; }

.pl-0 {
  padding-left: 0px !important; }

.pr-0 {
  padding-right: 0px !important; }

.pb-0 {
  padding-bottom: 0px !important; }

.w-100 {
  width: 100% !important; }

.input-field {
  padding: 0.5rem;
  border-radius: 10rem; }

.bold-text {
  font-weight: 600; }

.button__click-here {
  font-weight: 600; }
  .button__click-here:hover {
    cursor: pointer;
    font-size: 1.7rem; }

.label-heading {
  font-weight: bold;
  font-size: 1.6rem;
  color: #434343;
  margin-top: 1rem; }

.child__space-between {
  display: flex;
  justify-content: space-between; }
  .child__space-between svg {
    color: #00cdbe; }
  .child__space-between.mobile-friendly {
    display: block; }

input.otp {
  background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 50px 1px;
  background-repeat: repeat-x;
  background-position-x: 35px;
  border: none;
  width: 9ch;
  align-content: center;
  background: -webkit-repeating-linear-gradient(left, #696969, #696969 1ch, transparent 0, transparent 1.5ch) 0 100%/100% 2px no-repeat;
  background: repeating-linear-gradient(90deg, #696969, #696969 1ch, transparent 0, transparent 1.5ch) 0 100%/100% 2px no-repeat;
  color: #1d1d1d;
  font: 4ch consolas, monospace;
  letter-spacing: 0.5ch;
  outline: none; }
  input.otp::-webkit-input-placeholder {
    letter-spacing: 0ch;
    font-size: 1.2rem; }
  input.otp:-ms-input-placeholder {
    letter-spacing: 0ch;
    font-size: 1.2rem; }
  input.otp::-ms-input-placeholder {
    letter-spacing: 0ch;
    font-size: 1.2rem; }
  input.otp::placeholder {
    letter-spacing: 0ch;
    font-size: 1.2rem; }

.ReactModal__Overlay {
  z-index: 10010; }
  .ReactModal__Overlay .secondary-button__custom {
    padding: 1rem 1rem;
    border-radius: 0.5rem;
    background-color: #00cdbe;
    color: #fff;
    border: none;
    outline: none; }
    .ReactModal__Overlay .secondary-button__custom.padding {
      padding: 1rem 2rem; }

.loader-modloader-modal-overlayal-content {
  position: fixed;
  top: 45%;
  left: 45%; }

.loader-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center; }

.loader-modal-overlay {
  background-color: rgba(255, 255, 255, 0.75); }

.loader-modal-content {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.loader-modal-content {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center; }

input[disabled] {
  cursor: not-allowed !important;
  background-color: inherit; }

select[disabled] {
  cursor: not-allowed !important;
  background-color: inherit; }

button[disabled] {
  cursor: not-allowed !important;
  background: rgba(0, 0, 0, 0.1);
  opacity: 0.7; }

.error-message__line {
  color: #ed5a5a;
  font-size: 1.2rem; }

.select__generic {
  max-width: 80%;
  margin-top: 0.5rem; }
  .select__generic.w-100 {
    max-width: 100%; }
    .select__generic.w-100.w-24rem {
      max-width: 24rem; }

.pincode-flex {
  display: flex; }
  .pincode-flex .margin-1rem {
    margin: 1rem; }

.display-block {
  display: block !important; }

.optional-text__small {
  font-size: 1.2rem;
  color: #a6a6a6; }

.input-generic__dotted {
  border: none;
  border-bottom: 1px dashed #8c8c8c; }

.button-center__wrapper {
  text-align: center; }

.business-wrapper h1 {
  margin-bottom: 1rem;
  color: #00cdbe; }

.business-wrapper span {
  color: #001061;
  font-size: 2.4rem; }

.business-wrapper .gst__wrapper {
  padding: 0.5rem 1rem;
  border: 2px solid #00cdbe;
  border-radius: 0.5rem;
  width: 22rem; }
  .business-wrapper .gst__wrapper .gst-number {
    font-size: 1.6rem;
    color: #001061; }
  .business-wrapper .gst__wrapper .gst-name {
    font-size: 1.1rem; }

.shake-animation__input {
  -webkit-animation: shake-animation 0.2s ease;
          animation: shake-animation 0.2s ease; }

.wrong-otp__text {
  font-size: 1.2rem;
  color: #ed5a5a; }

#formGridCheckbox .form-check-label {
  margin-left: 10px; }

.bullet-green {
  color: #00cdbe;
  margin-right: 1rem; }

.error-message {
  font-size: 1.2rem;
  color: #ed5a5a; }

.main-box__wrapper {
  display: flex;
  justify-content: space-between; }
  .main-box__wrapper .sub-box__wrapper {
    background: #fefefe;
    border-radius: 10px;
    padding: 1rem 2rem;
    position: relative;
    margin: 1rem 0; }
    .main-box__wrapper .sub-box__wrapper.blue-bg {
      background-color: #e5e5e5; }

.set-cursor-pointer {
  cursor: pointer; }

.btn {
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  font-size: 12px;
  padding: 10px;
  text-align: center; }
  .btn.btn-blue {
    background: #00cdbe;
    color: white; }
  .btn.btn-disable {
    border: 1px solid #cecece;
    color: #cecece;
    font-weight: normal;
    font-size: 14px; }
  .btn.normal-button-size {
    width: 120px !important; }
  .btn .error-message {
    font-size: 1.2rem;
    color: #ed5a5a; }

.buttonGeneric_Blue {
  background: #00cdbe;
  border-radius: 5px;
  width: 50%;
  height: 40px;
  border: none;
  font-size: 14px;
  color: white; }

.card_wrapper {
  background: #fefefe;
  border-radius: 10px;
  padding: 5px; }

.display-space-between {
  display: flex;
  justify-content: space-between; }

input[type="file"] {
  opacity: 0;
  position: absolute;
  top: 0;
  height: 100%;
  left: 0;
  width: 100%; }

.ghost-grey__button {
  border: 1px solid #00cdbe;
  color: #cecfcf;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  background: transparent; }

.grey-dashed__button {
  border: 1px dashed #d4d5d5;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  color: #343434;
  background: transparent; }

.input-field:focus {
  box-shadow: 0 0 5px 0 rgba(0, 16, 97, 0.5); }

.input-field,
.form-control {
  display: inline-block;
  border: 1px solid #eee;
  border-radius: 7px;
  width: 100%;
  padding: 15px 12px;
  font-size: 14px;
  line-height: 1.14;
  letter-spacing: normal;
  height: auto; }

.nav-tabs {
  margin: 30px 30px 0;
  border-color: #bcbcbc; }
  .nav-tabs .tabs-heading {
    color: #1d1d1d; }
  .nav-tabs > a {
    margin: 0 4px -1px;
    background-color: #e5e5e5;
    border-radius: 5px 5px 0 0;
    position: relative; }
    .nav-tabs > a a {
      color: #1d1d1d;
      border-radius: 5px 5px 0 0;
      font-family: Helvetica;
      font-size: 16px;
      line-height: 1.19;
      letter-spacing: 0;
      padding: 12px 24px; }
      .nav-tabs > a a label {
        margin-bottom: 0; }
    .nav-tabs > a.active::after {
      content: "";
      width: 75%;
      height: 2px;
      background-color: #00cdbe;
      position: absolute;
      bottom: 0;
      left: 13%; }
    .nav-tabs > a.active a {
      color: #1d1d1d;
      margin-right: 0; }

.fs-10 {
  font-size: 1.2rem; }

.fs-12 {
  font-size: 1.2rem; }

.fs-14 {
  font-size: 1.2rem; }

.mb-5 {
  margin-bottom: 3rem; }

.select-width__generic {
  width: 300px; }

input[type="number"] {
  box-sizing: inherit; }

.cursor-pointer {
  cursor: pointer; }

.timer__wrapper {
  display: flex; }
  .timer__wrapper div {
    color: #001061;
    font-weight: bold; }

.back-button {
  margin: 3% 0 0 2%;
  font-size: 2.5rem;
  color: #00cdbe;
  cursor: pointer;
  display: inline-block; }

.tooltip-inner {
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  transform: translateX(40%); }

.dotted-patch {
  background-image: radial-gradient(rgba(0, 205, 191, 0.325) 15%, rgba(255, 255, 255, 0) 15%);
  background-position: 0 0;
  background-size: 25px 25px;
  height: 350px;
  width: 350px;
  z-index: -1;
  position: absolute !important;
  bottom: -12rem;
  right: -12rem; }
  .dotted-patch.loan-offer {
    height: 290px;
    width: 650px;
    bottom: -20.7rem;
    right: -0.3rem; }

.select__control .select__value-container {
  padding: 4px 8px; }

.card__wrapper {
  background: #FEFEFE;
  box-shadow: 0px 4px 10px rgba(128, 128, 128, 0.16);
  border-radius: 1rem;
  padding: 2rem; }

.homepage__wrapper p.montserrat {
  font-family: "Montserrat"; }

.homepage__wrapper .smaller {
  padding: 1% 10%;
  box-shadow: 0px 10px 9px 1px rgba(0, 16, 97, 0.2);
  background-color: #001061; }

.homepage__wrapper div {
  font-size: 1.6rem; }

.homepage__wrapper h2 {
  font-size: 3rem;
  margin-bottom: 1rem; }

.homepage__wrapper .menubar-item {
  padding: 0.5rem 1.5rem;
  text-decoration: none;
  color: white; }

.homepage__wrapper .secondary-button__custom {
  padding: 1rem 1rem;
  border-radius: 0.5rem;
  background-color: #00cdbe;
  color: #fff;
  border: none;
  outline: none; }

.homepage__wrapper .background-image {
  left: 0;
  top: 0;
  width: 100%;
  height: 88vh;
  background: linear-gradient(64.96deg, rgba(1, 17, 97, 0.7) -0.01%, rgba(0, 205, 190, 0.7) 100%), url(/static/media/HomePage.1735529a.jpg) no-repeat;
  background-size: cover;
  background-position: 0px 90px; }
  .homepage__wrapper .background-image .outer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 88vh;
    background: rgba(0, 0, 0, 0.3); }
    .homepage__wrapper .background-image .outer .details {
      max-width: 80%;
      margin: 2rem auto;
      margin-top: 12rem; }
      .homepage__wrapper .background-image .outer .details .nav-link {
        display: block;
        padding: 0.5rem 1.5rem;
        color: #fff;
        font-size: 1.8rem; }
      .homepage__wrapper .background-image .outer .details .dropdown-toggle::after {
        vertical-align: 0.05rem; }
      .homepage__wrapper .background-image .outer .details .navbar {
        padding: 0.5% 9%;
        box-shadow: 0px 10px 9px 1px rgba(0, 16, 97, 0.2);
        background-color: #001061; }
        .homepage__wrapper .background-image .outer .details .navbar .MSMELoan_dropdown {
          background: #ffffff;
          box-shadow: 0px 0px 50px rgba(158, 158, 158, 0.16);
          border-radius: 10px;
          color: #434343;
          padding: 2rem 3rem 2rem 3rem;
          width: 350%; }
          .homepage__wrapper .background-image .outer .details .navbar .MSMELoan_dropdown .triangle {
            width: 0;
            height: 0;
            border-left: 18px solid transparent;
            border-right: 18px solid transparent;
            border-bottom: 18px solid white;
            top: -7%;
            right: 8%;
            position: absolute; }
          .homepage__wrapper .background-image .outer .details .navbar .MSMELoan_dropdown .d-flex .horizontal-line {
            width: 55%; }
          .homepage__wrapper .background-image .outer .details .navbar .MSMELoan_dropdown .MSMELoan_dropdownItems {
            margin-top: 1rem; }
            .homepage__wrapper .background-image .outer .details .navbar .MSMELoan_dropdown .MSMELoan_dropdownItems:hover {
              color: #001061;
              background-color: #fff;
              border-radius: 0 20rem 20rem 0;
              font-weight: 600; }
          .homepage__wrapper .background-image .outer .details .navbar .MSMELoan_dropdown.dropdown-menu {
            top: 91%; }
      .homepage__wrapper .background-image .outer .details .main-wrapper {
        margin: 0rem auto;
        color: #fff; }
        .homepage__wrapper .background-image .outer .details .main-wrapper h1 {
          font-size: 5rem;
          font-weight: 600;
          margin: 0px; }
        .homepage__wrapper .background-image .outer .details .main-wrapper .width-60 {
          max-width: 60%; }
        .homepage__wrapper .background-image .outer .details .main-wrapper .secondary-color {
          color: #00cdbe; }
        .homepage__wrapper .background-image .outer .details .main-wrapper p {
          margin-bottom: 0px; }
        .homepage__wrapper .background-image .outer .details .main-wrapper .main-content {
          max-width: 68%;
          font-size: 1.8rem;
          margin-top: 2rem; }
          .homepage__wrapper .background-image .outer .details .main-wrapper .main-content .text__italic {
            font-size: 1.4rem;
            font-style: italic;
            text-decoration: underline; }
            .homepage__wrapper .background-image .outer .details .main-wrapper .main-content .text__italic:hover {
              cursor: pointer; }
        .homepage__wrapper .background-image .outer .details .main-wrapper .input-field__gst {
          padding: 1rem 1rem;
          border-radius: 0.5rem;
          border: 1px solid #fff;
          background-color: transparent;
          color: #fff;
          min-width: 30rem;
          outline: none;
          margin-top: 2rem; }
          .homepage__wrapper .background-image .outer .details .main-wrapper .input-field__gst::-webkit-input-placeholder {
            color: #fff; }
          .homepage__wrapper .background-image .outer .details .main-wrapper .input-field__gst:-ms-input-placeholder {
            color: #fff; }
          .homepage__wrapper .background-image .outer .details .main-wrapper .input-field__gst::-ms-input-placeholder {
            color: #fff; }
          .homepage__wrapper .background-image .outer .details .main-wrapper .input-field__gst::placeholder {
            color: #fff; }
        .homepage__wrapper .background-image .outer .details .main-wrapper .secondary-button__custom {
          border: 1px solid #00cdbe; }
        .homepage__wrapper .background-image .outer .details .main-wrapper p {
          margin-top: 1rem;
          font-size: 1.4rem;
          color: #fff; }
          .homepage__wrapper .background-image .outer .details .main-wrapper p .text__no-gst:hover {
            cursor: pointer;
            text-decoration: none;
            color: #747474; }
          .homepage__wrapper .background-image .outer .details .main-wrapper p .text__no-gst p {
            margin-top: 1rem;
            font-size: 1.4rem;
            color: #fff; }
        .homepage__wrapper .background-image .outer .details .main-wrapper .link {
          text-decoration: none;
          color: white; }

.homepage__wrapper .loan-offer__wrapper input::-webkit-input-placeholder {
  font-size: 1.2rem; }

.homepage__wrapper .loan-offer__wrapper input:-ms-input-placeholder {
  font-size: 1.2rem; }

.homepage__wrapper .loan-offer__wrapper input::-ms-input-placeholder {
  font-size: 1.2rem; }

.homepage__wrapper .loan-offer__wrapper input::placeholder {
  font-size: 1.2rem; }

.homepage__wrapper .loan-offer__wrapper {
  padding: 4rem 5rem;
  border-radius: 2rem;
  max-width: 50%;
  margin: 0 auto;
  box-shadow: 0px 5px 20px rgba(148, 148, 148, 0.16);
  transform: translateY(-50%);
  z-index: 100;
  background-color: #fff;
  margin-top: 1%;
  position: relative;
  margin-left: 10%; }
  .homepage__wrapper .loan-offer__wrapper .loan-offer__text {
    font-size: 2.8rem;
    color: #001061; }
  .homepage__wrapper .loan-offer__wrapper .col-md-4.col-sm-12 {
    margin: auto; }
  .homepage__wrapper .loan-offer__wrapper .input__generic {
    padding: 1rem; }

.homepage__wrapper .recommendation__wrapper {
  text-align: center;
  padding: 7rem 0;
  color: #fff;
  background: linear-gradient(64.96deg, #011161 -0.01%, #00cdbe 100%);
  background-repeat: no-repeat;
  background-position: right; }
  .homepage__wrapper .recommendation__wrapper div {
    font-size: 1.4rem !important; }
  .homepage__wrapper .recommendation__wrapper p {
    max-width: 65%;
    margin: 0 auto; }
  .homepage__wrapper .recommendation__wrapper .user-input__wrapper {
    background-color: #fff;
    box-shadow: 0px 0px 30px rgba(148, 148, 148, 0.16);
    border-radius: 10px;
    color: #747474;
    max-width: 90%;
    margin: 4rem auto;
    padding: 2rem 1rem; }
    .homepage__wrapper .recommendation__wrapper .user-input__wrapper .row div.col-md-3.col-sm-6:not(:last-child) {
      border-right: 1px solid #e6e6e6; }
    .homepage__wrapper .recommendation__wrapper .user-input__wrapper .row .loan-amount .input-range__label--max {
      transform: translateX(-50px); }
    .homepage__wrapper .recommendation__wrapper .user-input__wrapper .row .heading {
      margin-bottom: 2rem;
      color: #747474; }
    .homepage__wrapper .recommendation__wrapper .user-input__wrapper .row .interest-rate-input-value {
      display: flex;
      justify-content: space-evenly;
      text-align-last: center; }
      .homepage__wrapper .recommendation__wrapper .user-input__wrapper .row .interest-rate-input-value > * {
        width: 15%; }
    .homepage__wrapper .recommendation__wrapper .user-input__wrapper .row .basic-single {
      margin-top: 4rem; }
      .homepage__wrapper .recommendation__wrapper .user-input__wrapper .row .basic-single .select__control {
        padding: 0.25rem 0.25rem; }
    .homepage__wrapper .recommendation__wrapper .user-input__wrapper .loan-tenure__wrapper {
      display: flex;
      flex: 1 1;
      margin-top: 4rem; }
      .homepage__wrapper .recommendation__wrapper .user-input__wrapper .loan-tenure__wrapper:hover {
        cursor: pointer; }
      .homepage__wrapper .recommendation__wrapper .user-input__wrapper .loan-tenure__wrapper .section {
        border: 1px solid #8c8c8c;
        border-radius: 0.4rem;
        flex-grow: 1;
        flex-basis: 0;
        padding: 1rem 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 1.45rem; }
        .homepage__wrapper .recommendation__wrapper .user-input__wrapper .loan-tenure__wrapper .section.top-bottom-borders {
          border-left: none;
          border-right: none;
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px; }
        .homepage__wrapper .recommendation__wrapper .user-input__wrapper .loan-tenure__wrapper .section.no-left-borders {
          border-left: none;
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px; }
        .homepage__wrapper .recommendation__wrapper .user-input__wrapper .loan-tenure__wrapper .section.no-right-borders {
          border-right: none;
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px; }
      .homepage__wrapper .recommendation__wrapper .user-input__wrapper .loan-tenure__wrapper .active-section {
        background-color: #00cdbe;
        color: #fff;
        flex-grow: 1;
        flex-basis: 0;
        border: none;
        transform: scale(1.1);
        vertical-align: middle;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-weight: 400; }

.homepage__wrapper .aim__wrapper {
  text-align: center;
  margin: 8rem 0; }
  .homepage__wrapper .aim__wrapper h2 {
    color: #001061;
    font-weight: 900;
    font-size: 2.8rem; }
  .homepage__wrapper .aim__wrapper .skewed-box {
    background: #fafaff;
    padding: 3rem;
    transform: skew(0deg, -7deg) translateY(5rem); }
    .homepage__wrapper .aim__wrapper .skewed-box .content {
      transform: skew(0deg, 7deg); }
      .homepage__wrapper .aim__wrapper .skewed-box .content .card-aim__wrapper {
        background: #fff;
        border-radius: 2rem;
        height: 100%;
        text-align: left;
        padding: 3rem 5rem;
        max-width: 100%;
        box-shadow: 0px 0px 25px rgba(148, 148, 148, 0.16); }
        .homepage__wrapper .aim__wrapper .skewed-box .content .card-aim__wrapper .heading {
          color: #00cdbe;
          font-size: 2.1rem;
          margin: 1rem 0;
          font-weight: 400; }
        .homepage__wrapper .aim__wrapper .skewed-box .content .card-aim__wrapper p {
          max-width: 90%; }

.homepage__wrapper .assist__wrapper {
  max-width: 90%;
  background: linear-gradient(64.96deg, #011161 -0.01%, #00cdbe 100%);
  margin: 20rem auto 5rem;
  padding: 5rem 3rem;
  color: #fff;
  border-radius: 1rem;
  position: relative;
  box-shadow: 0px 0px 30px rgba(148, 148, 148, 0.16);
  display: flex;
  flex-wrap: wrap; }
  .homepage__wrapper .assist__wrapper .heading {
    font-size: 3rem;
    color: #fff; }
  .homepage__wrapper .assist__wrapper p {
    max-width: 80%;
    font-size: 2rem;
    margin: 3rem 0; }
  .homepage__wrapper .assist__wrapper .input-field__assist {
    padding: 1rem;
    border-radius: 0.5rem;
    border: 1px solid #fff;
    background-color: transparent;
    color: #fff;
    min-width: 30rem;
    outline: none; }
    .homepage__wrapper .assist__wrapper .input-field__assist::-webkit-input-placeholder {
      color: #fff;
      font-size: 12px; }
    .homepage__wrapper .assist__wrapper .input-field__assist:-ms-input-placeholder {
      color: #fff;
      font-size: 12px; }
    .homepage__wrapper .assist__wrapper .input-field__assist::-ms-input-placeholder {
      color: #fff;
      font-size: 12px; }
    .homepage__wrapper .assist__wrapper .input-field__assist::placeholder {
      color: #fff;
      font-size: 12px; }
  .homepage__wrapper .assist__wrapper .button__assist {
    padding: 1rem;
    border-radius: 0.5rem;
    background: #fff;
    color: #3670cc;
    outline: none;
    border: none;
    margin-left: 2rem;
    font-weight: 600; }
  .homepage__wrapper .assist__wrapper .achievement {
    justify-content: center; }
    .homepage__wrapper .assist__wrapper .achievement.heading {
      font-style: normal;
      font-weight: normal;
      font-size: 2rem;
      padding: 0rem 0rem 2rem 0rem;
      color: #ffffff; }
    .homepage__wrapper .assist__wrapper .achievement .shields {
      padding: 0.2rem; }
    .homepage__wrapper .assist__wrapper .achievement .achievement-box {
      width: 33%;
      position: relative; }

.homepage__wrapper .impact__wrapper {
  text-align: center; }
  .homepage__wrapper .impact__wrapper .carousel__wrapper {
    width: 80%;
    margin: 2rem auto; }
    .homepage__wrapper .impact__wrapper .carousel__wrapper img {
      height: 50rem; }

.homepage__wrapper .lending-partners__wrapper {
  max-width: 80%;
  margin: 5rem auto;
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  padding: 5rem;
  transform: translateY(70%);
  z-index: 100;
  position: relative; }
  .homepage__wrapper .lending-partners__wrapper h2 {
    color: #001061; }
  .homepage__wrapper .lending-partners__wrapper .images-wrapper .img__row {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 2rem; }
    .homepage__wrapper .lending-partners__wrapper .images-wrapper .img__row img {
      border-radius: 100%;
      padding: 1rem;
      box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.16);
      margin: 0 2rem; }

.homepage__wrapper .credochain-works__wrapper {
  text-align: center;
  margin-top: 8rem; }
  .homepage__wrapper .credochain-works__wrapper h2 {
    color: #001061;
    font-weight: 900;
    font-size: 2.8rem; }
  .homepage__wrapper .credochain-works__wrapper .card-credochain-works__wrapper {
    max-width: 93%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    margin-top: 3rem;
    flex-wrap: wrap; }
    .homepage__wrapper .credochain-works__wrapper .card-credochain-works__wrapper .card-wrapper {
      max-width: 40rem;
      background: #ffffff;
      box-shadow: 0px 0px 30px rgba(148, 148, 148, 0.16);
      border-radius: 2rem;
      padding: 3rem 2rem;
      text-align: left;
      position: relative;
      flex-wrap: wrap;
      margin-top: 3rem; }
      .homepage__wrapper .credochain-works__wrapper .card-credochain-works__wrapper .card-wrapper .container {
        max-width: 21rem; }
      .homepage__wrapper .credochain-works__wrapper .card-credochain-works__wrapper .card-wrapper .steps-count {
        font-weight: 900;
        font-size: 40px;
        color: #7e7c7c;
        margin-right: 1rem;
        opacity: 0.3; }
      .homepage__wrapper .credochain-works__wrapper .card-credochain-works__wrapper .card-wrapper h2 {
        color: #00cdbe;
        margin: 2rem 0; }

.homepage__wrapper .our-approach__wrapper {
  text-align: center;
  margin: 8rem 0;
  padding-top: 10rem; }
  .homepage__wrapper .our-approach__wrapper .blue-heading {
    color: #001061;
    font-weight: 900;
    font-size: 2.8rem; }
  .homepage__wrapper .our-approach__wrapper p {
    max-width: 60%;
    margin: 0 auto; }
  .homepage__wrapper .our-approach__wrapper .row {
    max-width: 90%;
    margin: 4rem auto; }
    .homepage__wrapper .our-approach__wrapper .row .approach-card__wrapper {
      height: 100%;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 1rem;
      height: 48rem;
      width: 85%;
      max-width: 34rem;
      margin: 1rem auto;
      transform: scaleY(1);
      transition: transform 0.3s;
      padding: 1rem 2rem;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center;
      min-height: 32rem;
      box-shadow: 0px 0px 30px rgba(148, 148, 148, 0.16); }
      .homepage__wrapper .our-approach__wrapper .row .approach-card__wrapper .animation {
        opacity: 0.2;
        height: 100%;
        width: 100%; }
        .homepage__wrapper .our-approach__wrapper .row .approach-card__wrapper .animation:hover {
          opacity: 1;
          transition: opacity 0.3s ease-in-out; }
      .homepage__wrapper .our-approach__wrapper .row .approach-card__wrapper .row {
        min-width: 100%;
        text-align: left;
        margin: 2rem 0; }
        .homepage__wrapper .our-approach__wrapper .row .approach-card__wrapper .row .col-sm-2,
        .homepage__wrapper .our-approach__wrapper .row .approach-card__wrapper .row .col-sm-10 {
          padding: 0; }
        .homepage__wrapper .our-approach__wrapper .row .approach-card__wrapper .row p {
          min-width: 100%;
          font-size: 1.4rem; }
      .homepage__wrapper .our-approach__wrapper .row .approach-card__wrapper:hover {
        display: block;
        cursor: pointer; }
      .homepage__wrapper .our-approach__wrapper .row .approach-card__wrapper h3 {
        font-size: 2.4rem; }
      .homepage__wrapper .our-approach__wrapper .row .approach-card__wrapper .our-approach-heading {
        margin-bottom: 2px !important;
        font-size: 2rem !important; }
    .homepage__wrapper .our-approach__wrapper .row .card1 {
      background-image: url(/static/media/cashflow.b40ce47f.png), linear-gradient(90deg, #001061 0%, #00cdbe 100%); }
    .homepage__wrapper .our-approach__wrapper .row .card2 {
      background-image: url(/static/media/cluster.96bed175.png), linear-gradient(90deg, #001061 0%, #00cdbe 100%); }
    .homepage__wrapper .our-approach__wrapper .row .card3 {
      background-image: url(/static/media/digital.1ef5f3a1.png), linear-gradient(90deg, #001061 0%, #00cdbe 100%); }

.homepage__wrapper .signin-btn {
  background-color: white;
  color: #00cdbe !important;
  border-radius: 5px;
  margin: 0px 1.5rem 1rem 1.5rem;
  padding: 0.8rem 1.9rem !important; }

.homepage__wrapper .msme-footer-btn {
  border-radius: 5px;
  padding: 0.5rem;
  border: 1px white solid;
  width: 80%;
  text-align: center;
  margin-top: 3rem !important; }

@media only screen and (max-width: 1080px) {
  .loan-offer__wrapper {
    margin-top: 10rem !important;
    transform: translateY(0) !important; } }

@media only screen and (max-width: 500px) {
  .homepage__wrapper .MSMELoan_dropdown .d-flex {
    display: inline !important; }
  .homepage__wrapper .signin-btn {
    padding: 1rem 1.5rem !important;
    min-width: auto;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content; }
  .homepage__wrapper .navbar-collapse {
    background-color: #00cdbe;
    padding: 1.5rem 0.7rem;
    min-width: 100vw;
    position: absolute;
    left: 0;
    top: 59px; }
  .homepage__wrapper .background-image {
    height: 130vh; }
    .homepage__wrapper .background-image .outer {
      height: 130vh; }
      .homepage__wrapper .background-image .outer .details {
        margin: 9rem auto;
        max-width: 93%; }
        .homepage__wrapper .background-image .outer .details .main-wrapper {
          margin-top: 1rem; }
          .homepage__wrapper .background-image .outer .details .main-wrapper .width-60 {
            max-width: 100%; }
          .homepage__wrapper .background-image .outer .details .main-wrapper .main-content {
            max-width: 100%;
            margin-top: 3rem; }
          .homepage__wrapper .background-image .outer .details .main-wrapper .input-field__gst {
            padding: 1.5rem 2.5rem;
            margin-bottom: 1.5rem;
            min-width: 100%; }
          .homepage__wrapper .background-image .outer .details .main-wrapper .secondary-button__custom {
            padding: 1.5rem 2.5rem;
            width: 100%; }
          .homepage__wrapper .background-image .outer .details .main-wrapper .button__generic {
            padding: 1.5rem 2.5rem;
            max-width: 100%;
            text-align: center;
            margin-top: 3.5rem !important; }
  .homepage__wrapper .loan-offer__wrapper {
    max-width: 93%;
    margin-left: auto; }
    .homepage__wrapper .loan-offer__wrapper .row .input__generic {
      max-width: 100%; }
    .homepage__wrapper .loan-offer__wrapper .row .secondary-button__custom {
      max-width: 100%;
      padding: 1.5rem 2.5rem; }
  .homepage__wrapper .recommendation__wrapper .patch-wrapper h2 {
    max-width: 80%;
    margin: auto;
    margin-bottom: 2rem;
    font-weight: 700; }
  .homepage__wrapper .recommendation__wrapper .patch-wrapper p {
    max-width: 90%; }
  .homepage__wrapper .recommendation__wrapper .patch-wrapper .user-input__wrapper .row .heading {
    margin-top: 2.5rem; }
  .homepage__wrapper .recommendation__wrapper .patch-wrapper .user-input__wrapper .row .basic-single {
    margin-bottom: 2rem; }
  .homepage__wrapper .credochain-works__wrapper {
    margin: auto;
    margin-top: 2.5rem; }
    .homepage__wrapper .credochain-works__wrapper .card-credochain-works__wrapper {
      max-width: 100%; }
      .homepage__wrapper .credochain-works__wrapper .card-credochain-works__wrapper .card-wrapper .mobile-steps {
        width: 14rem; }
  .homepage__wrapper .our-approach__wrapper p {
    max-width: 90%;
    margin: auto; }
  .homepage__wrapper .our-approach__wrapper .row .col-md-4.col-sm-12 {
    margin-bottom: 3rem !important; }
    .homepage__wrapper .our-approach__wrapper .row .col-md-4.col-sm-12 .approach-card__wrapper {
      height: 46rem;
      max-width: 90%;
      width: 90%; }
  .homepage__wrapper .aim__wrapper .card-aim__wrapper {
    min-height: 30rem; }
  .homepage__wrapper .aim__wrapper .skewed-box {
    padding: 0; }
    .homepage__wrapper .aim__wrapper .skewed-box .row {
      margin-top: 0; }
      .homepage__wrapper .aim__wrapper .skewed-box .row div {
        margin-top: 2rem; }
  .homepage__wrapper .assist__wrapper {
    padding: 4rem 3rem; }
    .homepage__wrapper .assist__wrapper .dotted-patch.assist {
      display: none; }
    .homepage__wrapper .assist__wrapper p {
      max-width: 80%; }
    .homepage__wrapper .assist__wrapper .input-field__assist {
      margin-bottom: 2rem;
      width: 100%; }
    .homepage__wrapper .assist__wrapper .button__assist {
      margin: auto;
      width: 100%; }
    .homepage__wrapper .assist__wrapper .achievement {
      margin-top: 2rem; }
      .homepage__wrapper .assist__wrapper .achievement .achievement-box {
        width: 100%; }
  .loan-offer {
    bottom: -87rem !important; }
  .loan-offer__wrapper {
    margin-top: 4rem !important;
    transform: translateY(0) !important;
    padding: 1rem !important;
    margin-bottom: 3rem !important; } }

.heading {
  font-weight: bold;
  color: #434343; }

.button__generic {
  padding: 0.9rem 2rem;
  background: #00cdbe;
  border-radius: 0.5rem;
  color: #fff;
  font-weight: 700;
  border: none;
  margin-top: 1rem;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content; }

.slider-panel {
  min-height: 220px;
  position: relative; }

.input__generic {
  border: 1px solid #cecfcf;
  border-radius: 0.5rem;
  width: 25rem;
  margin: 1rem 0;
  outline: none;
  padding: 0.75rem 1rem; }
  .input__generic.padding {
    padding: 1.25rem 1rem; }

.row {
  padding: 0;
  margin: 0; }
  .row .position-width-top {
    position: absolute;
    width: 100%; }
  .row .side-panel__wrapper {
    height: 100vh;
    background: linear-gradient(90deg, #3670cc 0%, #00cdbe 100%); }

.row {
  padding: 0;
  margin: 0; }
  .row .position-width-top {
    position: absolute;
    width: 100%; }
  .row .gst-otp__wrapper .share-otp__wrapper {
    max-width: 80%;
    min-width: 50rem;
    padding: 2rem;
    border: 2px solid #00cdbe;
    border-radius: 1rem;
    margin: 4rem 0px;
    background: white; }
    .row .gst-otp__wrapper .share-otp__wrapper .button__share-link {
      color: #00cdbe;
      border: 1px solid #00cdbe;
      padding: 0.5rem 0.75rem;
      background: transparent;
      border-radius: 0.5rem;
      margin: 0.5rem 0; }
  .row .share-wrapper {
    max-width: 80%;
    min-width: 50rem;
    padding: 2rem;
    border: 2px solid #00cdbe;
    border-radius: 1rem;
    margin: 2rem 0;
    background-color: #ffffff; }
    .row .share-wrapper h2 {
      margin: 0; }
  .row .without-aadhar__wrapper .withoutAadhar {
    padding: 2%;
    margin-top: 2%;
    position: relative; }
    .row .without-aadhar__wrapper .withoutAadhar .select__indicator.select__clear-indicator {
      display: none; }
    .row .without-aadhar__wrapper .withoutAadhar svg {
      color: #00cdbe; }
    .row .without-aadhar__wrapper .withoutAadhar .heading {
      color: #00cdbe;
      font-size: 2.6rem; }
    .row .without-aadhar__wrapper .withoutAadhar .add-wrapper {
      background: #ffffff;
      border: 0.5px dashed #a8a8a8;
      border-radius: 5px;
      max-width: 20%;
      min-width: 20rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5rem 0; }
      .row .without-aadhar__wrapper .withoutAadhar .add-wrapper:hover {
        border: 1px dashed #a8a8a8;
        cursor: pointer; }
    .row .without-aadhar__wrapper .withoutAadhar .available-wrapper {
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      max-width: 20%;
      min-width: 20rem;
      padding: 1rem 2rem;
      margin-right: 1rem; }
    .row .without-aadhar__wrapper .withoutAadhar .delete-profile__icon {
      position: absolute;
      top: 30px;
      right: 25px; }
      .row .without-aadhar__wrapper .withoutAadhar .delete-profile__icon svg {
        color: #ed5a5a; }
      .row .without-aadhar__wrapper .withoutAadhar .delete-profile__icon:hover {
        transform-origin: top center;
        -webkit-animation-name: swing;
        animation-name: swing;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        cursor: pointer; }

.address__wrapper {
  display: flex;
  flex-wrap: wrap; }
  .address__wrapper .available-wrapper {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    max-width: 20%;
    min-width: 20rem;
    padding: 1rem 2rem;
    margin-right: 1rem; }
  .address__wrapper .add-wrapper {
    background: #ffffff;
    border: 0.5px dashed #a8a8a8;
    border-radius: 5px;
    max-width: 20%;
    min-width: 20rem;
    display: flex;
    justify-content: center;
    align-items: center; }
    .address__wrapper .add-wrapper:hover {
      border: 1px dashed #a8a8a8;
      cursor: pointer; }

.aadhar-card__wrapper {
  border: 1px solid #00cdbe;
  padding: 1rem;
  border-radius: 1rem;
  max-width: 50%;
  min-width: 55rem;
  margin: 2rem 0; }
  .aadhar-card__wrapper label {
    color: #434343; }
  .aadhar-card__wrapper span {
    color: #000000; }

.withoutGst__wrapper .available-wrapper {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  max-width: 20%;
  min-width: 20rem;
  padding: 1rem 2rem;
  margin-right: 1rem; }

.withoutGst__wrapper .add-wrapper {
  background: #ffffff;
  border: 0.5px dashed #a8a8a8;
  border-radius: 5px;
  max-width: 20%;
  min-width: 20rem;
  display: flex;
  justify-content: center;
  align-items: center; }
  .withoutGst__wrapper .add-wrapper .available-wrapper {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    max-width: 20%;
    min-width: 20rem;
    padding: 1rem 2rem;
    margin-right: 1rem; }
  .withoutGst__wrapper .add-wrapper:hover {
    border: 1px dashed #a8a8a8;
    cursor: pointer; }

.withoutGst__wrapper .address-modal__wrapper * {
  font-size: 1.6rem;
  padding: 0; }

.withoutGst__wrapper .address-modal__wrapper .button-normal {
  padding: 1rem 3rem;
  background: #f4f4f4;
  border: 1px solid #cecfcf;
  border-radius: 5px;
  outline: none;
  margin-right: 1rem; }

.withoutGst__wrapper .address-modal__wrapper .button-selected {
  padding: 1rem 3rem;
  margin-right: 1rem;
  font-weight: 600;
  background: #00cdbe;
  color: #fff;
  border: 1px solid #cecfcf;
  border-radius: 5px;
  outline: none; }

.withoutGst__wrapper .address-modal__wrapper .input__generic {
  border: 1px solid #cecfcf;
  border-radius: 5px;
  padding: 1rem;
  outline: none; }

.withoutGst__wrapper .address-modal__wrapper .btns-box {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem; }
  .withoutGst__wrapper .address-modal__wrapper .btns-box .button-deleted {
    background: transparent;
    color: #ff4c4c;
    border: 1px solid #ff4c4c;
    padding: 1rem 3rem;
    border-radius: 0.5rem;
    margin-right: 1rem; }

.withoutGst__wrapper .report-download__wrapper .button__generic {
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content; }

.withoutGst__wrapper .signup__wrapper label {
  font-weight: 600; }

.withoutGst__wrapper .signup__wrapper .modal-fields__wrapper {
  border: 1px solid #00cdbe;
  border-radius: 5px;
  margin: 2rem 0;
  padding: 1rem 0; }

.withoutGst__wrapper .signup__wrapper .btn-box {
  display: flex;
  justify-content: space-evenly;
  max-width: 70%;
  margin: 0 auto; }
  .withoutGst__wrapper .signup__wrapper .btn-box .button-black-transparent {
    border: 1px solid #434343;
    border-radius: 0.5rem;
    background: transparent;
    margin-top: 1rem;
    padding: 1rem;
    outline: none; }

.withoutGst__wrapper .signup__wrapper .already-account__wrapper {
  text-align: center;
  font-size: 1.4rem;
  margin-top: 2rem; }
  .withoutGst__wrapper .signup__wrapper .already-account__wrapper p {
    line-height: 1; }
    .withoutGst__wrapper .signup__wrapper .already-account__wrapper p:nth-child(2) {
      font-weight: 700; }
      .withoutGst__wrapper .signup__wrapper .already-account__wrapper p:nth-child(2):hover {
        cursor: pointer; }

.withoutGst__wrapper .signup__wrapper .signup-userInput__wrapper {
  padding: 2rem 0; }

.withoutGst__wrapper .signup__wrapper .input-otp__box div input {
  width: 3rem !important;
  margin-top: 1rem;
  height: 4rem !important; }

.withoutGst__wrapper .signup__wrapper .input-otp__box div:not(:first-child) input {
  color: transparent;
  text-shadow: 0 0 0 black; }
  .withoutGst__wrapper .signup__wrapper .input-otp__box div:not(:first-child) input:focus {
    outline: none; }

.withoutGst__wrapper .signup__wrapper .otp-screen__wrapper {
  max-width: 50%;
  margin: 2rem auto;
  justify-content: center;
  min-width: 250px; }
  .withoutGst__wrapper .signup__wrapper .otp-screen__wrapper .mobile__wrapper {
    margin: 2rem 0px 1rem 0;
    background: #00cdbe;
    padding: 1rem;
    border-radius: 0.5rem;
    color: #fff;
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    min-width: max-content; }

.signup__wrapper label {
  font-weight: 600; }

.signup__wrapper .modal-fields__wrapper {
  border: 1px solid #00cdbe;
  border-radius: 5px;
  margin: 2rem 0;
  padding: 1rem 0; }

.signup__wrapper .btn-box {
  display: flex;
  justify-content: space-evenly;
  max-width: 70%;
  margin: 0 auto; }
  .signup__wrapper .btn-box .button-black-transparent {
    border: 1px solid #434343;
    border-radius: 0.5rem;
    background: transparent;
    margin-top: 1rem;
    padding: 1rem;
    outline: none; }

.signup__wrapper .already-account__wrapper {
  text-align: center;
  font-size: 1.4rem;
  margin-top: 2rem; }
  .signup__wrapper .already-account__wrapper p {
    line-height: 1; }
    .signup__wrapper .already-account__wrapper p:nth-child(2) {
      font-weight: 700; }
      .signup__wrapper .already-account__wrapper p:nth-child(2):hover {
        cursor: pointer; }

.signup__wrapper .signup-userInput__wrapper {
  padding: 2rem 0; }

.signup__wrapper .input-otp__box div input {
  width: 3rem !important;
  margin-top: 1rem;
  height: 4rem !important; }

.signup__wrapper .input-otp__box div:not(:first-child) input {
  color: transparent;
  text-shadow: 0 0 0 black; }
  .signup__wrapper .input-otp__box div:not(:first-child) input:focus {
    outline: none; }

.signup__wrapper .otp-screen__wrapper {
  max-width: 50%;
  margin: 2rem auto;
  justify-content: center;
  min-width: 250px; }
  .signup__wrapper .otp-screen__wrapper .mobile__wrapper {
    margin: 2rem 0px 1rem 0;
    background: #00cdbe;
    padding: 1rem;
    border-radius: 0.5rem;
    color: #fff;
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    min-width: max-content; }

.address-modal__wrapper * {
  font-size: 1.6rem;
  padding: 0; }

.address-modal__wrapper .button-normal {
  padding: 1rem 3rem;
  background: #f4f4f4;
  border: 1px solid #cecfcf;
  border-radius: 5px;
  outline: none;
  margin-right: 1rem; }

.address-modal__wrapper .button-selected {
  padding: 1rem 3rem;
  margin-right: 1rem;
  font-weight: 600;
  background: #00cdbe;
  color: #fff;
  border: 1px solid #cecfcf;
  border-radius: 5px;
  outline: none; }

.address-modal__wrapper .input__generic {
  border: 1px solid #cecfcf;
  border-radius: 5px;
  padding: 1rem;
  outline: none;
  width: 14rem; }

.address-modal__wrapper .pincode_wrapper {
  display: flex !important;
  flex-wrap: wrap; }

.address-modal__wrapper svg {
  color: #00cdbe; }

.address-modal__wrapper .btns-box {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem; }
  .address-modal__wrapper .btns-box .button-deleted {
    background: transparent;
    color: #ff4c4c;
    border: 1px solid #ff4c4c;
    padding: 1rem 3rem;
    border-radius: 0.5rem;
    margin-right: 1rem; }

.input-otp__box div input {
  width: 3rem !important;
  margin-top: 2rem;
  height: 4rem !important; }

.input-otp__box div:not(:first-child) input {
  color: transparent;
  text-shadow: 0 0 0 black; }
  .input-otp__box div:not(:first-child) input:focus {
    outline: none; }

.otp-screen__wrapper {
  max-width: 50%;
  margin: 2rem auto;
  justify-content: center;
  min-width: 250px; }
  .otp-screen__wrapper .mobile__wrapper {
    margin: 2rem 0;
    background: #00cdbe;
    padding: 1rem;
    border-radius: 0.5rem;
    color: #fff;
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    min-width: max-content; }

@media only screen and (max-width: 770px) {
  .biz360__wrapper .row .side-panel__wrapper {
    display: none; }
  .biz360__wrapper .row .main-panel__wrapper .gst-data__wrapper {
    width: 100%; }
    .biz360__wrapper .row .main-panel__wrapper .gst-data__wrapper .gst__wrapper {
      width: 100%; }
    .biz360__wrapper .row .main-panel__wrapper .gst-data__wrapper .gst-name {
      font-size: 1.2rem; }
    .biz360__wrapper .row .main-panel__wrapper .gst-data__wrapper .gst-number {
      font-size: 1.6rem; }
  .biz360__wrapper .row .main-panel__wrapper .row .heading {
    margin-bottom: 0.2rem;
    font-size: 1.8rem; }
  .biz360__wrapper .row .main-panel__wrapper .row .subheading {
    margin-bottom: 2rem; }
  .biz360__wrapper .row .main-panel__wrapper .row .react-date-picker {
    margin-bottom: 2rem;
    width: 100%; }
    .biz360__wrapper .row .main-panel__wrapper .row .react-date-picker .react-date-picker__wrapper {
      width: inherit; }
      .biz360__wrapper .row .main-panel__wrapper .row .react-date-picker .react-date-picker__wrapper .react-date-picker__inputGroup {
        width: inherit; }
        .biz360__wrapper .row .main-panel__wrapper .row .react-date-picker .react-date-picker__wrapper .react-date-picker__inputGroup .react-date-picker__inputGroup__year {
          width: 5.1rem !important; }
        .biz360__wrapper .row .main-panel__wrapper .row .react-date-picker .react-date-picker__wrapper .react-date-picker__inputGroup .react-date-picker__inputGroup__day {
          width: 3.1rem !important; }
  .biz360__wrapper .row .main-panel__wrapper .row .react-date-picker__calendar--open {
    left: -5rem !important;
    height: auto !important;
    width: auto !important; }
  .biz360__wrapper .row .main-panel__wrapper .row .select__generic {
    max-width: 100%; }
  .biz360__wrapper .row .main-panel__wrapper .address-mobile-view__wrapper {
    display: block; }
  .biz360__wrapper .row .main-panel__wrapper .optional-text__small {
    display: block; }
  .biz360__wrapper .row .main-panel__wrapper .address__wrapper .add-wrapper {
    padding: 1rem;
    width: 10rem;
    height: 6rem; }
  .biz360__wrapper .row .share-username__wrapper.pt-5 {
    padding: 3rem 2rem; }
  .biz360__wrapper .row .share-username__wrapper .share-wrapper {
    min-width: 30rem;
    margin-top: 9rem !important; }
  .biz360__wrapper .row .share-otp__wrapper {
    min-width: 30rem !important; }
  .address-modal__wrapper .child__space-between.mobile-friendly-pincode {
    display: block; } }

@media only screen and (max-width: 500px) {
  .form-panel__wrapper .main-panel__wrapper .gst__wrapper {
    width: 27rem; }
  .biz360__wrapper .row .main-panel__wrapper .row .react-date-picker .react-date-picker__wrapper .react-date-picker__inputGroup .react-date-picker__inputGroup__day {
    width: 1rem !important; }
  .biz360__wrapper .row .main-panel__wrapper .row .react-date-picker__calendar--open {
    left: -3rem !important; }
  .address-modal__wrapper .child__space-between.mobile-friendly-pincode {
    display: block; } }

@media screen and (max-width: 420px) {
  .position-width-top {
    padding-bottom: 4rem; }
  .side-panel__wrapper {
    display: none; }
  .address-modal__wrapper .child__space-between {
    flex-direction: column; }
  .withoutAadhar {
    margin-bottom: 2rem; }
  .signup__wrapper .otp-screen__wrapper {
    margin: 0; } }

@media screen and (width: 768px) {
  .react-date-picker__wrapper {
    width: -webkit-max-content !important;
    width: -moz-max-content !important;
    width: max-content !important; } }

@-webkit-keyframes pageLending {
  0% {
    transform: translateY(20px); }
  25% {
    transform: translateY(15px); }
  50% {
    transform: translateY(10px); }
  100% {
    transform: translateY(0px); } }

@keyframes pageLending {
  0% {
    transform: translateY(20px); }
  25% {
    transform: translateY(15px); }
  50% {
    transform: translateY(10px); }
  100% {
    transform: translateY(0px); } }

@-webkit-keyframes cardAnimation {
  0% {
    width: 50%; }
  25% {
    width: 60%; }
  50% {
    width: 70%; }
  75% {
    width: 80%; }
  100% {
    width: 100%; } }

@keyframes cardAnimation {
  0% {
    width: 50%; }
  25% {
    width: 60%; }
  50% {
    width: 70%; }
  75% {
    width: 80%; }
  100% {
    width: 100%; } }

@-webkit-keyframes shake-animation {
  0% {
    transform: translateX(0px); }
  25% {
    transform: translateX(10px); }
  50% {
    transform: translateX(-10px); }
  75% {
    transform: translateX(10px); }
  90% {
    transform: translateX(-10px); }
  100% {
    transform: translateX(0px); } }

@keyframes shake-animation {
  0% {
    transform: translateX(0px); }
  25% {
    transform: translateX(10px); }
  50% {
    transform: translateX(-10px); }
  75% {
    transform: translateX(10px); }
  90% {
    transform: translateX(-10px); }
  100% {
    transform: translateX(0px); } }

@-webkit-keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg); }
  40% {
    transform: rotate3d(0, 0, 1, -10deg); }
  60% {
    transform: rotate3d(0, 0, 1, 5deg); }
  80% {
    transform: rotate3d(0, 0, 1, -5deg); }
  to {
    transform: rotate3d(0, 0, 1, 0deg); } }

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg); }
  40% {
    transform: rotate3d(0, 0, 1, -10deg); }
  60% {
    transform: rotate3d(0, 0, 1, 5deg); }
  80% {
    transform: rotate3d(0, 0, 1, -5deg); }
  to {
    transform: rotate3d(0, 0, 1, 0deg); } }

.react-date-picker .react-date-picker__wrapper {
  padding: .60rem;
  border: 1px solid #CECFCF;
  border-radius: .5rem;
  margin: 1rem 0;
  width: inherit; }
  .react-date-picker .react-date-picker__wrapper .react-date-picker__clear-button.react-date-picker__button {
    display: none; }

.react-date-picker .react-date-picker__calendar {
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.16); }
  .react-date-picker .react-date-picker__calendar .react-calendar {
    border-radius: 1rem !important;
    overflow: hidden; }
    .react-date-picker .react-date-picker__calendar .react-calendar .react-calendar__navigation {
      background-color: #00cdbe; }
    .react-date-picker .react-date-picker__calendar .react-calendar .react-calendar__viewContainer .react-calendar__month-view .react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday abbr {
      text-decoration: none; }
    .react-date-picker .react-date-picker__calendar .react-calendar .react-calendar__viewContainer .react-calendar__month-view .react-calendar__month-view__days .react-calendar__tile--active {
      background: #001061 !important; }

.biz360-report__wrapper {
  background-color: #fff;
  margin: 1rem; }
  .biz360-report__wrapper .header__blue {
    background: #001061;
    color: #fff; }
    .biz360-report__wrapper .header__blue .container {
      display: flex;
      justify-content: space-between;
      padding: 2rem; }
      .biz360-report__wrapper .header__blue .container .header-heading__small {
        font-size: 1rem; }
      .biz360-report__wrapper .header__blue .container .header-heading {
        font-size: 3rem;
        font-weight: 600; }
      .biz360-report__wrapper .header__blue .container .right__section .image-logo__wrapper {
        display: flex;
        align-items: baseline; }
      .biz360-report__wrapper .header__blue .container .right__section h3 {
        display: inline; }
  .biz360-report__wrapper .container .box__wrapper-green {
    background: #FFFFFF;
    border: 2px solid #00CDBE;
    border-radius: 1rem;
    padding: 2rem;
    margin: 2rem 0; }
    .biz360-report__wrapper .container .box__wrapper-green .heading {
      margin: 0 auto;
      color: #001061;
      font-size: 2.0rem; }
    .biz360-report__wrapper .container .box__wrapper-green .sub-heading {
      color: #00cdbe;
      font-size: 1.6rem;
      border-bottom: 1px solid #999999;
      margin: 2rem 0 1rem;
      padding-bottom: 1rem; }
    .biz360-report__wrapper .container .box__wrapper-green .label-heading {
      color: #1D1D1D; }
    .biz360-report__wrapper .container .box__wrapper-green .label-value {
      color: #1D1D1D; }
    .biz360-report__wrapper .container .box__wrapper-green hr {
      margin: 0;
      padding: 0;
      color: #00cdbe;
      width: 104%;
      transform: translateX(-2%);
      background-color: #00cdbe; }
    .biz360-report__wrapper .container .box__wrapper-green .note__wrapper {
      color: #011061;
      margin-top: 2rem;
      line-height: 1;
      font-size: 1.4rem;
      position: relative; }
      .biz360-report__wrapper .container .box__wrapper-green .note__wrapper .left-section {
        position: absolute; }
      .biz360-report__wrapper .container .box__wrapper-green .note__wrapper .right-section {
        padding-left: 5rem; }
    .biz360-report__wrapper .container .box__wrapper-green ul {
      margin-top: 2rem; }
      .biz360-report__wrapper .container .box__wrapper-green ul li {
        color: #001061; }
        .biz360-report__wrapper .container .box__wrapper-green ul li span {
          color: black; }
    .biz360-report__wrapper .container .box__wrapper-green .concentration__wrapper-box {
      margin: 2rem 0; }
      .biz360-report__wrapper .container .box__wrapper-green .concentration__wrapper-box > div {
        padding: .5rem; }
      .biz360-report__wrapper .container .box__wrapper-green .concentration__wrapper-box .concentration-heading {
        font-size: 1.4rem;
        color: #001061;
        font-weight: bold; }
      .biz360-report__wrapper .container .box__wrapper-green .concentration__wrapper-box .concentration-table__wrapper {
        border: 1px solid #001262;
        border-radius: 5px; }
        .biz360-report__wrapper .container .box__wrapper-green .concentration__wrapper-box .concentration-table__wrapper .row {
          border-bottom: 1px solid #999999; }
          .biz360-report__wrapper .container .box__wrapper-green .concentration__wrapper-box .concentration-table__wrapper .row > [class*="col-"] {
            padding: 1rem; }
          .biz360-report__wrapper .container .box__wrapper-green .concentration__wrapper-box .concentration-table__wrapper .row > div:first-child {
            border-right: 1px solid #999999; }
          .biz360-report__wrapper .container .box__wrapper-green .concentration__wrapper-box .concentration-table__wrapper .row:last-child {
            border: none; }
    .biz360-report__wrapper .container .box__wrapper-green .react-table .react-table-heading {
      color: #fff;
      font-size: 1.4rem; }
    .biz360-report__wrapper .container .box__wrapper-green .react-table .react-table-subheading {
      font-size: 1.2rem;
      display: flex;
      justify-content: space-between;
      padding: 2px 4px; }
    .biz360-report__wrapper .container .box__wrapper-green .react-table .liveLoans {
      color: #00cdbe;
      font-size: 12px;
      font-weight: 800 !important; }
    .biz360-report__wrapper .container .box__wrapper-green .react-table span {
      min-width: 45%; }
      .biz360-report__wrapper .container .box__wrapper-green .react-table span:first-child {
        border-right: 1px solid #fff; }
    .biz360-report__wrapper .container .box__wrapper-green .card-box__wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      .biz360-report__wrapper .container .box__wrapper-green .card-box__wrapper .card__wrapper {
        background: #FEFEFE;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 1rem;
        padding: 1rem 2rem;
        max-width: 23%;
        min-width: 24rem;
        margin-bottom: 1rem; }
        .biz360-report__wrapper .container .box__wrapper-green .card-box__wrapper .card__wrapper-heading {
          color: #505050;
          font-size: 1.8rem; }
        .biz360-report__wrapper .container .box__wrapper-green .card-box__wrapper .card__wrapper .grey__text {
          color: #9F9FC0;
          font-size: 1.2rem;
          margin-top: 1rem; }
        .biz360-report__wrapper .container .box__wrapper-green .card-box__wrapper .card__wrapper .card__wrapper-heading {
          font-weight: bold;
          font-size: 18px;
          color: #505050; }
      .biz360-report__wrapper .container .box__wrapper-green .card-box__wrapper .btns-box {
        display: flex;
        justify-content: center;
        width: 100%; }
  .biz360-report__wrapper .container .powered-by__wrapper {
    display: flex;
    justify-content: center;
    margin: 5rem 0 10rem; }
    .biz360-report__wrapper .container .powered-by__wrapper > * {
      margin-right: 1rem; }
    .biz360-report__wrapper .container .powered-by__wrapper .logo__header {
      color: #001061;
      font-weight: 700; }
  .biz360-report__wrapper .container .btn-box__right {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem; }
    .biz360-report__wrapper .container .btn-box__right .print-page__button {
      border: 1px solid #00CDBE;
      border-radius: 5rem;
      padding: .5rem 2rem;
      background: transparent; }
      .biz360-report__wrapper .container .btn-box__right .print-page__button svg {
        color: #00cdbe;
        margin-left: 1rem; }

@page {
  size: A4;
  margin: 0; }

@media print {
  body {
    margin: 5mm 5mm 5mm 5mm; }
  .biz360-report__wrapper {
    position: absolute;
    top: 20px;
    right: 0;
    left: 0;
    bottom: 0; }
  div {
    -webkit-print-color-adjust: exact; }
    div:not(.container) {
      page-break-inside: avoid; }
  svg {
    max-width: 100vw; }
  table {
    font-size: 1vw; }
  button {
    display: none; }
  .col-md-2 {
    display: none; } }

@media only screen and (max-width: 600px) {
  .loan-advisory__wrapper .skewed-box__blue {
    height: 55vh !important; }
    .loan-advisory__wrapper .skewed-box__blue .content .recommendation__wrapper p {
      max-width: 100% !important; }
    .loan-advisory__wrapper .skewed-box__blue .content .recommendation__wrapper .user-input__wrapper {
      margin-top: 3rem !important; }
      .loan-advisory__wrapper .skewed-box__blue .content .recommendation__wrapper .user-input__wrapper .input-range__label--max {
        transform: translateX(-20px); }
      .loan-advisory__wrapper .skewed-box__blue .content .recommendation__wrapper .user-input__wrapper .user-input-sub__wrapper {
        margin-bottom: 2rem; }
    .loan-advisory__wrapper .skewed-box__blue .content .recommendation__wrapper .business-details__wrapper .row .business-details-sub__wrapper {
      margin-bottom: .5rem; }
    .loan-advisory__wrapper .skewed-box__blue .content .recommendation__wrapper .business-details__wrapper .row .input-value__wrapper {
      display: block; }
      .loan-advisory__wrapper .skewed-box__blue .content .recommendation__wrapper .business-details__wrapper .row .input-value__wrapper p {
        width: 100%;
        margin-bottom: 1.2rem;
        font-size: 2rem; }
      .loan-advisory__wrapper .skewed-box__blue .content .recommendation__wrapper .business-details__wrapper .row .input-value__wrapper .input-generic__dotted {
        width: 100%;
        font-size: 1.8rem; }
      .loan-advisory__wrapper .skewed-box__blue .content .recommendation__wrapper .business-details__wrapper .row .input-value__wrapper .basic-single {
        width: 100%; }
        .loan-advisory__wrapper .skewed-box__blue .content .recommendation__wrapper .business-details__wrapper .row .input-value__wrapper .basic-single .select__control {
          height: 4.5rem; }
          .loan-advisory__wrapper .skewed-box__blue .content .recommendation__wrapper .business-details__wrapper .row .input-value__wrapper .basic-single .select__control .select__single-value {
            font-weight: 500; }
    .loan-advisory__wrapper .skewed-box__blue .content .submit-gst__wrapper {
      display: block !important; }
      .loan-advisory__wrapper .skewed-box__blue .content .submit-gst__wrapper .left-section {
        max-width: 100% !important; }
      .loan-advisory__wrapper .skewed-box__blue .content .submit-gst__wrapper .right-section {
        display: block !important; }
        .loan-advisory__wrapper .skewed-box__blue .content .submit-gst__wrapper .right-section .input-gst__wrapper .input__generic {
          min-width: 100% !important;
          height: 6rem; }
        .loan-advisory__wrapper .skewed-box__blue .content .submit-gst__wrapper .right-section .input-gst__wrapper .no-gst__link {
          font-size: 1.7rem !important;
          bottom: -2rem !important; }
        .loan-advisory__wrapper .skewed-box__blue .content .submit-gst__wrapper .right-section .button__generic.submit-gst__button {
          margin-top: 6rem;
          min-width: 93%;
          height: 6rem; } }

.loan-advisory__wrapper .can-toggle__switch {
  border-radius: 3rem !important; }
  .loan-advisory__wrapper .can-toggle__switch:after {
    border-radius: 15px !important; }

.loan-advisory__wrapper .skewed-box__blue {
  background: #3670cc;
  height: 50vh;
  position: absolute;
  z-index: -1;
  transform: skew();
  background-image: linear-gradient(90deg, #001061 0%, #00CDBE 100%); }
  .loan-advisory__wrapper .skewed-box__blue .content {
    z-index: 1; }
    .loan-advisory__wrapper .skewed-box__blue .content .header_loanadvisory {
      margin: 2rem auto; }
      .loan-advisory__wrapper .skewed-box__blue .content .header_loanadvisory .nav-link {
        display: block;
        padding: 0.5rem 1.5rem;
        color: #fff; }
        .loan-advisory__wrapper .skewed-box__blue .content .header_loanadvisory .nav-link:last-child {
          border: 1px solid white;
          border-radius: .5rem; }
    .loan-advisory__wrapper .skewed-box__blue .content .navbar {
      padding: 1% 10%; }
      .loan-advisory__wrapper .skewed-box__blue .content .navbar .MSMELoan_dropdown {
        background: #FFFFFF;
        box-shadow: 0px 0px 50px rgba(158, 158, 158, 0.16);
        border-radius: 10px;
        -webkit-clip-path: polygon(0% 7%, 88% 7%, 91% 0%, 94% 7%, 100% 7%, 100% 100%, 0% 100%);
                clip-path: polygon(0% 7%, 88% 7%, 91% 0%, 94% 7%, 100% 7%, 100% 100%, 0% 100%);
        color: #434343;
        padding: 2rem; }
        .loan-advisory__wrapper .skewed-box__blue .content .navbar .MSMELoan_dropdown .horizontal-line {
          border-right: 1px solid #e5e5e5; }
        .loan-advisory__wrapper .skewed-box__blue .content .navbar .MSMELoan_dropdown .MSMELoan_dropdownItems {
          margin-top: 1rem; }
          .loan-advisory__wrapper .skewed-box__blue .content .navbar .MSMELoan_dropdown .MSMELoan_dropdownItems:hover {
            background-color: #001061;
            color: #fff;
            border-radius: 0 20rem 20rem 0; }
    .loan-advisory__wrapper .skewed-box__blue .content .recommendation__wrapper {
      text-align: center;
      color: #fff;
      max-width: 90%;
      margin: 0 auto; }
      .loan-advisory__wrapper .skewed-box__blue .content .recommendation__wrapper p {
        max-width: 60%;
        margin: 0 auto;
        margin-bottom: 1rem; }
      .loan-advisory__wrapper .skewed-box__blue .content .recommendation__wrapper .user-input__wrapper {
        background-color: #fff;
        box-shadow: 0px 0px 20px rgba(56, 56, 56, 0.16);
        border-radius: 10px;
        color: #747474;
        margin: 1rem auto;
        padding: 2rem 0;
        position: relative; }
        .loan-advisory__wrapper .skewed-box__blue .content .recommendation__wrapper .user-input__wrapper .edit-button__wrapper {
          width: -webkit-max-content;
          width: -moz-max-content;
          width: max-content;
          border-radius: 15px;
          border: 1px solid #747474;
          float: right;
          margin-right: 3rem;
          padding: 0.5rem .8rem;
          font-size: 12px; }
          .loan-advisory__wrapper .skewed-box__blue .content .recommendation__wrapper .user-input__wrapper .edit-button__wrapper:hover {
            cursor: pointer; }
          .loan-advisory__wrapper .skewed-box__blue .content .recommendation__wrapper .user-input__wrapper .edit-button__wrapper svg.edit-button {
            right: 20px;
            top: 20px;
            fill: #00cdbe;
            margin-right: 0.5rem; }
        .loan-advisory__wrapper .skewed-box__blue .content .recommendation__wrapper .user-input__wrapper .row {
          border-bottom: 1px solid #e5e5e5;
          margin: 1rem 0; }
          .loan-advisory__wrapper .skewed-box__blue .content .recommendation__wrapper .user-input__wrapper .row div.col-md-3.col-sm-6:not(:last-child) {
            border-right: 1px solid #e6e6e6; }
          .loan-advisory__wrapper .skewed-box__blue .content .recommendation__wrapper .user-input__wrapper .row .heading {
            margin-bottom: 3rem;
            text-align: left;
            font-weight: 400;
            color: #747474; }
          .loan-advisory__wrapper .skewed-box__blue .content .recommendation__wrapper .user-input__wrapper .row .interest-rate-input-value {
            display: flex;
            justify-content: space-around; }
            .loan-advisory__wrapper .skewed-box__blue .content .recommendation__wrapper .user-input__wrapper .row .interest-rate-input-value > * {
              width: 30%; }
          .loan-advisory__wrapper .skewed-box__blue .content .recommendation__wrapper .user-input__wrapper .row .align-left {
            text-align: left;
            font-weight: 400;
            color: black; }
        .loan-advisory__wrapper .skewed-box__blue .content .recommendation__wrapper .user-input__wrapper .loan-tenure__wrapper {
          display: flex;
          flex: 1 1; }
          .loan-advisory__wrapper .skewed-box__blue .content .recommendation__wrapper .user-input__wrapper .loan-tenure__wrapper:hover {
            cursor: pointer; }
          .loan-advisory__wrapper .skewed-box__blue .content .recommendation__wrapper .user-input__wrapper .loan-tenure__wrapper .section {
            border: 1px solid #8c8c8c;
            border-radius: 0.4rem;
            flex-grow: 1;
            flex-basis: 0;
            padding: 1rem 0.5rem; }
          .loan-advisory__wrapper .skewed-box__blue .content .recommendation__wrapper .user-input__wrapper .loan-tenure__wrapper .active-section {
            background-color: #00cdbe;
            color: #fff;
            scale: 120%;
            flex-grow: 1;
            flex-basis: 0;
            border: none;
            transform: scale(1.1); }
    .loan-advisory__wrapper .skewed-box__blue .content .business-details__wrapper {
      max-width: 95%;
      background-color: #fff;
      color: #747474;
      margin: 0rem auto;
      text-align: left;
      margin-top: 2rem; }
      .loan-advisory__wrapper .skewed-box__blue .content .business-details__wrapper .details-saved__wrapper {
        border: 1px solid #00cdbe;
        border-radius: 50px;
        padding: 0.5rem 1rem;
        margin-left: 2rem; }
        .loan-advisory__wrapper .skewed-box__blue .content .business-details__wrapper .details-saved__wrapper svg {
          fill: #00cdbe;
          transform: translateY(-10%); }
      .loan-advisory__wrapper .skewed-box__blue .content .business-details__wrapper .business-details-heading__wrapper .business-details__heading {
        color: #434343;
        font-size: 1.8rem;
        margin-left: 2rem; }
      .loan-advisory__wrapper .skewed-box__blue .content .business-details__wrapper .input-value__wrapper {
        margin-top: 3rem;
        display: flex;
        justify-content: space-between;
        box-shadow: 0px 4px 20px rgba(88, 88, 88, 0.06);
        border-radius: 10px;
        padding: 1rem 2rem;
        max-width: 95%; }
        .loan-advisory__wrapper .skewed-box__blue .content .business-details__wrapper .input-value__wrapper > * {
          width: 47%; }
    .loan-advisory__wrapper .skewed-box__blue .content .products__row {
      display: flex;
      justify-content: space-between;
      max-width: 90%;
      margin: 2rem auto;
      padding: 4rem 0; }
      .loan-advisory__wrapper .skewed-box__blue .content .products__row .card__wrapper {
        background: #ffffff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        width: 18%;
        padding: 1rem 2rem; }
        .loan-advisory__wrapper .skewed-box__blue .content .products__row .card__wrapper:nth-child(odd) {
          margin: 4rem 0; }
        .loan-advisory__wrapper .skewed-box__blue .content .products__row .card__wrapper:nth-child(even) {
          margin: 2rem 0; }
        .loan-advisory__wrapper .skewed-box__blue .content .products__row .card__wrapper:nth-child(3) {
          margin: 0; }
        .loan-advisory__wrapper .skewed-box__blue .content .products__row .card__wrapper h3 {
          color: #00cdbe; }
        .loan-advisory__wrapper .skewed-box__blue .content .products__row .card__wrapper .grey__text {
          font-size: 1.2rem;
          color: #9f9fc0;
          margin: 1rem 0; }
    .loan-advisory__wrapper .skewed-box__blue .content .submit-gst__wrapper {
      border: 1px solid #00cdbe;
      display: flex;
      justify-content: space-between;
      max-width: 90%;
      margin: 2rem auto;
      padding: 1.5rem 2rem;
      border-radius: 10px; }
      .loan-advisory__wrapper .skewed-box__blue .content .submit-gst__wrapper .left-section {
        max-width: 40%;
        color: #434343;
        font-size: 2rem; }
      .loan-advisory__wrapper .skewed-box__blue .content .submit-gst__wrapper .right-section {
        display: flex; }
        .loan-advisory__wrapper .skewed-box__blue .content .submit-gst__wrapper .right-section button {
          margin: 1rem; }
        .loan-advisory__wrapper .skewed-box__blue .content .submit-gst__wrapper .right-section .input-gst__wrapper {
          position: relative; }
          .loan-advisory__wrapper .skewed-box__blue .content .submit-gst__wrapper .right-section .input-gst__wrapper .no-gst__link {
            position: absolute;
            bottom: -1rem;
            right: 0;
            font-size: 1.2rem; }
            .loan-advisory__wrapper .skewed-box__blue .content .submit-gst__wrapper .right-section .input-gst__wrapper .no-gst__link:hover {
              font-size: 1.3rem;
              cursor: pointer; }

.validate-username__wrapper {
  background-color: #e5e5e5;
  height: 100vh; }
  .validate-username__wrapper .gradient-background {
    background: linear-gradient(9.43deg, #001061 0%, #00CDBE 100%);
    height: 30vh; }
    .validate-username__wrapper .gradient-background img {
      margin: 4rem; }
  .validate-username__wrapper .card-wrapper {
    border: none;
    border-radius: 1rem;
    transform: translateY(-40%);
    background-color: #fff;
    max-width: 40%;
    min-width: 30rem;
    margin: 0 auto;
    padding: 5rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .validate-username__wrapper .card-wrapper > * {
      margin: 1rem 0; }
  .validate-username__wrapper h1 {
    color: #001061; }
  .validate-username__wrapper .otp-success__text {
    color: #00cdbe;
    font-size: 2.2rem; }

.my-documents__wrapper {
  background: #e5e5e5;
  padding-left: 5%;
  padding-right: 5%; }
  .my-documents__wrapper .headings__wrapper {
    padding: 2% 0; }
    .my-documents__wrapper .headings__wrapper .note-for-user {
      font-weight: normal;
      font-size: 14px;
      color: #9f9fc0; }
    .my-documents__wrapper .headings__wrapper .main-heading__wrapper {
      padding-bottom: 3%; }
      .my-documents__wrapper .headings__wrapper .main-heading__wrapper .main-heading__styling {
        font-weight: bold;
        font-size: 22px;
        line-height: 26px;
        letter-spacing: 0.02em;
        color: #001061; }
  .my-documents__wrapper .document-body__wrapper {
    overflow: auto;
    height: 80vh; }
    .my-documents__wrapper .document-body__wrapper::-webkit-scrollbar {
      width: 10px; }
    .my-documents__wrapper .document-body__wrapper::-webkit-scrollbar-track {
      background-color: #d3cccc;
      border-radius: 10px; }
    .my-documents__wrapper .document-body__wrapper::-webkit-scrollbar-thumb {
      background: #001061;
      border-radius: 10px; }
    .my-documents__wrapper .document-body__wrapper::-webkit-scrollbar-thumb:hover {
      background: #041d8f; }
    .my-documents__wrapper .document-body__wrapper .main-box__wrapper {
      flex-wrap: wrap;
      justify-content: flex-start; }
      .my-documents__wrapper .document-body__wrapper .main-box__wrapper .sub-box__wrapper {
        margin: 1.5rem; }
        .my-documents__wrapper .document-body__wrapper .main-box__wrapper .sub-box__wrapper .download-icon {
          position: absolute;
          top: 3%;
          right: 5%; }
        .my-documents__wrapper .document-body__wrapper .main-box__wrapper .sub-box__wrapper .document-image {
          width: 190px;
          height: 100px; }
        .my-documents__wrapper .document-body__wrapper .main-box__wrapper .sub-box__wrapper .document-title {
          height: 25px;
          padding-left: 15px;
          font-style: normal;
          font-weight: normal;
          color: #001061; }
    .my-documents__wrapper .document-body__wrapper .category-title__wrapper {
      font-weight: normal;
      font-size: 18px;
      color: #00cdbe; }
    .my-documents__wrapper .document-body__wrapper .entity-docs__wrapper {
      margin-bottom: 4em; }

.applicant-co-applicant__wrapper, .kingdom-quest-top-preferences__wrapper {
  background: #e5e5e5;
  padding-left: 5%;
  padding-right: 5%;
  height: 100vh;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .applicant-co-applicant__wrapper .headings__wrapper, .kingdom-quest-top-preferences__wrapper .headings__wrapper {
    padding: 2% 2% 3% 2%;
    width: 100rem; }
    .applicant-co-applicant__wrapper .headings__wrapper .note-for-user, .kingdom-quest-top-preferences__wrapper .headings__wrapper .note-for-user {
      font-weight: normal;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      color: #9f9fc0; }
      .applicant-co-applicant__wrapper .headings__wrapper .note-for-user .btn.add-co-applicant, .kingdom-quest-top-preferences__wrapper .headings__wrapper .note-for-user .btn.add-co-applicant {
        width: 170px; }
    .applicant-co-applicant__wrapper .headings__wrapper .main-heading__wrapper, .kingdom-quest-top-preferences__wrapper .headings__wrapper .main-heading__wrapper {
      padding-bottom: 3%; }
      .applicant-co-applicant__wrapper .headings__wrapper .main-heading__wrapper .main-heading__styling, .kingdom-quest-top-preferences__wrapper .headings__wrapper .main-heading__wrapper .main-heading__styling {
        font-weight: bold;
        font-size: 28px;
        color: #00cdbe; }
      .applicant-co-applicant__wrapper .headings__wrapper .main-heading__wrapper .back-arrow-icon, .kingdom-quest-top-preferences__wrapper .headings__wrapper .main-heading__wrapper .back-arrow-icon {
        margin: auto 0;
        color: #00cdbe; }
  .applicant-co-applicant__wrapper .main-box__wrapper .sub-box__wrapper, .kingdom-quest-top-preferences__wrapper .main-box__wrapper .sub-box__wrapper {
    padding: 20px 4%;
    width: 100rem;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap; }
    .applicant-co-applicant__wrapper .main-box__wrapper .sub-box__wrapper .document-title, .kingdom-quest-top-preferences__wrapper .main-box__wrapper .sub-box__wrapper .document-title {
      font-weight: bold;
      font-size: 18px;
      color: #001061;
      padding-left: 0; }
    .applicant-co-applicant__wrapper .main-box__wrapper .sub-box__wrapper .document-detail, .kingdom-quest-top-preferences__wrapper .main-box__wrapper .sub-box__wrapper .document-detail {
      width: 150px;
      margin-bottom: 1.2em;
      margin-bottom: 1.2em;
      padding: 10px; }
      .applicant-co-applicant__wrapper .main-box__wrapper .sub-box__wrapper .document-detail .document-sub-heading, .kingdom-quest-top-preferences__wrapper .main-box__wrapper .sub-box__wrapper .document-detail .document-sub-heading {
        font-weight: bold;
        font-size: 13px;
        color: #434343; }
      .applicant-co-applicant__wrapper .main-box__wrapper .sub-box__wrapper .document-detail .document-sub-details, .kingdom-quest-top-preferences__wrapper .main-box__wrapper .sub-box__wrapper .document-detail .document-sub-details {
        font-weight: normal;
        font-size: 13px;
        color: #000000; }

.my-loans-track-loans__wrapper {
  background-color: #e5e5e5;
  background: #e5e5e5;
  padding-left: 5%;
  padding-right: 5%; }
  .my-loans-track-loans__wrapper .headings__wrapper {
    padding: 2% 0; }
    .my-loans-track-loans__wrapper .headings__wrapper .note-for-user {
      font-weight: normal;
      font-size: 14px;
      color: #9f9fc0; }
    .my-loans-track-loans__wrapper .headings__wrapper .main-heading__wrapper {
      padding-bottom: 3%; }
      .my-loans-track-loans__wrapper .headings__wrapper .main-heading__wrapper .main-heading__styling {
        font-weight: bold;
        font-size: 22px;
        line-height: 26px;
        letter-spacing: 0.02em;
        color: #001061; }
  .my-loans-track-loans__wrapper .loans-body__wrapper .navbar {
    padding: 0; }
    .my-loans-track-loans__wrapper .loans-body__wrapper .navbar .nav .nav-item .nav-link {
      color: #9f9fc0;
      font-size: 14px;
      text-align: center;
      padding: 4px 13px;
      margin: 4px 6px; }
      .my-loans-track-loans__wrapper .loans-body__wrapper .navbar .nav .nav-item .nav-link.is-active {
        background: #fefefe;
        border-radius: 5px;
        color: #00cdbe; }
  .my-loans-track-loans__wrapper .loans-body__wrapper .loan-boxes {
    overflow: auto; }
    .my-loans-track-loans__wrapper .loans-body__wrapper .loan-boxes::-webkit-scrollbar {
      width: 10px; }
    .my-loans-track-loans__wrapper .loans-body__wrapper .loan-boxes::-webkit-scrollbar-track {
      background-color: #d3cccc;
      border-radius: 10px; }
    .my-loans-track-loans__wrapper .loans-body__wrapper .loan-boxes::-webkit-scrollbar-thumb {
      background: #001061;
      border-radius: 10px; }
    .my-loans-track-loans__wrapper .loans-body__wrapper .loan-boxes::-webkit-scrollbar-thumb:hover {
      background: #041d8f; }
    .my-loans-track-loans__wrapper .loans-body__wrapper .loan-boxes .main-box__wrapper {
      flex-wrap: wrap;
      justify-content: flex-start;
      padding: 0; }
      .my-loans-track-loans__wrapper .loans-body__wrapper .loan-boxes .main-box__wrapper .sub-box__wrapper {
        width: 250px;
        padding-left: 0px;
        margin-right: 1.5rem; }
        .my-loans-track-loans__wrapper .loans-body__wrapper .loan-boxes .main-box__wrapper .sub-box__wrapper .col-md-12 {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap; }
          .my-loans-track-loans__wrapper .loans-body__wrapper .loan-boxes .main-box__wrapper .sub-box__wrapper .col-md-12 .loan-title {
            font-weight: bold;
            font-size: 18px;
            color: #00cdbe; }
          .my-loans-track-loans__wrapper .loans-body__wrapper .loan-boxes .main-box__wrapper .sub-box__wrapper .col-md-12 .loan-status {
            font-weight: normal;
            font-size: 13px;
            color: #ffbf00; }
          .my-loans-track-loans__wrapper .loans-body__wrapper .loan-boxes .main-box__wrapper .sub-box__wrapper .col-md-12 .loan-type {
            font-weight: bold;
            font-size: 12px;
            letter-spacing: 0.02em;
            width: 100px;
            color: #505050; }
          .my-loans-track-loans__wrapper .loans-body__wrapper .loan-boxes .main-box__wrapper .sub-box__wrapper .col-md-12 .loan-id-stamp {
            font-size: 10px;
            text-align: right;
            color: #9f9fc0; }
          .my-loans-track-loans__wrapper .loans-body__wrapper .loan-boxes .main-box__wrapper .sub-box__wrapper .col-md-12 .loan-amount__wrapper .loan-amount-title {
            font-weight: normal;
            font-size: 10px;
            color: #9f9fc0; }
          .my-loans-track-loans__wrapper .loans-body__wrapper .loan-boxes .main-box__wrapper .sub-box__wrapper .col-md-12 .loan-amount__wrapper .loan-amount {
            font-size: 22px;
            font-weight: 500;
            color: #001061; }
          .my-loans-track-loans__wrapper .loans-body__wrapper .loan-boxes .main-box__wrapper .sub-box__wrapper .col-md-12.loan-stats {
            justify-content: flex-start; }
            .my-loans-track-loans__wrapper .loans-body__wrapper .loan-boxes .main-box__wrapper .sub-box__wrapper .col-md-12.loan-stats .interest-rate__wrapper,
            .my-loans-track-loans__wrapper .loans-body__wrapper .loan-boxes .main-box__wrapper .sub-box__wrapper .col-md-12.loan-stats .tenure__wrapper {
              margin: 5px; }
              .my-loans-track-loans__wrapper .loans-body__wrapper .loan-boxes .main-box__wrapper .sub-box__wrapper .col-md-12.loan-stats .interest-rate__wrapper .yellow-circle,
              .my-loans-track-loans__wrapper .loans-body__wrapper .loan-boxes .main-box__wrapper .sub-box__wrapper .col-md-12.loan-stats .tenure__wrapper .yellow-circle {
                background: #fff2af;
                border-radius: 50%;
                width: 35px;
                height: 35px; }
              .my-loans-track-loans__wrapper .loans-body__wrapper .loan-boxes .main-box__wrapper .sub-box__wrapper .col-md-12.loan-stats .interest-rate__wrapper .interest-rate .interest-rate-heading,
              .my-loans-track-loans__wrapper .loans-body__wrapper .loan-boxes .main-box__wrapper .sub-box__wrapper .col-md-12.loan-stats .tenure__wrapper .interest-rate .interest-rate-heading {
                font-weight: normal;
                font-size: 10px;
                color: #9f9fc0; }
              .my-loans-track-loans__wrapper .loans-body__wrapper .loan-boxes .main-box__wrapper .sub-box__wrapper .col-md-12.loan-stats .interest-rate__wrapper .interest-rate .interest-rate-value,
              .my-loans-track-loans__wrapper .loans-body__wrapper .loan-boxes .main-box__wrapper .sub-box__wrapper .col-md-12.loan-stats .tenure__wrapper .interest-rate .interest-rate-value {
                font-weight: 600;
                font-size: 12px;
                color: #505050; }
          .my-loans-track-loans__wrapper .loans-body__wrapper .loan-boxes .main-box__wrapper .sub-box__wrapper .col-md-12.loan-action-btn__wrapper {
            justify-content: flex-end; }
            .my-loans-track-loans__wrapper .loans-body__wrapper .loan-boxes .main-box__wrapper .sub-box__wrapper .col-md-12.loan-action-btn__wrapper .loan-application-action-button {
              border-radius: 5px;
              font-size: 12px;
              text-align: center;
              color: #fefefe;
              border: none; }
              .my-loans-track-loans__wrapper .loans-body__wrapper .loan-boxes .main-box__wrapper .sub-box__wrapper .col-md-12.loan-action-btn__wrapper .loan-application-action-button.cancel-application {
                background: #f3f3f3;
                width: 120px;
                height: 30px;
                justify-self: right; }
              .my-loans-track-loans__wrapper .loans-body__wrapper .loan-boxes .main-box__wrapper .sub-box__wrapper .col-md-12.loan-action-btn__wrapper .loan-application-action-button.continue-application {
                background: #00cdbe;
                width: 100%;
                height: 30px; }

.kingdom-quest-top-preferences__wrapper {
  height: 100%; }
  .kingdom-quest-top-preferences__wrapper .preferences-box .main-box__wrapper {
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0; }
    .kingdom-quest-top-preferences__wrapper .preferences-box .main-box__wrapper .sub-box__wrapper {
      width: 34rem;
      padding-left: 0px;
      padding-right: 0px;
      margin: 1rem; }
      .kingdom-quest-top-preferences__wrapper .preferences-box .main-box__wrapper .sub-box__wrapper .col-md-12 {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap; }
        .kingdom-quest-top-preferences__wrapper .preferences-box .main-box__wrapper .sub-box__wrapper .col-md-12 .bank-name-logo {
          width: 100px; }
        .kingdom-quest-top-preferences__wrapper .preferences-box .main-box__wrapper .sub-box__wrapper .col-md-12 .loan-status {
          border-radius: 5px;
          width: 20px;
          height: 20px; }
          .kingdom-quest-top-preferences__wrapper .preferences-box .main-box__wrapper .sub-box__wrapper .col-md-12 .loan-status.approved {
            background: #00cdbe; }
        .kingdom-quest-top-preferences__wrapper .preferences-box .main-box__wrapper .sub-box__wrapper .col-md-12 .bank-name {
          font-weight: bold;
          font-size: 12px;
          letter-spacing: 0.02em;
          width: 100px;
          color: #505050; }
        .kingdom-quest-top-preferences__wrapper .preferences-box .main-box__wrapper .sub-box__wrapper .col-md-12 .loan-amount__wrapper .loan-amount-title {
          font-weight: normal;
          font-size: 10px;
          color: #9f9fc0; }
        .kingdom-quest-top-preferences__wrapper .preferences-box .main-box__wrapper .sub-box__wrapper .col-md-12 .loan-amount__wrapper .loan-amount {
          font-size: 22px;
          font-weight: 500;
          color: #001061; }
        .kingdom-quest-top-preferences__wrapper .preferences-box .main-box__wrapper .sub-box__wrapper .col-md-12.loan-stats {
          justify-content: space-between; }
          .kingdom-quest-top-preferences__wrapper .preferences-box .main-box__wrapper .sub-box__wrapper .col-md-12.loan-stats .interest-rate__wrapper,
          .kingdom-quest-top-preferences__wrapper .preferences-box .main-box__wrapper .sub-box__wrapper .col-md-12.loan-stats .tenure__wrapper {
            margin: 5px; }
            .kingdom-quest-top-preferences__wrapper .preferences-box .main-box__wrapper .sub-box__wrapper .col-md-12.loan-stats .interest-rate__wrapper .yellow-circle,
            .kingdom-quest-top-preferences__wrapper .preferences-box .main-box__wrapper .sub-box__wrapper .col-md-12.loan-stats .tenure__wrapper .yellow-circle {
              background: #fff2af;
              border-radius: 50%;
              width: 35px;
              height: 35px; }
            .kingdom-quest-top-preferences__wrapper .preferences-box .main-box__wrapper .sub-box__wrapper .col-md-12.loan-stats .interest-rate__wrapper .interest-rate .interest-rate-heading,
            .kingdom-quest-top-preferences__wrapper .preferences-box .main-box__wrapper .sub-box__wrapper .col-md-12.loan-stats .tenure__wrapper .interest-rate .interest-rate-heading {
              font-weight: normal;
              font-size: 10px;
              color: #9f9fc0; }
            .kingdom-quest-top-preferences__wrapper .preferences-box .main-box__wrapper .sub-box__wrapper .col-md-12.loan-stats .interest-rate__wrapper .interest-rate .interest-rate-value,
            .kingdom-quest-top-preferences__wrapper .preferences-box .main-box__wrapper .sub-box__wrapper .col-md-12.loan-stats .tenure__wrapper .interest-rate .interest-rate-value {
              font-weight: 600;
              font-size: 12px;
              color: #505050; }
              .kingdom-quest-top-preferences__wrapper .preferences-box .main-box__wrapper .sub-box__wrapper .col-md-12.loan-stats .interest-rate__wrapper .interest-rate .interest-rate-value.value-color,
              .kingdom-quest-top-preferences__wrapper .preferences-box .main-box__wrapper .sub-box__wrapper .col-md-12.loan-stats .tenure__wrapper .interest-rate .interest-rate-value.value-color {
                color: #00cdbe;
                font-size: 14px; }

.preview-your-application__wrapper {
  background: #e5e5e5;
  height: 100%;
  padding: 2.5rem; }
  .preview-your-application__wrapper .main-box__wrapper {
    padding: 0px; }
    .preview-your-application__wrapper .main-box__wrapper .sub-box__wrapper {
      width: 100rem;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap; }
      .preview-your-application__wrapper .main-box__wrapper .sub-box__wrapper.preview-documents {
        margin: 1rem;
        min-width: 10rem; }
      .preview-your-application__wrapper .main-box__wrapper .sub-box__wrapper.blue-bg .sub-box__wrapper {
        width: 24rem; }
        .preview-your-application__wrapper .main-box__wrapper .sub-box__wrapper.blue-bg .sub-box__wrapper .document-image {
          width: 100%;
          height: 100px; }
        .preview-your-application__wrapper .main-box__wrapper .sub-box__wrapper.blue-bg .sub-box__wrapper .document-title {
          height: 25px;
          padding-left: 2%;
          width: 100%;
          font-style: normal;
          font-weight: normal;
          color: #001061; }
      .preview-your-application__wrapper .main-box__wrapper .sub-box__wrapper .loan-details-main-heading {
        font-weight: bold;
        color: #001061; }
      .preview-your-application__wrapper .main-box__wrapper .sub-box__wrapper .document-detail {
        width: 150px;
        margin-bottom: 0.8em;
        padding: 5px 0px 1px 15px; }
        .preview-your-application__wrapper .main-box__wrapper .sub-box__wrapper .document-detail .document-sub-heading {
          font-weight: bold;
          font-size: 14px;
          color: #434343; }
          .preview-your-application__wrapper .main-box__wrapper .sub-box__wrapper .document-detail .document-sub-heading.color-blue {
            color: #00CDBE; }
          .preview-your-application__wrapper .main-box__wrapper .sub-box__wrapper .document-detail .document-sub-heading.color-red {
            color: #FF8888; }
        .preview-your-application__wrapper .main-box__wrapper .sub-box__wrapper .document-detail .document-sub-details {
          font-weight: normal;
          font-size: 14px;
          color: #000000; }

@media only screen and (max-width: 1200px) {
  .preview-your-application__wrapper .main-box__wrapper .sub-box__wrapper.blue-bg .sub-box__wrapper .document-title {
    font-size: 14px; } }

.loan-application__wrapper .heading {
  font-size: 1.6rem;
  margin: 1% 0; }

.loan-application__wrapper .amount-tenure__wrapper .previous-values__row {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 2rem; }

.loan-application__wrapper .amount-tenure__wrapper .number-range__wrapper {
  margin-top: 3rem;
  width: 50%; }

.loan-application__wrapper .amount-tenure__wrapper .tooltip-range__input {
  position: relative;
  text-align: center;
  width: 110px;
  color: #00cdbe;
  font-size: 2.2rem;
  transform: translate(-50%, 0%);
  background-color: transparent; }

.loan-application__wrapper .amount-tenure__wrapper .loan-tenure__wrapper > input {
  width: 50px; }

.loan-application__wrapper .amount-tenure__wrapper .input-range__label.input-range__label--value .input-range__label-container {
  display: none; }

.loan-application__wrapper .loan-box__wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 80%; }
  .loan-application__wrapper .loan-box__wrapper .loan-purpose-value__box {
    border: 1px solid #CECFCF;
    border-radius: .5rem;
    padding: 1rem 1.5rem;
    font-size: 1.2rem;
    margin: 1rem 1rem 1rem 0;
    min-width: 23%;
    max-width: 23%;
    text-transform: uppercase; }
    .loan-application__wrapper .loan-box__wrapper .loan-purpose-value__box:hover {
      cursor: pointer; }
    .loan-application__wrapper .loan-box__wrapper .loan-purpose-value__box img {
      height: 40px; }
    .loan-application__wrapper .loan-box__wrapper .loan-purpose-value__box div {
      font-size: 1.2rem;
      margin-top: 1rem; }
  .loan-application__wrapper .loan-box__wrapper .active-card {
    background-color: #00cdbf17;
    border: 2px solid #00cdbe; }

.loan-application__wrapper .existing-gst__wrapper .blue__text {
  color: #001061;
  font-weight: bold; }

.loan-application__wrapper .existing-gst__wrapper .entity-heading {
  color: #00cdbe;
  font-weight: bold;
  margin-top: 2rem;
  cursor: pointer; }

.loan-application__wrapper .existing-gst__wrapper .gst-number__count {
  font-size: 1.2rem;
  font-weight: bold;
  color: #1d1d1d; }

.loan-application__wrapper .existing-gst__wrapper .gst-number {
  font-size: 1.2rem; }

.preview-your-application__wrapper.loanApplication {
  background: #ffffff; }
  .preview-your-application__wrapper.loanApplication .detailsIcon-wrapper {
    display: flex;
    justify-content: space-between; }
    .preview-your-application__wrapper.loanApplication .detailsIcon-wrapper .icon {
      color: #00CDBE; }
  .preview-your-application__wrapper.loanApplication .CheckBox--wrapper {
    display: flex; }
  .preview-your-application__wrapper.loanApplication .GSTIN-OTP {
    display: inline;
    margin-left: 1rem;
    font-weight: normal;
    border-radius: 14px;
    font-size: 12px;
    padding: 0.4rem 1rem 0.4rem 0.4rem;
    background-color: #00CDBE;
    color: #ffffff; }
    .preview-your-application__wrapper.loanApplication .GSTIN-OTP .icon-arrow-check {
      font-weight: 100px; }

@media screen and (max-width: 420px) {
  .loan-application__wrapper .loan-box__wrapper .loan-purpose-value__box {
    min-width: 90%; }
  .loan-application__wrapper .amount-tenure__wrapper .previous-values__row {
    display: inline-grid; }
    .loan-application__wrapper .amount-tenure__wrapper .previous-values__row .left-section {
      margin-top: 1.5rem; }
    .loan-application__wrapper .amount-tenure__wrapper .previous-values__row .right-section {
      margin-top: 1.5rem; }
  .loan-application__wrapper .amount-tenure__wrapper .select-width__generic {
    width: 270px; } }

.my-business__wrapper {
  background-color: #e5e5e5; }
  .my-business__wrapper .main-box__wrapper {
    flex-wrap: wrap; }
    .my-business__wrapper .main-box__wrapper .sub-box__wrapper {
      width: 300px; }
      .my-business__wrapper .main-box__wrapper .sub-box__wrapper.GreyColor {
        background: #e5e5e5;
        width: 300px;
        border: 1px dashed #9999bd; }
  .my-business__wrapper .heading_content {
    font-size: 25px;
    font-weight: 700 !important;
    line-height: 37px;
    color: #00cdbe;
    margin-bottom: 20%; }
  .my-business__wrapper .Biz360Generated {
    color: #9999bd; }
    .my-business__wrapper .Biz360Generated.content {
      margin-bottom: 10%; }
    .my-business__wrapper .Biz360Generated.addnewBusiness {
      width: 90%;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem; }
  .my-business__wrapper .btn_div {
    margin-top: 2rem; }
  .my-business__wrapper .notification_div {
    display: flex;
    justify-content: space-between;
    padding: 5%; }
    .my-business__wrapper .notification_div .heading_notify {
      color: black; }
    .my-business__wrapper .notification_div .heading_showAll {
      font-size: 14px;
      color: #3670cc; }
  .my-business__wrapper .notifications_sub-div {
    background: #f5f5fb;
    border-radius: 15px;
    padding: 3%;
    margin: 2%; }
    .my-business__wrapper .notifications_sub-div .circle {
      padding: 2%;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: #fafafa; }

.header_div {
  display: flex;
  justify-content: space-between; }
  .header_div .header_MyBusiness {
    font-weight: 700 !important;
    font-size: 25px;
    line-height: 26px;
    color: #001061;
    margin: 15px; }
  .header_div .business_profile {
    display: flex; }
    .header_div .business_profile .profile_image {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: white;
      margin-top: 13px; }
    .header_div .business_profile .profile_name {
      line-height: 19px;
      color: #00cdbe;
      padding-top: 5px;
      margin: 15px; }
  .header_div .header_Business {
    font-weight: bold;
    font-size: 32px;
    color: #00cdbe; }

.lessThan_icon {
  margin-right: 10px; }

.creditReport__wrapper {
  background-color: #e5e5e5;
  min-height: 100vh; }
  .creditReport__wrapper .card_wrapper {
    padding: 2rem;
    margin-bottom: 2rem; }
  .creditReport__wrapper .main-box__wrapper {
    flex-wrap: wrap; }
    .creditReport__wrapper .main-box__wrapper .sub-box__wrapper {
      width: 320px;
      padding: 2rem;
      display: flex;
      flex-wrap: wrap;
      align-content: space-between; }
  .creditReport__wrapper .subHeadings {
    font-weight: 500;
    line-height: 19px;
    color: #141437; }
  .creditReport__wrapper .content {
    font-size: 14px;
    line-height: 19px;
    color: #9f9fc0;
    margin-top: 10px;
    margin-bottom: 10px; }
  .creditReport__wrapper .btn_div {
    margin-top: 10px; }
    .creditReport__wrapper .btn_div .buttonGeneric_Blue {
      width: 20rem;
      font-size: 13px; }

.heading_MyBusiness {
  font-size: 14px;
  color: #9f9fc0;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 5px; }

.loanOffer {
  background: #e5e5e5;
  padding: 10px; }
  .loanOffer .main-box__wrapper {
    justify-content: flex-start;
    flex-wrap: wrap; }
    .loanOffer .main-box__wrapper .sub-box__wrapper {
      margin: 1rem; }
      .loanOffer .main-box__wrapper .sub-box__wrapper.top_Product {
        width: 28rem;
        display: flex;
        flex-wrap: wrap;
        align-content: space-between; }
  .loanOffer .heading_Loan {
    font-weight: bold;
    color: #505050;
    margin-top: 10px;
    margin-left: 10px;
    width: 45%; }
  .loanOffer .label__Why {
    font-size: 10px;
    line-height: 14px;
    color: #9f9fc0;
    margin-top: 10px;
    margin-left: 10px; }
    .loanOffer .label__Why.not {
      margin-top: 20px; }
  .loanOffer .loan_Contents_Box {
    display: flex; }
    .loanOffer .loan_Contents_Box .loan_Contents {
      font-size: 11px;
      color: #1d1d1d;
      margin-top: 10px;
      margin-left: 10px;
      margin-right: 10px; }
    .loanOffer .loan_Contents_Box .dot_icons {
      color: #00cdbe;
      margin-left: 10px;
      margin-top: 4px; }
  .loanOffer .otherProducts {
    font-weight: bold;
    font-size: 20px;
    line-height: 21px;
    color: #1d1d1d;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 10px; }
  .loanOffer .offer_div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 35px;
    margin-left: 10px;
    margin-bottom: 20px; }
    .loanOffer .offer_div .label_Offer {
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      color: #001061;
      margin-right: 35px; }
    .loanOffer .offer_div .buttonGeneric_Blue {
      width: 115px;
      height: 30px;
      font-weight: bold; }
  .loanOffer .card_wrapper {
    border-radius: 2px;
    margin-top: 20px; }
    .loanOffer .card_wrapper .heading_LoanApplicaton {
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      color: #001061;
      text-align: center;
      margin-top: 15px; }
    .loanOffer .card_wrapper .loanApplication_Details {
      margin-top: 30px;
      margin-left: 60px; }
      .loanOffer .card_wrapper .loanApplication_Details .label_Topics {
        font-size: 14px;
        color: #444040;
        margin-top: 20px; }
      .loanOffer .card_wrapper .loanApplication_Details .label_TopicsDetail {
        font-weight: bold;
        font-size: 14px;
        color: #343434;
        margin-top: 10px; }

.uploadDocuments {
  background: #E5E5E5; }
  .uploadDocuments .heading_Document {
    font-size: 21px;
    line-height: 25px;
    color: #00CDBE; }
  .uploadDocuments .main-box__wrapper {
    justify-content: flex-start;
    flex-wrap: wrap; }
    .uploadDocuments .main-box__wrapper .sub-box__wrapper {
      padding: 1rem;
      width: 240px;
      height: 225px;
      margin: 1rem;
      position: relative; }
      .uploadDocuments .main-box__wrapper .sub-box__wrapper .label_Documents {
        font-weight: bold;
        font-size: 14px;
        color: #505050;
        margin-top: 10px; }
      .uploadDocuments .main-box__wrapper .sub-box__wrapper .select_Box {
        background: #FFFFFF;
        border: white;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 3px;
        width: 210px;
        height: 36px;
        margin-top: 20px;
        margin-right: 10px; }
        .uploadDocuments .main-box__wrapper .sub-box__wrapper .select_Box .css-yk16xz-control {
          border-style: none; }
        .uploadDocuments .main-box__wrapper .sub-box__wrapper .select_Box .css-1wa3eu0-placeholder {
          font-style: italic;
          font-size: 12px;
          line-height: 12px;
          color: #A8A8A8; }
        .uploadDocuments .main-box__wrapper .sub-box__wrapper .select_Box .css-1okebmr-indicatorSeparator {
          width: 0em; }
        .uploadDocuments .main-box__wrapper .sub-box__wrapper .select_Box .css-tlfecz-indicatorContainer {
          color: #00CDBE; }
      .uploadDocuments .main-box__wrapper .sub-box__wrapper .documentPdf_delete_div {
        display: flex;
        justify-content: space-between;
        margin-top: 20px; }
        .uploadDocuments .main-box__wrapper .sub-box__wrapper .documentPdf_delete_div .documentPdf_box {
          border: 1px solid #9F9FC0;
          box-sizing: border-box;
          border-radius: 20px;
          width: 190px;
          height: 32px; }
          .uploadDocuments .main-box__wrapper .sub-box__wrapper .documentPdf_delete_div .documentPdf_box .documentPdf {
            font-size: 12px;
            line-height: 12px;
            color: #343434;
            margin-top: 10px;
            margin-left: 10px; }
        .uploadDocuments .main-box__wrapper .sub-box__wrapper .documentPdf_delete_div .delete_icon {
          color: #EDEDED;
          margin-top: 5px;
          margin-left: 20px; }
      .uploadDocuments .main-box__wrapper .sub-box__wrapper .buttonGeneric_Blue {
        width: 110px;
        height: 30px;
        margin-top: 15px;
        font-weight: bold;
        position: absolute;
        bottom: 1rem; }
        .uploadDocuments .main-box__wrapper .sub-box__wrapper .buttonGeneric_Blue .add_icon {
          color: white;
          margin-right: 3px; }

.eligibility__wrapper {
  max-width: 70%;
  padding: 0 1rem; }
  .eligibility__wrapper > * {
    margin: 1rem 0; }
  .eligibility__wrapper svg {
    fill: #00cdbe; }
  .eligibility__wrapper .card__wrapper {
    background-color: #fff;
    padding: 1rem 1.5rem;
    border-radius: 1rem; }
    .eligibility__wrapper .card__wrapper .heading {
      color: #001061;
      font-size: 2rem;
      margin-bottom: 1rem; }
    .eligibility__wrapper .card__wrapper .subheading {
      color: #434343;
      font-size: 1.4rem; }
    .eligibility__wrapper .card__wrapper .value {
      font-size: 2.6rem;
      color: #00cdbe; }
  .eligibility__wrapper .grey__text {
    color: #9F9FC0; }
  .eligibility__wrapper .bold {
    font-weight: 700; }
  .eligibility__wrapper .view-application {
    color: #3670CC;
    cursor: pointer; }

.dashboard-loan__wrapper .loan-application__wrapper {
  width: 80%;
  margin: 2%;
  padding: 1% 2%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 1px 1px 2px 2px #e5e5e5; }

.dashboard__wrapper .side__panel {
  background-color: #fafafa;
  min-height: 100vh;
  height: 100%; }
  .dashboard__wrapper .side__panel .side__panel-wrapper {
    padding-top: 10%;
    margin-left: 30%; }
    .dashboard__wrapper .side__panel .side__panel-wrapper .nav-links {
      color: #9999BD; }
      .dashboard__wrapper .side__panel .side__panel-wrapper .nav-links .nav-link {
        margin-top: 15%;
        padding: 0; }
        .dashboard__wrapper .side__panel .side__panel-wrapper .nav-links .nav-link:hover {
          cursor: pointer; }
    .dashboard__wrapper .side__panel .side__panel-wrapper .active {
      color: #001061;
      font-weight: 500;
      width: 100%;
      border-right: 5px solid #00cdbe; }

.dashboard__wrapper .main__panel {
  background-color: #ffffff;
  min-height: 100vh; }
  .dashboard__wrapper .main__panel .biz360-details__wrapper .card__wrapper {
    background-color: #fff;
    padding: 1rem 2rem;
    border-radius: 1rem; }
    .dashboard__wrapper .main__panel .biz360-details__wrapper .card__wrapper .update-gst__button {
      border: 1px solid #00cdbe;
      padding: .5rem 1rem;
      border-radius: .5rem; }
  .dashboard__wrapper .main__panel .dashboard-page__wrapper {
    width: 80%;
    margin: 2%;
    padding: 1% 2%;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 4px 4px 8px 4px #e5e5e5;
    position: absolute; }

.banking__wrapper .banking-heading {
  font-size: 2rem;
  color: #00cdbe;
  margin: 1rem 0; }

.banking__wrapper .banking__section {
  margin-bottom: 3rem;
  position: relative; }
  .banking__wrapper .banking__section .delete-profile__icon {
    position: absolute;
    top: 30px;
    right: 25px; }
    .banking__wrapper .banking__section .delete-profile__icon svg {
      color: #ed5a5a; }
    .banking__wrapper .banking__section .delete-profile__icon:hover {
      transform-origin: top center;
      -webkit-animation-name: swing;
      animation-name: swing;
      -webkit-animation-duration: .5s;
              animation-duration: .5s;
      cursor: pointer; }

.banking__wrapper .button__generic {
  background-color: #00cdbe;
  color: #fff;
  padding: .5rem 1rem;
  margin-right: 1rem;
  cursor: pointer; }

.banking__wrapper .button__grey {
  border: 1px solid #CECFCF;
  border-radius: 5px;
  padding: .5rem 1rem;
  color: #CECFCF;
  margin-right: 1rem;
  cursor: pointer; }

.banking__wrapper .select-handler {
  max-width: 200px; }

.banking__wrapper .file__wrapper {
  display: flex;
  margin-top: 10px; }
  .banking__wrapper .file__wrapper .file__container {
    border: 1px solid #000;
    border-radius: 1000px;
    margin-right: 10px;
    padding: 5px 10px; }
    .banking__wrapper .file__wrapper .file__container svg {
      fill: #FF3500;
      margin-right: .5rem; }

@media screen and (max-width: 420px) {
  .banking__wrapper .d-flex {
    display: inline !important; }
  .banking__wrapper .banking__section {
    margin-top: -2rem;
    margin-bottom: 1rem; }
  .banking__wrapper .button__grey {
    margin-top: 1rem;
    margin-right: 6rem; }
  .banking__wrapper .button__generic {
    max-width: 200px; } }

.login-wrapper {
  margin-top: 10rem; }
  .login-wrapper .content-box {
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    padding: 35px 50px 25px;
    width: 750px;
    margin: 0 auto; }
    .login-wrapper .content-box .heading {
      font-size: 24px;
      line-height: 1.2;
      color: #001061;
      margin: 0 0 25px 0; }
    .login-wrapper .content-box .dropdown {
      margin-top: 25px; }
    .login-wrapper .content-box .form-box .input-box,
    .login-wrapper .content-box .form-box .remember-box {
      margin: 20px 0; }
    .login-wrapper .content-box .form-box .input-box:first-child {
      margin-top: 0; }
    .login-wrapper .content-box .form-box .remember-box {
      display: flex;
      align-items: center; }
      .login-wrapper .content-box .form-box .remember-box input[type=checkbox] {
        height: 15px;
        width: 15px;
        margin: 0 7px 0 0; }
    .login-wrapper .content-box .form-box .actions-box {
      display: flex;
      flex-direction: column;
      margin-bottom: 15px; }
      .login-wrapper .content-box .form-box .actions-box button {
        width: 125px;
        background-color: #00cdbe;
        padding: 10px 12px 12px;
        border-radius: 25px;
        border: none;
        font-size: 18px;
        line-height: 0.89;
        letter-spacing: -0.1px;
        color: #ffffff; }
      .login-wrapper .content-box .form-box .actions-box button,
      .login-wrapper .content-box .form-box .actions-box span {
        margin-bottom: 10px; }
      .login-wrapper .content-box .form-box .actions-box a {
        margin-top: 15px; }

.overview-wrapper {
  margin-top: 30px; }
  .overview-wrapper * {
    padding: 0px;
    overflow-wrap: break-word; }
  .overview-wrapper label {
    font-weight: 700; }
  .overview-wrapper .content-wrapper {
    border-radius: 15px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #fafafa;
    background-color: #fafafa;
    padding: 15px 20px;
    margin-bottom: 20px;
    margin-left: 15px; }
    .overview-wrapper .content-wrapper h2 {
      font-size: 24px;
      line-height: 1;
      letter-spacing: -0.2px;
      color: #001061;
      margin: 10px 0; }
    .overview-wrapper .content-wrapper h3 {
      font-size: 24px;
      line-height: 1.18;
      letter-spacing: -0.2px;
      color: #001061;
      margin: 10px 0; }
    .overview-wrapper .content-wrapper h4 {
      font-size: 18px;
      line-height: 0.92;
      letter-spacing: normal;
      color: #00cdbe;
      border-bottom: 1px solid #e0e0e0;
      padding-bottom: 10px; }
    .overview-wrapper .content-wrapper label,
    .overview-wrapper .content-wrapper span {
      font-size: 14px;
      line-height: 1.19;
      letter-spacing: 0;
      color: #1d1d1d;
      display: block;
      text-overflow: ellipsis;
      overflow: hidden; }
    .overview-wrapper .content-wrapper .chart-box {
      margin-top: 45px; }
    .overview-wrapper .content-wrapper .content-box {
      margin-top: 15px; }
      .overview-wrapper .content-wrapper .content-box .content {
        margin-bottom: 10px; }
        .overview-wrapper .content-wrapper .content-box .content span address {
          line-height: inherit; }
        .overview-wrapper .content-wrapper .content-box .content:last-child {
          margin-bottom: 0; }

.overview-applicant-modal .otplink-modal h3 {
  text-align: center; }

.overview-applicant-modal .cross-icon {
  position: absolute;
  right: 25px;
  top: 25px;
  color: #bcbcbc;
  cursor: pointer; }

.overview-applicant-modal h3 {
  font-size: 28px;
  line-height: 1;
  letter-spacing: -0.2px;
  color: #001061;
  margin: 0;
  padding-bottom: 5px;
  border-bottom: 1px solid #bcbcbc; }
  .overview-applicant-modal h3 small {
    line-height: 1.67;
    letter-spacing: -0.1px;
    color: #001061; }

.overview-applicant-modal .ReactTable {
  margin: 20px auto 0; }
  .overview-applicant-modal .ReactTable .rt-thead .rt-tr .rt-th {
    position: relative;
    background-color: #001061;
    font-size: 14px;
    line-height: 1.25;
    letter-spacing: 0;
    color: #ffffff;
    padding: 10px;
    border-right-color: #fff; }
    .overview-applicant-modal .ReactTable .rt-thead .rt-tr .rt-th:first-child {
      text-align: left;
      padding-left: 15px; }
    .overview-applicant-modal .ReactTable .rt-thead .rt-tr .rt-th.-sort-asc::after {
      content: "\f0d7";
      position: absolute;
      right: 9px;
      top: 10px; }
    .overview-applicant-modal .ReactTable .rt-thead .rt-tr .rt-th.-sort-desc::after {
      content: "\f0d8";
      position: absolute;
      right: 9px;
      top: 10px; }
  .overview-applicant-modal .ReactTable .rt-tbody .rt-tr {
    font-size: 14px;
    line-height: 1.25;
    letter-spacing: 0;
    color: #1d1d1d; }
    .overview-applicant-modal .ReactTable .rt-tbody .rt-tr .rt-td {
      border-right-color: transparent;
      padding: 10px;
      text-align: center; }
      .overview-applicant-modal .ReactTable .rt-tbody .rt-tr .rt-td:first-child {
        text-align: left;
        padding-left: 15px; }
      .overview-applicant-modal .ReactTable .rt-tbody .rt-tr .rt-td label {
        margin-bottom: 0;
        font-size: 14px;
        line-height: 1.19;
        letter-spacing: normal;
        color: #1d1d1d; }
    .overview-applicant-modal .ReactTable .rt-tbody .rt-tr.-odd {
      background-color: #f0f0f0; }
    .overview-applicant-modal .ReactTable .rt-tbody .rt-tr.-even {
      background-color: #fafafa; }

.overview-applicant-modal .circular-progressbar {
  width: 125px;
  display: block;
  margin: 35px auto;
  border-radius: 50%;
  border: 1px solid #36ddd1; }
  .overview-applicant-modal .circular-progressbar .CircularProgressbar-trail {
    stroke: transparent;
    box-shadow: 2px 2px 2px 2px; }

.overview-applicant-modal .applicant-details,
.overview-applicant-modal .progress-list {
  list-style: none;
  margin-bottom: 0;
  margin-top: 15px; }
  .overview-applicant-modal .applicant-details li,
  .overview-applicant-modal .progress-list li {
    display: flex;
    align-items: flex-start;
    margin: 10px 0; }
    .overview-applicant-modal .applicant-details li:first-child,
    .overview-applicant-modal .progress-list li:first-child {
      margin-top: 0; }
    .overview-applicant-modal .applicant-details li:last-child,
    .overview-applicant-modal .progress-list li:last-child {
      margin-bottom: 0; }
    .overview-applicant-modal .applicant-details li label,
    .overview-applicant-modal .applicant-details li span,
    .overview-applicant-modal .progress-list li label,
    .overview-applicant-modal .progress-list li span {
      font-size: 14px;
      line-height: 1.19;
      letter-spacing: 0;
      color: #1d1d1d;
      margin-bottom: 0; }
    .overview-applicant-modal .applicant-details li label,
    .overview-applicant-modal .progress-list li label {
      flex: 0.6 1; }
    .overview-applicant-modal .applicant-details li span,
    .overview-applicant-modal .progress-list li span {
      flex: 1.4 1; }

.overview-applicant-modal .progress-list li label {
  flex: 0.5 1; }

.overview-applicant-modal .progress-list li .progress {
  margin-top: 2px;
  box-shadow: none;
  flex: 1.5 1;
  height: 12px;
  width: 100%;
  margin-bottom: 0;
  background-color: transparent;
  border: 1px solid #00cdbe;
  border-radius: 15px; }
  .overview-applicant-modal .progress-list li .progress .progress-bar {
    background-color: #00cdbe;
    line-height: 12px;
    font-size: 10px; }

.business-risk-wrapper {
  margin-top: 30px; }
  .business-risk-wrapper .row.upper-section .nav-tabs {
    margin-top: 0; }
  .business-risk-wrapper .row.upper-section ul {
    display: flex; }
    .business-risk-wrapper .row.upper-section ul li a {
      height: 100%;
      padding: 0 !important; }
  .business-risk-wrapper .row.upper-section .tabs-heading {
    padding: 10px 15px; }
  .business-risk-wrapper .row.upper-section .bold-text__smaller {
    font-size: 1.2rem;
    font-weight: bold !important; }
  .business-risk-wrapper .row.upper-section .margin-for-level {
    margin-top: 55px; }
  .business-risk-wrapper .box-wrapper {
    border-radius: 15px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #fafafa;
    margin-bottom: 35px;
    padding: 25px 20px; }
    .business-risk-wrapper .box-wrapper .box-header {
      border-bottom: 1px solid #e0e0e0;
      margin: 0 10px; }
      .business-risk-wrapper .box-wrapper .box-header .react-switch {
        float: right;
        margin-bottom: 10px; }
        .business-risk-wrapper .box-wrapper .box-header .react-switch .react-switch-bg {
          width: 200px !important;
          border-radius: 12px;
          background-color: #E0E0E0; }
      .business-risk-wrapper .box-wrapper .box-header .quarterDataFlag {
        color: #b53629;
        border: 1px solid #b53629;
        padding: 5px;
        border-radius: 5px;
        margin-left: 10px;
        float: right; }
    .business-risk-wrapper .box-wrapper .chart-box {
      margin-top: 25px;
      min-height: 200px; }
    .business-risk-wrapper .box-wrapper .box-footer {
      margin: 20px -20px -25px -20px;
      background-color: #001061;
      border-radius: 0 0 15px 15px; }
      .business-risk-wrapper .box-wrapper .box-footer ul {
        display: flex;
        margin-bottom: 0; }
        .business-risk-wrapper .box-wrapper .box-footer ul li {
          flex: 1 1;
          padding: 10px 15px;
          border-right: 1px solid rgba(255, 255, 255, 0.5);
          display: flex;
          flex-direction: column;
          justify-content: space-between; }
          .business-risk-wrapper .box-wrapper .box-footer ul li label {
            font-size: 1.4rem;
            line-height: 1.33;
            letter-spacing: 0;
            color: #ffffff; }
          .business-risk-wrapper .box-wrapper .box-footer ul li > span {
            font-size: 2.8rem;
            font-stretch: normal;
            line-height: 1.21;
            letter-spacing: normal;
            color: #ffffff;
            display: flex;
            align-items: center;
            margin-top: 10px; }
            .business-risk-wrapper .box-wrapper .box-footer ul li > span .icon {
              margin-top: -3px; }
        .business-risk-wrapper .box-wrapper .box-footer ul:last-child {
          border-top: 0.5px solid rgba(255, 255, 255, 0.5);
          border-radius: 0 0 15px 15px; }
          .business-risk-wrapper .box-wrapper .box-footer ul:last-child li:first-child {
            border-bottom-left-radius: 15px; }
          .business-risk-wrapper .box-wrapper .box-footer ul:last-child li:last-child {
            border-bottom-right-radius: 15px; }
    .business-risk-wrapper .box-wrapper h4 {
      font-size: 1.8rem;
      line-height: 0.92;
      letter-spacing: normal;
      color: #00cdbe;
      margin-top: 0; }
    .business-risk-wrapper .box-wrapper .select-box {
      margin-top: 15px;
      font-size: 1.6rem;
      line-height: 1.19;
      letter-spacing: 0;
      color: #1d1d1d;
      border: 1px solid #E0E0E0;
      padding: 3px; }
    .business-risk-wrapper .box-wrapper .content-box {
      margin-top: 20px; }
      .business-risk-wrapper .box-wrapper .content-box .content {
        margin: 20px 0; }
        .business-risk-wrapper .box-wrapper .content-box .content:first-child {
          margin-top: 0; }
        .business-risk-wrapper .box-wrapper .content-box .content:last-child {
          margin-bottom: 0; }
        .business-risk-wrapper .box-wrapper .content-box .content label,
        .business-risk-wrapper .box-wrapper .content-box .content span {
          font-size: 1.4rem;
          line-height: 1.19;
          letter-spacing: 0;
          color: #1d1d1d;
          display: block; }
    .business-risk-wrapper .box-wrapper .flag-box {
      display: flex;
      justify-content: flex-end;
      align-items: center; }
      .business-risk-wrapper .box-wrapper .flag-box span {
        color: #c60d0d; }
        .business-risk-wrapper .box-wrapper .flag-box span:last-child {
          margin-left: 10px; }
    .business-risk-wrapper .box-wrapper .vertical-divider-left {
      position: relative; }
      .business-risk-wrapper .box-wrapper .vertical-divider-left::before {
        position: absolute;
        left: -10px;
        content: '';
        height: 100%;
        width: 1px;
        border: 1px solid #e0e0e0; }
    .business-risk-wrapper .box-wrapper .funnel-chart-box {
      margin-top: 15px; }
      .business-risk-wrapper .box-wrapper .funnel-chart-box h5 {
        text-align: center;
        font-size: 1.4rem;
        line-height: 0.92;
        letter-spacing: normal;
        margin-top: 0;
        margin-left: 15%; }
      .business-risk-wrapper .box-wrapper .funnel-chart-box.customer-chart .circular-progressbar {
        border-radius: 50px;
        border: 2px solid #36ddd1; }
      .business-risk-wrapper .box-wrapper .funnel-chart-box.customer-chart .funnel-chart ul li > span > span {
        background-color: #36ddd1; }
      .business-risk-wrapper .box-wrapper .funnel-chart-box.vendor-chart .circular-progressbar {
        border-radius: 50px;
        border: 2px solid #56d9fe; }
      .business-risk-wrapper .box-wrapper .funnel-chart-box.vendor-chart .funnel-chart ul li > span > span {
        background-color: #56d9fe; }
      .business-risk-wrapper .box-wrapper .funnel-chart-box.product-chart .circular-progressbar {
        border-radius: 50px;
        border: 2px solid #3670cc; }
      .business-risk-wrapper .box-wrapper .funnel-chart-box.product-chart .funnel-chart ul li > span > span {
        background-color: #3670cc; }
      .business-risk-wrapper .box-wrapper .funnel-chart-box .circular-progressbar {
        width: 100px;
        margin: 10px 0 20px 45px; }
        .business-risk-wrapper .box-wrapper .funnel-chart-box .circular-progressbar .CircularProgressbar-trail {
          stroke: transparent;
          box-shadow: 2px 2px 2px 2px; }
      .business-risk-wrapper .box-wrapper .funnel-chart-box .funnel-chart {
        border-right: 1px solid #e0e0e0; }
        .business-risk-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul {
          width: 85%;
          margin: 0 auto; }
          .business-risk-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul li {
            display: flex; }
            .business-risk-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul li label {
              width: 130px;
              font-size: 1.4rem;
              line-height: 1;
              letter-spacing: normal;
              color: #1d1d1d;
              margin-bottom: 0;
              border-right: 1px solid #e0e0e0;
              padding-top: 3px;
              text-align: right;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              padding-right: 5px;
              text-align: start;
              word-wrap: break-word; }
            .business-risk-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul li > span {
              display: flex;
              margin-bottom: 5px; }
              .business-risk-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul li > span > span {
                display: block;
                margin: 0 auto;
                font-size: 1.4rem;
                line-height: 1.2;
                text-align: center;
                color: #ffffff;
                padding: 3px 0; }
            .business-risk-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul li:first-child > span > span {
              width: 100%; }
            .business-risk-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul li:nth-child(2) > span > span {
              width: 90%; }
            .business-risk-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul li:nth-child(3) > span > span {
              width: 80%; }
            .business-risk-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul li:nth-child(4) > span > span {
              width: 70%; }
            .business-risk-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul li:nth-child(5) > span > span {
              width: 60%; }
            .business-risk-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul li:nth-child(6) > span > span {
              width: 50%; }
            .business-risk-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul li:nth-child(7) > span > span {
              width: 40%; }
            .business-risk-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul li:nth-child(8) > span > span {
              width: 30%; }
            .business-risk-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul li:nth-child(9) > span > span {
              width: 20%; }
            .business-risk-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul li:last-child > span > span {
              width: 10%; }
    .business-risk-wrapper .box-wrapper.analysis-box {
      margin-top: 15px;
      margin-bottom: 0; }
      .business-risk-wrapper .box-wrapper.analysis-box table {
        width: 100%; }
        .business-risk-wrapper .box-wrapper.analysis-box table thead tr {
          background-color: #001061;
          color: #fff; }
        .business-risk-wrapper .box-wrapper.analysis-box table tbody tr:nth-child(even) {
          background-color: #f5f5f5; }
        .business-risk-wrapper .box-wrapper.analysis-box table tbody tr:nth-child(odd) {
          background-color: #f0f0f0; }
        .business-risk-wrapper .box-wrapper.analysis-box table td,
        .business-risk-wrapper .box-wrapper.analysis-box table th {
          padding: 10px 20px; }
      .business-risk-wrapper .box-wrapper.analysis-box p,
      .business-risk-wrapper .box-wrapper.analysis-box ol li {
        font-size: 1.4rem;
        line-height: 1.19;
        letter-spacing: 0;
        color: #1d1d1d;
        margin-left: 15px; }
      .business-risk-wrapper .box-wrapper.analysis-box p {
        margin-top: 10px;
        margin-bottom: 5px; }

.cash-flow-wrapper {
  margin-top: 30px;
  padding: 0px 70px; }
  .cash-flow-wrapper .btns-box button {
    width: 100%;
    margin-bottom: 10px;
    font-size: 1.6rem;
    line-height: 1.19;
    letter-spacing: 0;
    color: #ffffff;
    border: none;
    border-radius: 25px;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center; }
    .cash-flow-wrapper .btns-box button div {
      margin-right: 5px; }
  .cash-flow-wrapper .nav-tabs {
    margin: 0 30px;
    border-color: #bcbcbc; }
    .cash-flow-wrapper .nav-tabs > li {
      margin: 0 4px -1px;
      background-color: #e5e5e5;
      border-radius: 5px 5px 0 0;
      position: relative; }
      .cash-flow-wrapper .nav-tabs > li a {
        color: #1d1d1d;
        border-radius: 5px 5px 0 0;
        font-size: 1.4rem;
        line-height: 1.19;
        letter-spacing: 0;
        padding: 12px; }
        .cash-flow-wrapper .nav-tabs > li a > div {
          padding: 10px 20px; }
        .cash-flow-wrapper .nav-tabs > li a label {
          font-weight: 700;
          margin-bottom: 0; }
      .cash-flow-wrapper .nav-tabs > li:first-child a > div {
        padding: 18px 20px; }
      .cash-flow-wrapper .nav-tabs > li.active::after {
        content: "";
        width: 75%;
        height: 2px;
        background-color: #00cdbe;
        position: absolute;
        bottom: 0;
        left: 13%; }
      .cash-flow-wrapper .nav-tabs > li.active a {
        color: #1d1d1d;
        margin-right: 0;
        font-weight: 700; }
  .cash-flow-wrapper .tab-content {
    border-right: 1px solid #bcbcbc;
    border-bottom: 1px solid #bcbcbc;
    border-left: 1px solid #bcbcbc;
    border-top: 2px solid #e5e5e5;
    border-radius: 25px;
    margin-top: -2px;
    margin-bottom: 15px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    padding: 20px; }
    .cash-flow-wrapper .tab-content .row {
      display: block; }
    .cash-flow-wrapper .tab-content .entityName {
      font-size: 2rem;
      color: #00cdbe;
      padding: 0 15px;
      display: inline-block; }
    .cash-flow-wrapper .tab-content .actions-box {
      padding: 5px 10px;
      display: flex;
      justify-content: flex-end; }
      .cash-flow-wrapper .tab-content .actions-box span {
        padding: 0 10px; }
      .cash-flow-wrapper .tab-content .actions-box .icon-download {
        color: #2d9086;
        cursor: pointer; }
        .cash-flow-wrapper .tab-content .actions-box .icon-download svg {
          color: #1d6f42; }
      .cash-flow-wrapper .tab-content .actions-box .icon-upload {
        color: #001061;
        cursor: pointer;
        position: relative; }
      .cash-flow-wrapper .tab-content .actions-box .icon-download-pdf svg {
        color: red; }
    .cash-flow-wrapper .tab-content .box-content {
      background-color: #fafafa;
      border-radius: 12px;
      padding: 15px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
      .cash-flow-wrapper .tab-content .box-content .box-header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(0, 0, 0, 0.16);
        align-items: center;
        padding-bottom: 5px; }
        .cash-flow-wrapper .tab-content .box-content .box-header .box-heading {
          margin-top: 0;
          font-weight: 700;
          font-size: 1.6rem;
          line-height: 0.92;
          letter-spacing: normal;
          color: #00cdbe; }
        .cash-flow-wrapper .tab-content .box-content .box-header .actions {
          display: flex; }
          .cash-flow-wrapper .tab-content .box-content .box-header .actions .download-content {
            display: flex;
            justify-content: center;
            line-height: 1;
            text-align: center;
            color: #1d1d1d;
            align-items: center; }
            .cash-flow-wrapper .tab-content .box-content .box-header .actions .download-content span,
            .cash-flow-wrapper .tab-content .box-content .box-header .actions .download-content div {
              padding-right: 5px; }
            .cash-flow-wrapper .tab-content .box-content .box-header .actions .download-content span {
              display: inline-block;
              max-width: 75px; }
              .cash-flow-wrapper .tab-content .box-content .box-header .actions .download-content span.icon-pdf {
                color: #ed5a5a; }
              .cash-flow-wrapper .tab-content .box-content .box-header .actions .download-content span.icon-excel {
                color: #8bd591; }
            .cash-flow-wrapper .tab-content .box-content .box-header .actions .download-content div {
              cursor: pointer; }
        .cash-flow-wrapper .tab-content .box-content .box-header .edit-icon {
          color: #5f5f5f;
          border-bottom: 1px solid #5f5f5f;
          cursor: pointer;
          display: inline-block;
          margin-bottom: 3px; }
      .cash-flow-wrapper .tab-content .box-content .bank-details-list {
        list-style: none;
        margin: 10px 0; }
        .cash-flow-wrapper .tab-content .box-content .bank-details-list .sanctionLimitEdit {
          margin-left: 10px; }
          .cash-flow-wrapper .tab-content .box-content .bank-details-list .sanctionLimitEdit svg {
            color: #1d1d1d; }
          .cash-flow-wrapper .tab-content .box-content .bank-details-list .sanctionLimitEdit:hover {
            cursor: pointer; }
        .cash-flow-wrapper .tab-content .box-content .bank-details-list > li {
          display: flex;
          align-items: center;
          margin: 5px 0; }
          .cash-flow-wrapper .tab-content .box-content .bank-details-list > li label,
          .cash-flow-wrapper .tab-content .box-content .bank-details-list > li span {
            font-size: 1.4rem;
            line-height: 1.19;
            letter-spacing: 0;
            color: #1d1d1d;
            margin: 0;
            flex: 1 1; }
          .cash-flow-wrapper .tab-content .box-content .bank-details-list > li label {
            font-weight: 700; }
          .cash-flow-wrapper .tab-content .box-content .bank-details-list > li svg {
            color: #b22929; }
          .cash-flow-wrapper .tab-content .box-content .bank-details-list > li .months-list {
            flex: 1 1;
            display: flex;
            list-style: none;
            width: 100%; }
            .cash-flow-wrapper .tab-content .box-content .bank-details-list > li .months-list li {
              font-size: 1.2rem;
              line-height: 1.67;
              letter-spacing: normal;
              color: #ffffff;
              flex: 1 1;
              background-color: #00cdbe;
              text-align: center;
              border-right: 0.5px solid #fff; }
  .cash-flow-wrapper .box-wrapper {
    border-radius: 15px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #fafafa;
    margin-bottom: 35px;
    padding: 25px 20px; }
    .cash-flow-wrapper .box-wrapper .radio-btns {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      margin-top: 20px; }
      .cash-flow-wrapper .box-wrapper .radio-btns label {
        margin-right: 30px;
        margin-top: 10px; }
    .cash-flow-wrapper .box-wrapper .box-header {
      border-bottom: 1px solid #e0e0e0; }
      .cash-flow-wrapper .box-wrapper .box-header.tool-tip-box {
        display: flex;
        justify-content: space-between; }
      .cash-flow-wrapper .box-wrapper .box-header .tool-tip {
        margin-top: -3px; }
    .cash-flow-wrapper .box-wrapper .chart-details {
      margin-top: 20px; }
      .cash-flow-wrapper .box-wrapper .chart-details span {
        font-size: 1.6rem;
        line-height: 1.19;
        letter-spacing: normal;
        color: #1d1d1d; }
    .cash-flow-wrapper .box-wrapper .chart-box {
      margin-top: 25px;
      min-height: 200px; }
    .cash-flow-wrapper .box-wrapper .box-footer {
      margin: 20px -20px -25px -20px;
      background-color: #001061;
      border-radius: 0 0 15px 15px; }
      .cash-flow-wrapper .box-wrapper .box-footer ul {
        display: flex;
        margin-bottom: 0; }
        .cash-flow-wrapper .box-wrapper .box-footer ul li {
          flex: 1 1;
          padding: 10px 15px;
          border-right: 1px solid rgba(255, 255, 255, 0.5);
          display: flex;
          flex-direction: column;
          justify-content: space-between; }
          .cash-flow-wrapper .box-wrapper .box-footer ul li label {
            font-size: 1.4rem;
            line-height: 1.33;
            letter-spacing: 0;
            color: #ffffff;
            margin-bottom: 10px; }
          .cash-flow-wrapper .box-wrapper .box-footer ul li span {
            font-size: 2.4rem;
            font-stretch: normal;
            line-height: 1.21;
            letter-spacing: normal;
            color: #ffffff;
            display: flex;
            align-items: center; }
            .cash-flow-wrapper .box-wrapper .box-footer ul li span .icon {
              margin-top: -3px; }
        .cash-flow-wrapper .box-wrapper .box-footer ul:last-child {
          border-top: 0.5px solid rgba(255, 255, 255, 0.5);
          border-radius: 0 0 15px 15px; }
          .cash-flow-wrapper .box-wrapper .box-footer ul:last-child li:first-child {
            border-bottom-left-radius: 15px; }
          .cash-flow-wrapper .box-wrapper .box-footer ul:last-child li:last-child {
            border-bottom-right-radius: 15px; }
    .cash-flow-wrapper .box-wrapper h4 {
      font-weight: 700;
      font-size: 18px;
      line-height: 0.92;
      letter-spacing: normal;
      color: #00cdbe;
      margin-top: 0; }
    .cash-flow-wrapper .box-wrapper .select-box {
      margin-top: 15px;
      font-size: 1.6rem;
      line-height: 1.19;
      letter-spacing: 0;
      color: #1d1d1d;
      border: 1px solid #e0e0e0;
      padding: 3px; }
    .cash-flow-wrapper .box-wrapper .ReactTable {
      margin: 20px auto 40px; }
      .cash-flow-wrapper .box-wrapper .ReactTable .sticky {
        position: -webkit-sticky !important;
        position: sticky !important;
        left: 0;
        top: 0;
        z-index: 1;
        background-color: white;
        color: #00cdbe; }
      .cash-flow-wrapper .box-wrapper .ReactTable .rt-thead .rt-th {
        position: relative;
        background-color: #001061;
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 1.25;
        letter-spacing: 0;
        color: #ffffff;
        padding: 12px 0;
        border-right-color: #fff; }
        .cash-flow-wrapper .box-wrapper .ReactTable .rt-thead .rt-th.-sort-asc::after {
          content: "\f0d7";
          position: absolute;
          right: 9px;
          top: 10px;
          font-size: 1.6rem; }
        .cash-flow-wrapper .box-wrapper .ReactTable .rt-thead .rt-th.-sort-desc::after {
          content: "\f0d8";
          position: absolute;
          right: 9px;
          top: 10px;
          font-size: 1.6rem; }
      .cash-flow-wrapper .box-wrapper .ReactTable .rt-tbody {
        overflow: visible !important; }
        .cash-flow-wrapper .box-wrapper .ReactTable .rt-tbody .sticky {
          position: -webkit-sticky !important;
          position: sticky !important;
          left: 0;
          top: 0;
          z-index: 1;
          background-color: white;
          color: #00cdbe; }
        .cash-flow-wrapper .box-wrapper .ReactTable .rt-tbody .subIdentifier {
          position: absolute;
          top: 50px;
          left: 40px;
          color: #1d1d1d;
          font-size: 1.2rem; }
        .cash-flow-wrapper .box-wrapper .ReactTable .rt-tbody .rt-tr {
          font-size: 1.4rem;
          line-height: 1.25;
          letter-spacing: 0;
          text-align: center;
          color: #1d1d1d; }
          .cash-flow-wrapper .box-wrapper .ReactTable .rt-tbody .rt-tr .rt-td {
            border-right-color: transparent; }
            .cash-flow-wrapper .box-wrapper .ReactTable .rt-tbody .rt-tr .rt-td .icons {
              color: #fff;
              border-radius: 25px;
              width: 23px;
              height: 24px;
              display: inline-block;
              text-align: center;
              padding-top: 2px; }
              .cash-flow-wrapper .box-wrapper .ReactTable .rt-tbody .rt-tr .rt-td .icons .bold {
                font-weight: 700 !important; }
              .cash-flow-wrapper .box-wrapper .ReactTable .rt-tbody .rt-tr .rt-td .icons.check-icon {
                background-color: #8bd591; }
              .cash-flow-wrapper .box-wrapper .ReactTable .rt-tbody .rt-tr .rt-td .icons.close-icon {
                background-color: #ed5a5a; }
            .cash-flow-wrapper .box-wrapper .ReactTable .rt-tbody .rt-tr .rt-td:first-child {
              font-weight: 700 !important; }
          .cash-flow-wrapper .box-wrapper .ReactTable .rt-tbody .rt-tr.-odd {
            background-color: #f0f0f0; }
          .cash-flow-wrapper .box-wrapper .ReactTable .rt-tbody .rt-tr.-even {
            background-color: #fafafa; }
    .cash-flow-wrapper .box-wrapper .filter-box {
      text-align: center; }
      .cash-flow-wrapper .box-wrapper .filter-box select {
        width: 50%;
        border-radius: 10px;
        border: 1px solid #bcbcbc;
        margin-right: 10px;
        font-size: 1.6rem;
        line-height: 1.14;
        letter-spacing: normal;
        color: #1d1d1d;
        padding: 7px; }
      .cash-flow-wrapper .box-wrapper .filter-box button {
        font-size: 1.6rem;
        line-height: 1.19;
        letter-spacing: 0;
        color: #ffffff;
        border: none;
        border-radius: 25px;
        padding: 7px 15px 9px;
        background-color: #00cdbe;
        margin-left: 10px; }
  .cash-flow-wrapper .ratio {
    height: 37px;
    font-size: 3.8rem;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    text-align: left;
    color: #001061; }
  .cash-flow-wrapper .edit-ratio {
    cursor: pointer; }

.edit-ratio-form {
  text-align: center; }
  .edit-ratio-form .input-container {
    display: flex;
    justify-content: space-between;
    padding: 15px 0px;
    flex-direction: column; }
  .edit-ratio-form label {
    font-size: 1.6rem;
    padding-bottom: 6px;
    text-align: left; }
  .edit-ratio-form .input {
    padding: 10px;
    font-size: 1.6rem;
    border-radius: 10px;
    border: solid 1px #bcbcbc;
    background-color: #ffffff; }
  .edit-ratio-form .submit {
    width: 110px;
    height: 40px;
    border-radius: 25px;
    color: white;
    background-color: #29a9e0;
    border: none; }
  .edit-ratio-form .span {
    font-size: 1.6rem;
    text-align: center;
    margin: 10px;
    display: block; }
  .edit-ratio-form span {
    padding: 10px; }

.overview-applicant-modal .cross-icon {
  right: 20px !important;
  top: 25px !important; }

.overview-applicant-modal.edit-ratio-modal h3 {
  font-size: 2.2rem;
  padding-bottom: 10px; }

.credit-behaviour-wrapper {
  margin-top: 30px; }
  .credit-behaviour-wrapper h4 {
    font-size: 18px;
    line-height: 0.92;
    letter-spacing: normal;
    color: #00cdbe;
    margin-top: 0; }
  .credit-behaviour-wrapper .btns-box button {
    width: 100%;
    margin-bottom: 10px;
    line-height: 1.19;
    letter-spacing: 0;
    color: #ffffff;
    border: none;
    border-radius: 25px;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center; }
    .credit-behaviour-wrapper .btns-box button.add-btn {
      background-color: #00cdbe; }
    .credit-behaviour-wrapper .btns-box button.edit-btn {
      background-color: #29a9e0; }
    .credit-behaviour-wrapper .btns-box button.reset-btn {
      background-color: #ff0000; }
    .credit-behaviour-wrapper .btns-box button div {
      margin-right: 5px; }
  .credit-behaviour-wrapper .nav-tabs {
    margin: 30px 30px 0;
    border-color: #bcbcbc; }
    .credit-behaviour-wrapper .nav-tabs > li {
      margin: 0 4px -1px;
      background-color: #e5e5e5;
      border-radius: 5px 5px 0 0;
      position: relative; }
      .credit-behaviour-wrapper .nav-tabs > li a {
        color: #1d1d1d;
        border-radius: 5px 5px 0 0;
        font-size: 14px;
        line-height: 1.19;
        letter-spacing: 0;
        padding: 10px 20px; }
        .credit-behaviour-wrapper .nav-tabs > li a label {
          margin-bottom: 0; }
      .credit-behaviour-wrapper .nav-tabs > li.active::after {
        content: "";
        width: 75%;
        height: 2px;
        background-color: #00cdbe;
        position: absolute;
        bottom: 0;
        left: 13%; }
      .credit-behaviour-wrapper .nav-tabs > li.active a {
        color: #1d1d1d;
        margin-right: 0; }
  .credit-behaviour-wrapper .tab-content {
    border-right: 1px solid #bcbcbc;
    border-bottom: 1px solid #bcbcbc;
    border-left: 1px solid #bcbcbc;
    border-top: 2px solid #e5e5e5;
    border-radius: 25px;
    margin-top: -2px;
    margin-bottom: 15px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    padding: 20px; }
    .credit-behaviour-wrapper .tab-content .box-content {
      background-color: #fafafa;
      border-radius: 12px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
      .credit-behaviour-wrapper .tab-content .box-content .box-header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(0, 0, 0, 0.16); }
        .credit-behaviour-wrapper .tab-content .box-content .box-header .box-heading {
          margin-top: 0;
          line-height: 0.92;
          letter-spacing: normal;
          color: #00cdbe; }
        .credit-behaviour-wrapper .tab-content .box-content .box-header .edit-icon {
          color: #5f5f5f;
          border-bottom: 1px solid #5f5f5f;
          cursor: pointer;
          display: inline-block;
          margin-bottom: 3px; }
      .credit-behaviour-wrapper .tab-content .box-content .bank-details-list {
        list-style: none;
        margin: 10px 0; }
        .credit-behaviour-wrapper .tab-content .box-content .bank-details-list > li {
          display: flex;
          align-items: center;
          margin: 5px 0; }
          .credit-behaviour-wrapper .tab-content .box-content .bank-details-list > li label,
          .credit-behaviour-wrapper .tab-content .box-content .bank-details-list > li span {
            font-size: 14px;
            line-height: 1.19;
            letter-spacing: 0;
            color: #1d1d1d;
            margin: 0;
            flex: 1 1; }
          .credit-behaviour-wrapper .tab-content .box-content .bank-details-list > li svg {
            color: #b22929; }
          .credit-behaviour-wrapper .tab-content .box-content .bank-details-list > li .months-list {
            flex: 1 1;
            display: flex;
            list-style: none;
            width: 100%; }
            .credit-behaviour-wrapper .tab-content .box-content .bank-details-list > li .months-list li {
              font-size: 12px;
              line-height: 1.67;
              letter-spacing: normal;
              color: #ffffff;
              flex: 1 1;
              background-color: #8bd591;
              text-align: center;
              border-right: 0.5px solid #fff; }
  .credit-behaviour-wrapper .box-wrapper {
    border-radius: 15px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #fafafa;
    margin-bottom: 35px;
    padding: 25px 20px; }
    .credit-behaviour-wrapper .box-wrapper.secondary {
      padding: 0; }
      .credit-behaviour-wrapper .box-wrapper.secondary .box-header {
        background-color: #eeeeee;
        padding: 15px;
        border-radius: 15px 15px 0 0; }
        .credit-behaviour-wrapper .box-wrapper.secondary .box-header h4 {
          margin-bottom: 0; }
      .credit-behaviour-wrapper .box-wrapper.secondary .box-content ul {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 0; }
        .credit-behaviour-wrapper .box-wrapper.secondary .box-content ul li {
          padding: 15px;
          border-right: 1px solid #eeeeee;
          flex: 1 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between; }
          .credit-behaviour-wrapper .box-wrapper.secondary .box-content ul li label {
            font-size: 14px;
            line-height: 1.33;
            letter-spacing: 0;
            color: #1d1d1d;
            margin-bottom: 10px; }
          .credit-behaviour-wrapper .box-wrapper.secondary .box-content ul li span {
            font-size: 14px;
            font-stretch: normal;
            line-height: 1.21;
            letter-spacing: normal;
            color: #1d1d1d;
            display: flex;
            align-items: center; }
            .credit-behaviour-wrapper .box-wrapper.secondary .box-content ul li span .icon {
              margin-top: -3px; }
          .credit-behaviour-wrapper .box-wrapper.secondary .box-content ul li:last-child {
            border-right: none; }
    .credit-behaviour-wrapper .box-wrapper .box-header {
      border-bottom: 1px solid #e0e0e0;
      padding-bottom: 1rem; }
    .credit-behaviour-wrapper .box-wrapper .chart-details {
      margin-top: 20px; }
      .credit-behaviour-wrapper .box-wrapper .chart-details span {
        line-height: 1.19;
        letter-spacing: normal;
        color: #1d1d1d; }
    .credit-behaviour-wrapper .box-wrapper .chart-box {
      margin-top: 25px; }
    .credit-behaviour-wrapper .box-wrapper .box-footer {
      margin: 20px -20px -25px -20px;
      background-color: #001061;
      border-radius: 0 0 15px 15px; }
      .credit-behaviour-wrapper .box-wrapper .box-footer ul {
        display: flex;
        margin-bottom: 0; }
        .credit-behaviour-wrapper .box-wrapper .box-footer ul li {
          flex: 1 1;
          padding: 10px 15px;
          border-right: 1px solid rgba(255, 255, 255, 0.5);
          display: flex;
          flex-direction: column;
          justify-content: space-between; }
          .credit-behaviour-wrapper .box-wrapper .box-footer ul li label {
            font-size: 14px;
            line-height: 1.33;
            letter-spacing: 0;
            color: #ffffff;
            margin-bottom: 10px; }
          .credit-behaviour-wrapper .box-wrapper .box-footer ul li span {
            font-size: 24px;
            font-stretch: normal;
            line-height: 1.21;
            letter-spacing: normal;
            color: #ffffff;
            display: flex;
            align-items: center; }
            .credit-behaviour-wrapper .box-wrapper .box-footer ul li span .icon {
              margin-top: -3px; }
        .credit-behaviour-wrapper .box-wrapper .box-footer ul:last-child {
          border-top: 0.5px solid rgba(255, 255, 255, 0.5);
          border-radius: 0 0 15px 15px; }
          .credit-behaviour-wrapper .box-wrapper .box-footer ul:last-child li:first-child {
            border-bottom-left-radius: 15px; }
          .credit-behaviour-wrapper .box-wrapper .box-footer ul:last-child li:last-child {
            border-bottom-right-radius: 15px; }
    .credit-behaviour-wrapper .box-wrapper .select-box {
      margin-top: 15px;
      line-height: 1.19;
      letter-spacing: 0;
      color: #1d1d1d;
      border: 1px solid #e0e0e0;
      padding: 3px; }
  .credit-behaviour-wrapper .ReactTable {
    margin: 20px auto; }
    .credit-behaviour-wrapper .ReactTable .rt-thead .rt-th {
      position: relative;
      background-color: #001061;
      font-size: 12px;
      line-height: 1.25;
      letter-spacing: 0;
      color: #ffffff;
      padding: 12px 0;
      border-right-color: #fff;
      white-space: unset; }
      .credit-behaviour-wrapper .ReactTable .rt-thead .rt-th.-sort-asc::after {
        content: "\f0d7";
        position: absolute;
        right: 9px;
        top: 10px; }
      .credit-behaviour-wrapper .ReactTable .rt-thead .rt-th.-sort-desc::after {
        content: "\f0d8";
        position: absolute;
        right: 9px;
        top: 10px; }
    .credit-behaviour-wrapper .ReactTable .rt-tbody .rt-tr {
      font-size: 13px;
      line-height: 1.25;
      letter-spacing: 0;
      text-align: center;
      color: #1d1d1d;
      padding: 7px 0; }
      .credit-behaviour-wrapper .ReactTable .rt-tbody .rt-tr .rt-td {
        border-right-color: transparent; }
        .credit-behaviour-wrapper .ReactTable .rt-tbody .rt-tr .rt-td .icons {
          color: #fff;
          border-radius: 25px;
          width: 23px;
          height: 24px;
          display: inline-block;
          text-align: center;
          padding-top: 2px; }
          .credit-behaviour-wrapper .ReactTable .rt-tbody .rt-tr .rt-td .icons.check-icon {
            background-color: #78e35f; }
          .credit-behaviour-wrapper .ReactTable .rt-tbody .rt-tr .rt-td .icons.close-icon {
            background-color: #ff0000; }
      .credit-behaviour-wrapper .ReactTable .rt-tbody .rt-tr.-odd {
        background-color: #f0f0f0; }
      .credit-behaviour-wrapper .ReactTable .rt-tbody .rt-tr.-even {
        background-color: #fafafa; }
  .credit-behaviour-wrapper .applicants-table .rt-thead .rt-th {
    font-size: 12px;
    word-wrap: break-word; }
    .credit-behaviour-wrapper .applicants-table .rt-thead .rt-th.-sort-asc::after, .credit-behaviour-wrapper .applicants-table .rt-thead .rt-th.-sort-desc::after {
      right: 5px;
      top: 20px; }
  .credit-behaviour-wrapper .applicants-table .rt-tbody .rt-tr .rt-td {
    white-space: unset; }
  .credit-behaviour-wrapper .filters-box .icon-download {
    float: right; }
    .credit-behaviour-wrapper .filters-box .icon-download svg {
      color: #2d9086 !important; }
  .credit-behaviour-wrapper .filters-box label {
    font-size: 14px;
    line-height: 1.19;
    letter-spacing: 0;
    color: #1d1d1d; }
  .credit-behaviour-wrapper .filters-box ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .credit-behaviour-wrapper .filters-box ul li {
      margin-right: 10px; }
    .credit-behaviour-wrapper .filters-box ul label {
      font-size: 12px; }
  .credit-behaviour-wrapper .filters-box button {
    font-size: 14px;
    line-height: 1.19;
    letter-spacing: 0;
    color: #00cdbe;
    background-color: transparent;
    border-radius: 15px;
    border: 1px solid #00cdbe;
    padding: 3px 5px; }
  .credit-behaviour-wrapper .deliquency-trend {
    list-style: none;
    display: flex;
    align-items: center;
    margin-bottom: 0; }
    .credit-behaviour-wrapper .deliquency-trend li {
      flex: 1 1;
      padding: 9px 0; }
  .credit-behaviour-wrapper .text-input-field {
    resize: none;
    max-height: 100px;
    width: 90%;
    overflow: hidden;
    border-radius: 5px;
    padding: 5px; }
  .credit-behaviour-wrapper .boldText {
    font-weight: 600 !important;
    color: #1d1d1d;
    font-size: 14px;
    display: inline-block;
    margin-right: 1em; }
  .credit-behaviour-wrapper .heading {
    font-size: 12px;
    border-bottom: 1px solid #e0e0e0;
    color: #ffffff; }
  .credit-behaviour-wrapper .subheading {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    padding: 2px 4px;
    margin-left: 1.5rem;
    margin-right: 1.5rem; }
    .credit-behaviour-wrapper .subheading .liveLoans {
      color: #00cdbe;
      font-size: 12px;
      font-weight: 800 !important; }
    .credit-behaviour-wrapper .subheading .subheading {
      font-size: 12px;
      display: flex;
      justify-content: space-between;
      padding: 2px 4px; }
      .credit-behaviour-wrapper .subheading .subheading .liveLoans {
        color: #00cdbe;
        font-size: 12px;
        font-weight: 800 !important; }
      .credit-behaviour-wrapper .subheading .subheading span {
        min-width: 45%; }
        .credit-behaviour-wrapper .subheading .subheading span:first-child {
          border-right: 1px solid #fff; }

.flag-wrapper.content-container {
  margin: 50px auto 20px;
  width: 85%; }

.flag-wrapper .section-box {
  margin-bottom: 35px; }
  .flag-wrapper .section-box .section-header {
    text-align: center; }
    .flag-wrapper .section-box .section-header h4 {
      background: linear-gradient(to right, #00cdbe, #001061);
      width: 350px;
      margin-bottom: 0;
      font-size: 18px;
      line-height: 1.21;
      letter-spacing: normal;
      color: #ffffff;
      padding: 5px 0 3px;
      border-radius: 25px;
      cursor: pointer;
      display: inline-block;
      text-align: center; }
      .flag-wrapper .section-box .section-header h4 .icon {
        display: inline-block; }
  .flag-wrapper .section-box .react-slidedown {
    overflow: hidden;
    background-color: #f5f5f5;
    border-radius: 15px;
    padding: 40px 20px 0;
    margin-top: -15px; }
  .flag-wrapper .section-box.open .section-header .icon {
    transform-origin: center center;
    -webkit-animation-name: iconDown;
    animation-name: iconDown;
    -webkit-animation-duration: .35s;
            animation-duration: .35s;
    transform: rotate(0deg); }
  .flag-wrapper .section-box.closed .section-header .icon {
    transform-origin: center center;
    -webkit-animation-name: iconUp;
    animation-name: iconUp;
    -webkit-animation-duration: .35s;
            animation-duration: .35s;
    transform: rotate(180deg); }

.flag-wrapper h4 {
  font-size: 18px;
  line-height: 0.92;
  letter-spacing: normal;
  color: #00cdbe;
  margin-top: 0; }

.flag-wrapper .nav-tabs {
  margin: 30px 30px 0;
  border-color: #bcbcbc; }
  .flag-wrapper .nav-tabs > li {
    margin: 0 4px -1px;
    background-color: #e5e5e5;
    border-radius: 5px 5px 0 0;
    position: relative; }
    .flag-wrapper .nav-tabs > li a {
      color: #1d1d1d;
      border-radius: 5px 5px 0 0;
      font-size: 14px;
      line-height: 1.19;
      letter-spacing: 0;
      padding: 10px 20px; }
      .flag-wrapper .nav-tabs > li a label {
        margin-bottom: 0; }
    .flag-wrapper .nav-tabs > li.active::after {
      content: '';
      width: 75%;
      height: 2px;
      background-color: #00cdbe;
      position: absolute;
      bottom: 0;
      left: 13%; }
    .flag-wrapper .nav-tabs > li.active a {
      color: #1d1d1d;
      margin-right: 0; }

.flag-wrapper .tab-content {
  border-right: 1px solid #bcbcbc;
  border-bottom: 1px solid #bcbcbc;
  border-left: 1px solid #bcbcbc;
  border-top: 2px solid #e5e5e5;
  border-radius: 25px;
  margin-top: -2px;
  margin-bottom: 15px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  padding: 20px; }
  .flag-wrapper .tab-content .box-content {
    background-color: #fafafa;
    border-radius: 12px;
    padding: 15px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
    .flag-wrapper .tab-content .box-content .box-header {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.16); }
      .flag-wrapper .tab-content .box-content .box-header .box-heading {
        margin-top: 0;
        line-height: 0.92;
        letter-spacing: normal;
        color: #00cdbe; }
      .flag-wrapper .tab-content .box-content .box-header .edit-icon {
        color: #5f5f5f;
        border-bottom: 1px solid #5f5f5f;
        cursor: pointer;
        display: inline-block;
        margin-bottom: 3px; }
    .flag-wrapper .tab-content .box-content .bank-details-list {
      list-style: none;
      margin: 10px 0; }
      .flag-wrapper .tab-content .box-content .bank-details-list > li {
        display: flex;
        align-items: center;
        margin: 5px 0; }
        .flag-wrapper .tab-content .box-content .bank-details-list > li label,
        .flag-wrapper .tab-content .box-content .bank-details-list > li span {
          font-size: 14px;
          line-height: 1.19;
          letter-spacing: 0;
          color: #1d1d1d;
          margin: 0;
          flex: 1 1; }
        .flag-wrapper .tab-content .box-content .bank-details-list > li svg {
          color: #b22929; }
        .flag-wrapper .tab-content .box-content .bank-details-list > li .months-list {
          flex: 1 1;
          display: flex;
          list-style: none;
          width: 100%; }
          .flag-wrapper .tab-content .box-content .bank-details-list > li .months-list li {
            font-size: 12px;
            line-height: 1.67;
            letter-spacing: normal;
            color: #ffffff;
            flex: 1 1;
            background-color: #8bd591;
            text-align: center;
            border-right: 0.5px solid #fff; }

.flag-wrapper .box-wrapper {
  border-radius: 15px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fafafa;
  margin-bottom: 20px;
  padding: 25px 20px; }
  .flag-wrapper .box-wrapper.secondary {
    padding: 0; }
    .flag-wrapper .box-wrapper.secondary .box-header {
      background-color: #eeeeee;
      padding: 15px;
      border-radius: 15px 15px 0 0; }
      .flag-wrapper .box-wrapper.secondary .box-header h4 {
        margin-bottom: 0;
        font-size: 14px;
        line-height: 1.19;
        letter-spacing: 0;
        color: #1d1d1d; }
    .flag-wrapper .box-wrapper.secondary .box-content ul {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 0; }
      .flag-wrapper .box-wrapper.secondary .box-content ul li {
        padding: 15px;
        border-right: 1px solid #eeeeee;
        flex: 1 1; }
        .flag-wrapper .box-wrapper.secondary .box-content ul li .boldText {
          font-weight: 600 !important;
          color: #1d1d1d;
          font-size: 14px;
          display: inline-block;
          margin-right: 1em; }
        .flag-wrapper .box-wrapper.secondary .box-content ul li label {
          font-size: 14px;
          line-height: 1.33;
          letter-spacing: 0;
          color: #1d1d1d;
          margin-bottom: 10px; }
        .flag-wrapper .box-wrapper.secondary .box-content ul li span {
          font-size: 14px;
          font-stretch: normal;
          line-height: 1.21;
          letter-spacing: normal;
          color: #1d1d1d;
          display: flex;
          align-items: center; }
          .flag-wrapper .box-wrapper.secondary .box-content ul li span .icon {
            margin-top: -3px; }
        .flag-wrapper .box-wrapper.secondary .box-content ul li:last-child {
          border-right: none; }
  .flag-wrapper .box-wrapper .secondary {
    margin-top: 10px; }
  .flag-wrapper .box-wrapper .box-header {
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .flag-wrapper .box-wrapper .box-content {
    margin: 15px 0 0; }
    .flag-wrapper .box-wrapper .box-content .content-row {
      margin-bottom: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .flag-wrapper .box-wrapper .box-content .content-row label,
      .flag-wrapper .box-wrapper .box-content .content-row span {
        font-size: 14px;
        line-height: 1.19;
        letter-spacing: 0;
        color: #1d1d1d; }
      .flag-wrapper .box-wrapper .box-content .content-row label {
        margin-bottom: 0; }

.flag-wrapper .flag-box {
  white-space: nowrap; }
  .flag-wrapper .flag-box .greenLabel {
    color: #19ce35 !important; }
  .flag-wrapper .flag-box .redLabel {
    color: #ed5a5a !important; }
  .flag-wrapper .flag-box label {
    font-size: 14px;
    line-height: 0.9;
    letter-spacing: 0;
    margin-right: 5px;
    margin-bottom: 0; }

.flag-wrapper .cc-score-box {
  margin-top: 20px;
  margin-bottom: 5px; }
  .flag-wrapper .cc-score-box label {
    font-size: 14px;
    line-height: 1.33;
    letter-spacing: normal;
    color: #1d1d1d; }
  .flag-wrapper .cc-score-box span {
    font-size: 26px;
    line-height: 1.21;
    letter-spacing: normal;
    color: #ed5a5a;
    word-wrap: break-word;
    max-width: 20px; }
  .flag-wrapper .cc-score-box ul {
    list-style: none;
    display: flex;
    margin: 30px 0 0; }
    .flag-wrapper .cc-score-box ul li {
      flex: 1 1;
      border: 0.5px solid #ddd;
      padding: 2px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 14px;
      line-height: 1.14;
      letter-spacing: 0; }
      .flag-wrapper .cc-score-box ul li:first-child {
        flex: 2.5 1;
        color: #1d1d1d; }
      .flag-wrapper .cc-score-box ul li:nth-child(n+2) {
        background-color: #000;
        color: #fff; }
      .flag-wrapper .cc-score-box ul li span {
        position: absolute;
        top: -30px;
        font-size: 14px;
        line-height: 1.14;
        letter-spacing: 0;
        color: #ed5a5a;
        white-space: nowrap; }
        .flag-wrapper .cc-score-box ul li span div {
          display: block !important;
          margin-top: -3px; }
      .flag-wrapper .cc-score-box ul li:nth-child(2), .flag-wrapper .cc-score-box ul li:nth-child(3) {
        background-color: #e8505b; }
      .flag-wrapper .cc-score-box ul li:nth-child(4) {
        background-color: #ed625a; }
      .flag-wrapper .cc-score-box ul li:nth-child(5) {
        background-color: #ed745a; }
      .flag-wrapper .cc-score-box ul li:nth-child(6) {
        background-color: #ed7c5a; }
      .flag-wrapper .cc-score-box ul li:nth-child(7) {
        background-color: #ed845a; }
      .flag-wrapper .cc-score-box ul li:nth-child(8) {
        background-color: #ed8c5a; }
      .flag-wrapper .cc-score-box ul li:nth-child(9) {
        background-color: #ed945a; }
      .flag-wrapper .cc-score-box ul li:nth-child(10) {
        background-color: #ed9c5a; }
      .flag-wrapper .cc-score-box ul li:nth-child(11) {
        background-color: #edac5a; }
      .flag-wrapper .cc-score-box ul li:nth-child(12) {
        background-color: #edbc5a; }
      .flag-wrapper .cc-score-box ul li:nth-child(13) {
        background-color: #edc45a; }
      .flag-wrapper .cc-score-box ul li:nth-child(14) {
        background-color: #edd45a; }
      .flag-wrapper .cc-score-box ul li:nth-child(15) {
        background-color: #c9de4d; }
      .flag-wrapper .cc-score-box ul li:nth-child(16) {
        background-color: #9bdd42; }
      .flag-wrapper .cc-score-box ul li:nth-child(17) {
        background-color: #7ace3d; }
      .flag-wrapper .cc-score-box ul li:nth-child(18) {
        background-color: #5dc145; }
      .flag-wrapper .cc-score-box ul li:nth-child(19) {
        background-color: #54b23e; }
      .flag-wrapper .cc-score-box ul li:nth-child(20) {
        background-color: #48a537; }
      .flag-wrapper .cc-score-box ul li:nth-child(21) {
        background-color: #3f9625; }
      .flag-wrapper .cc-score-box ul li:nth-child(22) {
        background-color: #267e2d; }
      .flag-wrapper .cc-score-box ul li:nth-child(23) {
        background-color: #0f6226; }

.flag-wrapper .timeline-box {
  position: relative;
  height: 40px;
  margin: 60px auto;
  width: 85%; }
  .flag-wrapper .timeline-box::before {
    content: '';
    position: absolute;
    height: 1px;
    border-top: 1px solid #bcbcbc;
    width: 100%;
    top: 50%; }
  .flag-wrapper .timeline-box .timelines {
    position: absolute;
    text-align: center; }
    .flag-wrapper .timeline-box .timelines span:first-of-type {
      padding: 10px 15px;
      border-radius: 50%;
      background-color: 00cdbe;
      background-color: #00cdbe;
      display: inline-block;
      position: relative;
      color: white; }
    .flag-wrapper .timeline-box .timelines span:last-of-type {
      display: block;
      font-size: 14px;
      line-height: 1.19;
      letter-spacing: 0;
      color: #1d1d1d;
      margin-top: 10px; }
    .flag-wrapper .timeline-box .timelines label {
      display: block;
      font-size: 14px;
      line-height: 1.19;
      letter-spacing: 0;
      color: #1d1d1d;
      margin-top: 5px; }
  .flag-wrapper .timeline-box .timeline-1 {
    left: -35px; }
  .flag-wrapper .timeline-box .timeline-2 {
    left: 60%; }
  .flag-wrapper .timeline-box .timeline-3 {
    right: -35px; }

.flag-wrapper .vertical-divider-left {
  position: relative; }
  .flag-wrapper .vertical-divider-left::before {
    position: absolute;
    left: 0;
    content: '';
    height: 100%;
    width: 1px;
    border: 1px solid #e0e0e0; }

.flag-wrapper .business-profile-flag-box .box-content .content-row label,
.flag-wrapper .business-profile-flag-box .box-content .content-row span {
  flex: 1 1; }

.flag-wrapper .business-risk-flag-box .box-content {
  padding: 15px; }

.flag-wrapper .credit-behaviour-flag-box .box-content {
  margin: 25px 0; }
  .flag-wrapper .credit-behaviour-flag-box .box-content .content-row {
    margin-bottom: 10px; }
    .flag-wrapper .credit-behaviour-flag-box .box-content .content-row label {
      flex: 1.5 1; }
    .flag-wrapper .credit-behaviour-flag-box .box-content .content-row span {
      flex: .5 1;
      text-align: right; }

.flag-wrapper .credit-behaviour-flag-box .top5-list-box {
  margin: 15px 10px; }
  .flag-wrapper .credit-behaviour-flag-box .top5-list-box ul li {
    font-size: 12px;
    color: #1d1d1d; }

.flag-wrapper .credit-capacity-flag-box .box-content {
  margin: 50px 0; }
  .flag-wrapper .credit-capacity-flag-box .box-content .content-row {
    margin-bottom: 10px;
    font-weight: 500 !important;
    font-size: 14px; }
    .flag-wrapper .credit-capacity-flag-box .box-content .content-row label {
      flex: 1.5 1; }
    .flag-wrapper .credit-capacity-flag-box .box-content .content-row span {
      flex: .5 1;
      text-align: right;
      color: #ed5a5a !important; }

.flag-wrapper .credit-capacity-flag-box .vertical-divider-left::before {
  top: 10%;
  height: 80%; }

.flag-wrapper .dpd-box > div {
  margin: 20px 0; }
  .flag-wrapper .dpd-box > div:last-child {
    margin-bottom: 0; }

.flag-wrapper .dpd-box label {
  font-size: 14px;
  line-height: 1.33;
  color: #1d1d1d; }

.flag-wrapper .dpd-box span {
  font-size: 26px;
  line-height: 1.21;
  letter-spacing: normal;
  color: #19ce35;
  display: block; }

.flag-wrapper .dpd-box .dpd-list {
  list-style: none;
  display: flex;
  background: linear-gradient(to right, #f7f2f2, #f53c3c);
  padding: 3px 0;
  border-radius: 7px;
  margin: 12px 0; }
  .flag-wrapper .dpd-box .dpd-list li {
    flex: 1 1;
    text-align: center;
    color: #fff;
    font-size: 18px;
    position: relative; }
    .flag-wrapper .dpd-box .dpd-list li:first-child::before {
      position: absolute;
      content: "DPD 0";
      left: -10px;
      bottom: -25px;
      font-size: 14px;
      line-height: 1.33;
      letter-spacing: 0;
      color: #1d1d1d; }
    .flag-wrapper .dpd-box .dpd-list li:first-child::after {
      position: absolute;
      content: "DPD 30";
      right: -10px;
      bottom: -25px;
      font-size: 14px;
      line-height: 1.33;
      letter-spacing: 0;
      color: #1d1d1d; }
    .flag-wrapper .dpd-box .dpd-list li:nth-child(2)::after {
      position: absolute;
      content: "DPD 60";
      right: -10px;
      bottom: -25px;
      font-size: 14px;
      line-height: 1.33;
      letter-spacing: 0;
      color: #1d1d1d; }
    .flag-wrapper .dpd-box .dpd-list li:last-child::after {
      position: absolute;
      content: "DPD 90";
      right: -10px;
      bottom: -25px;
      font-size: 14px;
      line-height: 1.33;
      letter-spacing: 0;
      color: #1d1d1d; }

.flag-wrapper .dpd-box .content-row {
  margin-bottom: 10px; }

.flag-wrapper .bubble-chart-box {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  margin: 40px 0; }
  .flag-wrapper .bubble-chart-box .list-item {
    text-align: center; }
    .flag-wrapper .bubble-chart-box .list-item span,
    .flag-wrapper .bubble-chart-box .list-item label {
      display: block;
      font-size: 14px;
      line-height: 1.19;
      letter-spacing: 0;
      color: #1d1d1d;
      text-align: center; }
    .flag-wrapper .bubble-chart-box .list-item label {
      margin-bottom: 0;
      margin-top: 5px; }
    .flag-wrapper .bubble-chart-box .list-item .bubble {
      color: #fafafa;
      display: inline-block;
      padding: 15px 0;
      border-radius: 50%; }
    .flag-wrapper .bubble-chart-box .list-item .bubble-transparent {
      font-size: 26px;
      line-height: 0.66;
      letter-spacing: -0.1px;
      color: #ed5a5a;
      margin-bottom: 5px; }

.credit-capacity-wrapper {
  margin-top: 30px; }
  .credit-capacity-wrapper .credit-capacity-modal {
    width: 85%;
    border-radius: 30px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    padding: 25px;
    position: relative; }
    .credit-capacity-wrapper .credit-capacity-modal .select-box {
      border-radius: 10px;
      padding: 5px; }
    .credit-capacity-wrapper .credit-capacity-modal .box-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #bcbcbc;
      padding: 0 10px 5px; }
      .credit-capacity-wrapper .credit-capacity-modal .box-header h3 {
        font-size: 24px;
        line-height: 1;
        letter-spacing: -0.2px;
        color: #001061;
        margin: 0; }
      .credit-capacity-wrapper .credit-capacity-modal .box-header .cross-icon {
        color: #bcbcbc;
        cursor: pointer; }
    .credit-capacity-wrapper .credit-capacity-modal table {
      width: 100%;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      background-color: #fafafa;
      margin: 25px 0;
      overflow-x: scroll;
      overflow-y: hidden; }
      .credit-capacity-wrapper .credit-capacity-modal table::-webkit-scrollbar {
        height: 3px; }
      .credit-capacity-wrapper .credit-capacity-modal table::-webkit-scrollbar-button {
        display: none; }
      .credit-capacity-wrapper .credit-capacity-modal table::-webkit-scrollbar-track {
        background: transparent; }
      .credit-capacity-wrapper .credit-capacity-modal table::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #001061; }
      .credit-capacity-wrapper .credit-capacity-modal table thead tr {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #001061; }
        .credit-capacity-wrapper .credit-capacity-modal table thead tr th {
          flex: 1 1;
          font-size: 14px;
          line-height: 1.25;
          letter-spacing: 0;
          color: #ffffff;
          text-align: center;
          padding: 10px 6px;
          border-right: 1px solid #fff;
          height: 55px;
          display: flex;
          align-items: center;
          justify-content: center; }
          .credit-capacity-wrapper .credit-capacity-modal table thead tr th:last-child {
            border-right: none; }
      .credit-capacity-wrapper .credit-capacity-modal table tbody tr {
        display: flex;
        justify-content: space-between;
        align-items: center; }
        .credit-capacity-wrapper .credit-capacity-modal table tbody tr td {
          flex: 1 1;
          font-size: 14px;
          line-height: 0.92;
          letter-spacing: normal;
          color: #1d1d1d;
          padding: 15px 5px;
          position: relative; }
          .credit-capacity-wrapper .credit-capacity-modal table tbody tr td .select-box {
            display: inline-block;
            border: 1px solid #bcbcbc;
            border-radius: 7px;
            width: 100%;
            padding: 9px;
            font-size: 14px;
            line-height: 1.14;
            letter-spacing: normal; }
          .credit-capacity-wrapper .credit-capacity-modal table tbody tr td .input-field,
          .credit-capacity-wrapper .credit-capacity-modal table tbody tr td .date-field {
            padding: 12px; }
          .credit-capacity-wrapper .credit-capacity-modal table tbody tr td label {
            top: 15px;
            width: 80%; }
    .credit-capacity-wrapper .credit-capacity-modal .btns-box {
      text-align: center;
      margin-top: 15px;
      margin-bottom: 5px; }
      .credit-capacity-wrapper .credit-capacity-modal .btns-box button {
        background-color: #29a9e0;
        padding: 10px;
        border: none;
        border-radius: 25px;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.19;
        letter-spacing: 0;
        color: #ffffff;
        width: 100px; }
  .credit-capacity-wrapper .content-wrapper h4 {
    font-size: 18px;
    line-height: 0.92;
    letter-spacing: normal;
    color: #00cdbe;
    margin-top: 0;
    font-weight: 400 !important;
    padding-bottom: 4px; }
  .credit-capacity-wrapper .content-wrapper button {
    border: none;
    border-radius: 15px;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.19;
    letter-spacing: 0;
    color: #ffffff; }
  .credit-capacity-wrapper .content-wrapper table {
    width: 100%;
    border-radius: 15px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #fafafa;
    margin: 10px 0 35px;
    overflow: hidden; }
    .credit-capacity-wrapper .content-wrapper table thead tr {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .credit-capacity-wrapper .content-wrapper table thead tr th {
        flex: 1 1;
        font-size: 14px;
        line-height: 0.92;
        letter-spacing: normal;
        color: #001061;
        padding: 12px 15px;
        border-right: 1px solid #fff;
        font-weight: normal; }
        .credit-capacity-wrapper .content-wrapper table thead tr th:nth-child(2), .credit-capacity-wrapper .content-wrapper table thead tr th:nth-child(3) {
          flex: 1.25 1; }
        .credit-capacity-wrapper .content-wrapper table thead tr th:first-child {
          border-top-left-radius: 15px; }
        .credit-capacity-wrapper .content-wrapper table thead tr th:last-child {
          border-top-right-radius: 15px;
          border: none;
          flex: 0.9 1; }
    .credit-capacity-wrapper .content-wrapper table tbody tr {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .credit-capacity-wrapper .content-wrapper table tbody tr td {
        flex: 1 1;
        font-size: 24px;
        line-height: 0.92;
        letter-spacing: normal;
        color: #001061;
        padding: 12px 20px; }
        .credit-capacity-wrapper .content-wrapper table tbody tr td:nth-child(2), .credit-capacity-wrapper .content-wrapper table tbody tr td:nth-child(3) {
          flex: 1.25 1; }
        .credit-capacity-wrapper .content-wrapper table tbody tr td:last-child {
          flex: 0.9 1; }
        .credit-capacity-wrapper .content-wrapper table tbody tr td input {
          padding: 7px 12px; }
  .credit-capacity-wrapper .content-wrapper .btns-box {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 5px; }
    .credit-capacity-wrapper .content-wrapper .btns-box button {
      background-color: #29a9e0;
      padding: 7px 12px; }
  .credit-capacity-wrapper .content-wrapper .nav-tabs {
    margin: 30px 30px 0;
    border-color: #bcbcbc; }
    .credit-capacity-wrapper .content-wrapper .nav-tabs > li {
      margin: 0 4px -1px;
      background-color: #e5e5e5;
      border-radius: 5px 5px 0 0;
      position: relative; }
      .credit-capacity-wrapper .content-wrapper .nav-tabs > li a {
        color: #1d1d1d;
        border-radius: 5px 5px 0 0;
        font-size: 14px;
        line-height: 1.19;
        letter-spacing: 0;
        padding: 10px 20px; }
        .credit-capacity-wrapper .content-wrapper .nav-tabs > li a label {
          margin-bottom: 0; }
      .credit-capacity-wrapper .content-wrapper .nav-tabs > li.active::after {
        content: "";
        width: 75%;
        height: 2px;
        background-color: #00cdbe;
        position: absolute;
        bottom: 0;
        left: 13%; }
      .credit-capacity-wrapper .content-wrapper .nav-tabs > li.active a {
        color: #1d1d1d;
        margin-right: 0; }
  .credit-capacity-wrapper .content-wrapper .tab-content {
    border-right: 1px solid #bcbcbc;
    border-bottom: 1px solid #bcbcbc;
    border-left: 1px solid #bcbcbc;
    border-top: 2px solid #e5e5e5;
    border-radius: 25px;
    margin-top: -2px;
    margin-bottom: 15px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    padding: 20px; }
    .credit-capacity-wrapper .content-wrapper .tab-content .box-content {
      background-color: #fafafa;
      border-radius: 12px;
      padding: 15px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
      .credit-capacity-wrapper .content-wrapper .tab-content .box-content .box-header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(0, 0, 0, 0.16); }
        .credit-capacity-wrapper .content-wrapper .tab-content .box-content .box-header .box-heading {
          margin-top: 0;
          line-height: 0.92;
          letter-spacing: normal;
          color: #00cdbe; }
        .credit-capacity-wrapper .content-wrapper .tab-content .box-content .box-header .edit-icon {
          color: #5f5f5f;
          border-bottom: 1px solid #5f5f5f;
          cursor: pointer;
          display: inline-block;
          margin-bottom: 3px; }
      .credit-capacity-wrapper .content-wrapper .tab-content .box-content .bank-details-list {
        list-style: none;
        margin: 10px 0; }
        .credit-capacity-wrapper .content-wrapper .tab-content .box-content .bank-details-list > li {
          display: flex;
          align-items: center;
          margin: 5px 0; }
          .credit-capacity-wrapper .content-wrapper .tab-content .box-content .bank-details-list > li label,
          .credit-capacity-wrapper .content-wrapper .tab-content .box-content .bank-details-list > li span {
            font-size: 14px;
            line-height: 1.19;
            letter-spacing: 0;
            color: #1d1d1d;
            margin: 0;
            flex: 1 1; }
          .credit-capacity-wrapper .content-wrapper .tab-content .box-content .bank-details-list > li svg {
            color: #b22929; }
          .credit-capacity-wrapper .content-wrapper .tab-content .box-content .bank-details-list > li .months-list {
            flex: 1 1;
            display: flex;
            list-style: none;
            width: 100%; }
            .credit-capacity-wrapper .content-wrapper .tab-content .box-content .bank-details-list > li .months-list li {
              font-size: 12px;
              line-height: 1.67;
              letter-spacing: normal;
              color: #ffffff;
              flex: 1 1;
              background-color: #8bd591;
              text-align: center;
              border-right: 0.5px solid #fff; }
  .credit-capacity-wrapper .content-wrapper .box-wrapper {
    border-radius: 15px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #fafafa;
    margin-bottom: 35px;
    padding: 25px 20px; }
    .credit-capacity-wrapper .content-wrapper .box-wrapper .box-header {
      border-bottom: 1px solid #e0e0e0; }
      .credit-capacity-wrapper .content-wrapper .box-wrapper .box-header button {
        background-color: #00cdbe;
        padding: 5px 10px;
        margin-bottom: 10px; }
    .credit-capacity-wrapper .content-wrapper .box-wrapper .chart-box {
      margin-top: 25px; }
    .credit-capacity-wrapper .content-wrapper .box-wrapper .box-footer {
      margin: 20px -20px -25px -20px;
      background-color: #001061;
      border-radius: 0 0 15px 15px; }
      .credit-capacity-wrapper .content-wrapper .box-wrapper .box-footer ul {
        display: flex;
        margin-bottom: 0; }
        .credit-capacity-wrapper .content-wrapper .box-wrapper .box-footer ul li {
          flex: 1 1;
          padding: 10px 15px;
          border-right: 1px solid rgba(255, 255, 255, 0.5);
          display: flex;
          flex-direction: column;
          justify-content: space-between; }
          .credit-capacity-wrapper .content-wrapper .box-wrapper .box-footer ul li label {
            font-size: 14px;
            line-height: 1.33;
            letter-spacing: 0;
            color: #ffffff;
            margin-bottom: 10px; }
          .credit-capacity-wrapper .content-wrapper .box-wrapper .box-footer ul li span {
            font-size: 24px;
            font-stretch: normal;
            line-height: 1.21;
            letter-spacing: normal;
            color: #ffffff;
            display: flex;
            align-items: center; }
            .credit-capacity-wrapper .content-wrapper .box-wrapper .box-footer ul li span .icon {
              margin-top: -3px; }
        .credit-capacity-wrapper .content-wrapper .box-wrapper .box-footer ul:last-child {
          border-top: 0.5px solid rgba(255, 255, 255, 0.5);
          border-radius: 0 0 15px 15px; }
          .credit-capacity-wrapper .content-wrapper .box-wrapper .box-footer ul:last-child li:first-child {
            border-bottom-left-radius: 15px; }
          .credit-capacity-wrapper .content-wrapper .box-wrapper .box-footer ul:last-child li:last-child {
            border-bottom-right-radius: 15px; }
  .credit-capacity-wrapper .content-wrapper .ReactTable {
    margin: 20px auto 0; }
    .credit-capacity-wrapper .content-wrapper .ReactTable .rt-table {
      overflow-x: scroll; }
      .credit-capacity-wrapper .content-wrapper .ReactTable .rt-table::-webkit-scrollbar {
        height: 10px; }
      .credit-capacity-wrapper .content-wrapper .ReactTable .rt-table::-webkit-scrollbar-button {
        display: none; }
      .credit-capacity-wrapper .content-wrapper .ReactTable .rt-table::-webkit-scrollbar-track {
        background: transparent; }
      .credit-capacity-wrapper .content-wrapper .ReactTable .rt-table::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #00cdbe; }
    .credit-capacity-wrapper .content-wrapper .ReactTable .rt-thead {
      box-shadow: none !important; }
      .credit-capacity-wrapper .content-wrapper .ReactTable .rt-thead .rt-th {
        position: relative;
        background-color: #001061;
        font-size: 14px;
        line-height: 1.25;
        letter-spacing: 0;
        color: #ffffff;
        padding: 12px 0;
        border-right-color: #fff; }
        .credit-capacity-wrapper .content-wrapper .ReactTable .rt-thead .rt-th.-sort-asc::after {
          content: "\f0d7";
          position: absolute;
          right: 9px;
          top: 10px; }
        .credit-capacity-wrapper .content-wrapper .ReactTable .rt-thead .rt-th.-sort-desc::after {
          content: "\f0d8";
          position: absolute;
          right: 9px;
          top: 10px; }
    .credit-capacity-wrapper .content-wrapper .ReactTable .rt-tbody .rt-tr {
      font-size: 13px;
      line-height: 1.25;
      letter-spacing: 0;
      text-align: center;
      color: #1d1d1d;
      padding: 0; }
      .credit-capacity-wrapper .content-wrapper .ReactTable .rt-tbody .rt-tr .rt-td {
        border-right-color: transparent; }
        .credit-capacity-wrapper .content-wrapper .ReactTable .rt-tbody .rt-tr .rt-td .icons {
          color: #fff;
          border-radius: 25px;
          width: 23px;
          height: 24px;
          display: inline-block;
          text-align: center;
          padding-top: 2px; }
          .credit-capacity-wrapper .content-wrapper .ReactTable .rt-tbody .rt-tr .rt-td .icons.check-icon {
            background-color: #78e35f; }
          .credit-capacity-wrapper .content-wrapper .ReactTable .rt-tbody .rt-tr .rt-td .icons.close-icon {
            background-color: #ff0000; }
  .credit-capacity-wrapper .content-wrapper .obligations-table .rt-thead .rt-th {
    white-space: normal;
    font-size: 12px; }
    .credit-capacity-wrapper .content-wrapper .obligations-table .rt-thead .rt-th.-sort-asc::after, .credit-capacity-wrapper .content-wrapper .obligations-table .rt-thead .rt-th.-sort-desc::after {
      right: 5px;
      top: 20px; }
  .credit-capacity-wrapper .content-wrapper .obligations-table .rt-tbody .rt-tr .rt-td {
    white-space: normal; }
  .credit-capacity-wrapper .content-wrapper .obligations-table .editButton {
    color: #001061;
    border-bottom: 1px dotted black;
    margin-left: 10px; }
    .credit-capacity-wrapper .content-wrapper .obligations-table .editButton:hover {
      font-size: 120%;
      color: #00cdbe; }
  .credit-capacity-wrapper .content-wrapper .obligations-data {
    margin: 0; }
    .credit-capacity-wrapper .content-wrapper .obligations-data > div {
      background-color: #f0f0f0;
      padding-top: 5px;
      padding-bottom: 5px; }
      .credit-capacity-wrapper .content-wrapper .obligations-data > div label {
        font-size: 12px;
        color: #1d1d1d; }
      .credit-capacity-wrapper .content-wrapper .obligations-data > div span {
        width: 100px;
        display: inline-block;
        margin-left: 15px;
        text-align: left;
        font-size: 14px;
        color: #00cdbe; }
      .credit-capacity-wrapper .content-wrapper .obligations-data > div:last-child {
        background-color: #fafafa; }
  .credit-capacity-wrapper .content-wrapper .accounts-table {
    border: none; }
    .credit-capacity-wrapper .content-wrapper .accounts-table .rt-tr .rt-td {
      display: flex;
      align-items: center;
      justify-content: center; }
      .credit-capacity-wrapper .content-wrapper .accounts-table .rt-tr .rt-td:last-child {
        background-color: transparent !important; }
      .credit-capacity-wrapper .content-wrapper .accounts-table .rt-tr .rt-td button {
        background-color: #00cdbe;
        width: 65px;
        padding: 5px 0; }
  .credit-capacity-wrapper .content-wrapper .filters-box {
    margin-top: 20px; }
    .credit-capacity-wrapper .content-wrapper .filters-box .icon-download {
      float: right; }
      .credit-capacity-wrapper .content-wrapper .filters-box .icon-download svg {
        color: #2d9086 !important; }
    .credit-capacity-wrapper .content-wrapper .filters-box label {
      font-size: 14px;
      line-height: 1.19;
      letter-spacing: 0;
      color: #1d1d1d; }
    .credit-capacity-wrapper .content-wrapper .filters-box ul {
      list-style: none;
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .credit-capacity-wrapper .content-wrapper .filters-box button {
      font-size: 14px;
      line-height: 1.19;
      letter-spacing: 0;
      color: #00cdbe;
      background-color: transparent;
      border-radius: 15px;
      border: 1px solid #00cdbe;
      padding: 3px 5px; }
  .credit-capacity-wrapper .content-wrapper .emi-scenario-box ul {
    margin: 30px;
    list-style: none; }
    .credit-capacity-wrapper .content-wrapper .emi-scenario-box ul li {
      margin: 35px 0 15px;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .credit-capacity-wrapper .content-wrapper .emi-scenario-box ul li:first-child {
        margin-top: 15px; }
      .credit-capacity-wrapper .content-wrapper .emi-scenario-box ul li div.input-range {
        width: 40%; }
        .credit-capacity-wrapper .content-wrapper .emi-scenario-box ul li div.input-range .input-range__label.input-range__label--max,
        .credit-capacity-wrapper .content-wrapper .emi-scenario-box ul li div.input-range .input-range__label.input-range__label--min {
          display: none; }
      .credit-capacity-wrapper .content-wrapper .emi-scenario-box ul li label {
        flex: 0.75 1;
        text-align: right;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.14;
        letter-spacing: normal;
        color: #1d1d1d;
        margin-bottom: 0; }
  .credit-capacity-wrapper .content-wrapper .emi-scenario-box table thead tr th {
    font-size: 14px;
    padding: 12px 10px; }
  .credit-capacity-wrapper .content-wrapper .emi-scenario-box table tbody tr td {
    font-size: 18px;
    padding: 12px 10px; }
  .credit-capacity-wrapper .content-wrapper .emi-scenario-box .emiValueWrapper {
    font-size: 20px;
    margin: 30px; }
    .credit-capacity-wrapper .content-wrapper .emi-scenario-box .emiValueWrapper .emiValueBox {
      border: 1px solid #00cdbe;
      padding: 10px;
      border-radius: 5px; }
  .credit-capacity-wrapper .text-input-field {
    resize: none;
    max-height: 100px;
    width: 90%;
    overflow: hidden;
    border-radius: 5px;
    padding: 5px; }

.final-decision-wrapper {
  color: #1d1d1d; }
  .final-decision-wrapper.content-container {
    margin: 50px auto 20px;
    width: 85%; }
  .final-decision-wrapper .box-wrapper {
    border-radius: 15px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background: #ffffff;
    margin-bottom: 20px;
    padding: 25px 20px; }
    .final-decision-wrapper .box-wrapper .fprBox {
      margin-top: 23px; }
      .final-decision-wrapper .box-wrapper .fprBox .content {
        margin: 20px 0; }
        .final-decision-wrapper .box-wrapper .fprBox .content:first-child {
          margin-top: 0; }
        .final-decision-wrapper .box-wrapper .fprBox .content:last-child {
          margin-bottom: 0; }
        .final-decision-wrapper .box-wrapper .fprBox .content label,
        .final-decision-wrapper .box-wrapper .fprBox .content span {
          display: block; }
    .final-decision-wrapper .box-wrapper.secondary {
      padding: 0; }
      .final-decision-wrapper .box-wrapper.secondary .btns-box {
        background: #00cdbe;
        color: #ffffff; }
      .final-decision-wrapper .box-wrapper.secondary .table-box {
        overflow-x: scroll;
        margin-top: 20px; }
        .final-decision-wrapper .box-wrapper.secondary .table-box .btns-box {
          text-align: center;
          margin-top: 15px; }
          .final-decision-wrapper .box-wrapper.secondary .table-box .btns-box button {
            color: #ffffff;
            border: none;
            border-radius: 25px;
            padding: 10px;
            min-width: 100px;
            background: #00cdbe; }
        .final-decision-wrapper .box-wrapper.secondary .table-box .table {
          width: 100%; }
        .final-decision-wrapper .box-wrapper.secondary .table-box::-webkit-scrollbar {
          height: 8px; }
        .final-decision-wrapper .box-wrapper.secondary .table-box::-webkit-scrollbar-button {
          display: none; }
        .final-decision-wrapper .box-wrapper.secondary .table-box::-webkit-scrollbar-track {
          background: transparent; }
        .final-decision-wrapper .box-wrapper.secondary .table-box::-webkit-scrollbar-thumb {
          border-radius: 10px; }
      .final-decision-wrapper .box-wrapper.secondary .primary-table thead::-webkit-scrollbar {
        height: 300px; }
      .final-decision-wrapper .box-wrapper.secondary .primary-table thead .tr {
        background-color: #e5e5e5; }
        .final-decision-wrapper .box-wrapper.secondary .primary-table thead .tr .th {
          border: 1px solid #fff;
          text-align: center;
          padding: 10px; }
      .final-decision-wrapper .box-wrapper.secondary .primary-table .tbody .tr .td {
        text-align: center;
        padding: 10px;
        border-right: 2px solid #fff; }
        .final-decision-wrapper .box-wrapper.secondary .primary-table .tbody .tr .td:last-child {
          border-right: none; }
        .final-decision-wrapper .box-wrapper.secondary .primary-table .tbody .tr .td .residenceStatus {
          font-weight: 800 !important;
          color: #980e18; }
        .final-decision-wrapper .box-wrapper.secondary .primary-table .tbody .tr .td .btn {
          width: 150px;
          color: #ffffff;
          border: none;
          padding: 9px;
          background: #29a9e0;
          border-radius: 5px; }
        .final-decision-wrapper .box-wrapper.secondary .primary-table .tbody .tr .td .select-box {
          width: 100px; }
      .final-decision-wrapper .box-wrapper.secondary .primary-table .tbody tr:nth-child(odd) {
        background: #f0f0f0; }
      .final-decision-wrapper .box-wrapper.secondary .primary-table .tbody tr:nth-child(even) {
        background: #fafafa; }
      .final-decision-wrapper .box-wrapper.secondary .primary-table.bank-table tbody tr:last-child {
        border-top: 1px solid #bcbcbc; }
      .final-decision-wrapper .box-wrapper.secondary .box-header {
        padding: 20px;
        border-radius: 15px 15px 0 0;
        background: #eeeeee; }
        .final-decision-wrapper .box-wrapper.secondary .box-header ul {
          list-style: none;
          display: flex;
          justify-content: space-between;
          margin-bottom: 0;
          width: 100%; }
          .final-decision-wrapper .box-wrapper.secondary .box-header ul li {
            margin: 0 10px; }
            .final-decision-wrapper .box-wrapper.secondary .box-header ul li:first-child {
              margin-left: 0; }
            .final-decision-wrapper .box-wrapper.secondary .box-header ul li:last-child {
              margin-right: 0; }
            .final-decision-wrapper .box-wrapper.secondary .box-header ul li h4 {
              margin-top: 0; }
        .final-decision-wrapper .box-wrapper.secondary .box-header h4 {
          margin: 0;
          color: #00cdbe; }
        .final-decision-wrapper .box-wrapper.secondary .box-header small {
          letter-spacing: -0.1px;
          display: block; }
      .final-decision-wrapper .box-wrapper.secondary .box-content ul {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 0; }
        .final-decision-wrapper .box-wrapper.secondary .box-content ul li {
          padding: 15px;
          border-right: 1px solid #eeeeee;
          flex: 1 1; }
          .final-decision-wrapper .box-wrapper.secondary .box-content ul li label {
            color: #001061;
            margin-bottom: 10px; }
          .final-decision-wrapper .box-wrapper.secondary .box-content ul li span {
            color: #001061;
            display: flex;
            align-items: center; }
            .final-decision-wrapper .box-wrapper.secondary .box-content ul li span .icon {
              margin-top: -3px; }
          .final-decision-wrapper .box-wrapper.secondary .box-content ul li:last-child {
            border-right: none; }
    .final-decision-wrapper .box-wrapper .box-header {
      border-bottom: 1px solid #e0e0e0;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .final-decision-wrapper .box-wrapper .box-header h4 {
        color: #00cdbe;
        margin-top: 0; }
      .final-decision-wrapper .box-wrapper .box-header .decisions {
        background-color: #fafafa;
        border: none; }
      .final-decision-wrapper .box-wrapper .box-header .buttonFPR {
        background-color: #fafafa;
        border: none; }
    .final-decision-wrapper .box-wrapper .box-footer {
      background-color: #fff;
      border-radius: 0 0 15px 15px; }
      .final-decision-wrapper .box-wrapper .box-footer .list-unstyled {
        display: flex;
        flex-wrap: wrap; }
      .final-decision-wrapper .box-wrapper .box-footer .boldText {
        font-weight: 600 !important;
        display: inline-block;
        margin-right: 1em; }
      .final-decision-wrapper .box-wrapper .box-footer .centered {
        margin: auto; }
      .final-decision-wrapper .box-wrapper .box-footer ul {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0; }
        .final-decision-wrapper .box-wrapper .box-footer ul li {
          padding: 10px 15px;
          border-left: 1px solid #eeeeee;
          display: flex;
          flex-direction: column; }
          .final-decision-wrapper .box-wrapper .box-footer ul li label {
            margin-bottom: 10px; }
          .final-decision-wrapper .box-wrapper .box-footer ul li span {
            display: flex;
            align-items: center; }
            .final-decision-wrapper .box-wrapper .box-footer ul li span .select-box {
              display: inline-block;
              border: 1px solid #bcbcbc;
              border-radius: 7px;
              width: 134px;
              border: 1px solid #bcbcbc;
              box-sizing: border-box;
              border-radius: 5px;
              height: 30px; }
        .final-decision-wrapper .box-wrapper .box-footer ul span {
          font-size: 2.4rem;
          color: #ffffff;
          display: flex;
          align-items: center; }
          .final-decision-wrapper .box-wrapper .box-footer ul span .icon {
            margin-top: -3px; }
        .final-decision-wrapper .box-wrapper .box-footer ul:first-child {
          border-top: 0.5px solid rgba(255, 255, 255, 0.5);
          border-radius: 0 0 15px 15px; }
          .final-decision-wrapper .box-wrapper .box-footer ul:first-child li:first-child {
            border-bottom-left-radius: 15px; }
          .final-decision-wrapper .box-wrapper .box-footer ul:first-child li:last-child {
            border-bottom-right-radius: 15px; }
        .final-decision-wrapper .box-wrapper .box-footer ul:last-child {
          border-top: 0.5px solid rgba(255, 255, 255, 0.5);
          border-radius: 0 0 15px 15px; }
          .final-decision-wrapper .box-wrapper .box-footer ul:last-child li:first-child {
            border-bottom-left-radius: 15px;
            border-left: none; }
          .final-decision-wrapper .box-wrapper .box-footer ul:last-child li:last-child {
            border-bottom-right-radius: 15px; }
    .final-decision-wrapper .box-wrapper .input-field-box ::-webkit-input-placeholder {
      color: #bcbcbc; }
    .final-decision-wrapper .box-wrapper .input-field-box .dropdown .select-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #eee;
      border-radius: 7px;
      padding: 15px 12px;
      text-overflow: ellipsis;
      overflow: hidden; }
    .final-decision-wrapper .box-wrapper .input-field-box .input-label {
      margin-top: -35px;
      font-weight: bold !important; }
    .final-decision-wrapper .box-wrapper .taller {
      margin-left: 10px;
      height: 200px; }
    .final-decision-wrapper .box-wrapper .input-box1 .dropdown .select-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #bcbcbc;
      border: 1px solid #eee;
      border-radius: 7px;
      padding: 15px 12px;
      text-overflow: ellipsis;
      overflow: hidden; }
    .final-decision-wrapper .box-wrapper .input-box1 .input-label {
      margin-left: 10px;
      height: 300px;
      margin-top: -35px; }
  .final-decision-wrapper .cc-score-box {
    margin-top: 20px;
    margin-bottom: 5px; }
    .final-decision-wrapper .cc-score-box .score-box-text {
      font-size: 1.4rem;
      padding: 3px 5px; }
      .final-decision-wrapper .cc-score-box .score-box-text.score-box-text-weight {
        font-weight: 600; }
    .final-decision-wrapper .cc-score-box .vl {
      border-left: 1px solid;
      height: 93px;
      position: absolute;
      left: 50%;
      margin-left: 33px;
      top: 0;
      color: #bcbcbc;
      margin-top: -12px; }
    .final-decision-wrapper .cc-score-box .guageChart {
      margin-left: -32px;
      padding: 16px;
      padding-right: 0px; }
    .final-decision-wrapper .cc-score-box .chart {
      padding: 0px; }
    .final-decision-wrapper .cc-score-box span {
      font-size: 2.6rem;
      color: #f0ce70; }
    .final-decision-wrapper .cc-score-box ul {
      list-style: none;
      display: flex;
      margin: 30px 0 0; }
      .final-decision-wrapper .cc-score-box ul li {
        flex: 1 1;
        border: 0.5px solid #ddd;
        padding: 2px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center; }
        .final-decision-wrapper .cc-score-box ul li:first-child {
          flex: 2.5 1; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(n + 2) {
          background-color: #000;
          color: #fff; }
        .final-decision-wrapper .cc-score-box ul li span {
          position: absolute;
          top: -30px;
          color: #ed5a5a;
          white-space: nowrap; }
          .final-decision-wrapper .cc-score-box ul li span div {
            display: block !important;
            margin-top: -3px; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(2), .final-decision-wrapper .cc-score-box ul li:nth-child(3) {
          background-color: #f0ce70; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(4) {
          background-color: #f0ce70; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(5) {
          background-color: #f0ce70; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(6) {
          background-color: #f0ce70; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(7) {
          background-color: #f0ce70; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(8) {
          background-color: #f0ce70; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(9) {
          background-color: #f0ce70; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(10) {
          background-color: #f0ce70; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(11) {
          background-color: #8bd591; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(12) {
          background-color: #8bd591; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(13) {
          background-color: #8bd591; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(14) {
          background-color: #8bd591; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(15) {
          background-color: #8bd591; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(16) {
          background-color: #8bd591; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(17) {
          background-color: #8bd591; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(18) {
          background-color: #8bd591; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(19) {
          background-color: #8bd591; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(20) {
          background-color: #8bd591; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(21) {
          background-color: #8bd591; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(22) {
          background-color: #8bd591; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(23) {
          background-color: #8bd591; }
  .final-decision-wrapper .vertical-divider-left {
    position: relative; }
    .final-decision-wrapper .vertical-divider-left::before {
      position: absolute;
      left: 0;
      content: "";
      height: 100%;
      width: 1px;
      border: 1px solid #e0e0e0; }
  .final-decision-wrapper .vertical-divider-right {
    position: relative; }
    .final-decision-wrapper .vertical-divider-right::before {
      position: absolute;
      right: 0;
      content: "";
      width: 1px;
      border: 1px solid #e0e0e0; }
  .final-decision-wrapper .highcharts-data-label,
  .final-decision-wrapper .highcharts-axis-labels {
    display: none; }
  .final-decision-wrapper .table-box {
    overflow-x: scroll;
    margin-top: 20px; }
    .final-decision-wrapper .table-box .btns-box {
      text-align: center;
      margin-top: 15px; }
      .final-decision-wrapper .table-box .btns-box button {
        color: #ffffff;
        border: none;
        border-radius: 25px;
        padding: 10px;
        min-width: 100px;
        background: #00cdbe; }
    .final-decision-wrapper .table-box table {
      width: 100%; }
    .final-decision-wrapper .table-box::-webkit-scrollbar {
      height: 8px; }
    .final-decision-wrapper .table-box::-webkit-scrollbar-button {
      display: none; }
    .final-decision-wrapper .table-box::-webkit-scrollbar-track {
      background: transparent; }
    .final-decision-wrapper .table-box::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #001061; }
  .final-decision-wrapper .primary-table thead::-webkit-scrollbar {
    height: 300px; }
  .final-decision-wrapper .primary-table thead tr {
    background-color: #e5e5e5; }
    .final-decision-wrapper .primary-table thead tr th {
      border: 1px solid #fff;
      text-align: center;
      padding: 10px; }
  .final-decision-wrapper .primary-table tbody tr td {
    text-align: center;
    padding: 10px;
    border-right: 2px solid #fff; }
    .final-decision-wrapper .primary-table tbody tr td:last-child {
      border-right: none; }
    .final-decision-wrapper .primary-table tbody tr td .residenceStatus {
      font-weight: 800 !important;
      color: #980e18; }
    .final-decision-wrapper .primary-table tbody tr td .btn {
      width: 150px;
      color: #ffffff;
      border: none;
      padding: 9px;
      background: #29a9e0;
      border-radius: 5px; }
    .final-decision-wrapper .primary-table tbody tr td .select-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #eee;
      border-radius: 7px;
      padding: 10px 12px;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%; }
  .final-decision-wrapper .primary-table tbody tr:nth-child(odd) {
    background: #f0f0f0; }
  .final-decision-wrapper .primary-table tbody tr:nth-child(even) {
    background: #fafafa; }
  .final-decision-wrapper .primary-table.bank-table tbody tr:last-child {
    border-top: 1px solid #bcbcbc; }
  .final-decision-wrapper .ReactTable {
    margin: 20px auto; }
    .final-decision-wrapper .ReactTable .rt-thead .rt-th {
      position: relative;
      background-color: #001061;
      color: #ffffff;
      padding: 12px 0;
      border-right-color: #fff; }
      .final-decision-wrapper .ReactTable .rt-thead .rt-th.-sort-asc::after {
        content: "\f0d7";
        position: absolute;
        right: 9px;
        top: 10px; }
      .final-decision-wrapper .ReactTable .rt-thead .rt-th.-sort-desc::after {
        content: "\f0d8";
        position: absolute;
        right: 9px;
        top: 10px; }
    .final-decision-wrapper .ReactTable .rt-tbody .rt-tr {
      text-align: center;
      padding: 2px 0; }
      .final-decision-wrapper .ReactTable .rt-tbody .rt-tr .rt-td {
        border-right-color: transparent;
        text-align: center;
        padding: 15px 0px !important;
        border-right: 2px solid #fff; }
        .final-decision-wrapper .ReactTable .rt-tbody .rt-tr .rt-td:last-child {
          border-right: none; }
        .final-decision-wrapper .ReactTable .rt-tbody .rt-tr .rt-td .boldText {
          font-weight: 600 !important;
          margin-right: 1em; }
        .final-decision-wrapper .ReactTable .rt-tbody .rt-tr .rt-td .icons {
          color: #fff;
          border-radius: 25px;
          width: 23px;
          height: 24px;
          display: inline-block;
          text-align: center;
          padding-top: 2px; }
          .final-decision-wrapper .ReactTable .rt-tbody .rt-tr .rt-td .icons.check-icon {
            background-color: #78e35f; }
          .final-decision-wrapper .ReactTable .rt-tbody .rt-tr .rt-td .icons.close-icon {
            background-color: #ff0000; }
      .final-decision-wrapper .ReactTable .rt-tbody .rt-tr.-odd {
        background-color: #f0f0f0; }
      .final-decision-wrapper .ReactTable .rt-tbody .rt-tr.-even {
        background-color: #fafafa; }
  .final-decision-wrapper .table-box {
    margin-bottom: 20px;
    overflow: hidden; }
    .final-decision-wrapper .table-box table {
      width: 100%; }
      .final-decision-wrapper .table-box table thead tr {
        background-color: #eeeeee; }
        .final-decision-wrapper .table-box table thead tr th {
          position: relative;
          background-color: #001061;
          color: #ffffff;
          padding: 12px 0;
          border-right-color: #fff; }
      .final-decision-wrapper .table-box table tbody tr td {
        flex: 1 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 7px 5px;
        overflow: hidden;
        transition: 0.3s ease;
        transition-property: width, min-width, padding, opacity;
        border-right: 2px solid #fff; }
        .final-decision-wrapper .table-box table tbody tr td:last-child {
          border-right: none; }
    .final-decision-wrapper .table-box .table-footer {
      padding: 20px;
      border-top: 1px solid #eee; }
      .final-decision-wrapper .table-box .table-footer label {
        margin-bottom: 10px; }
  .final-decision-wrapper .table-boxs {
    margin-bottom: 20px;
    overflow: hidden; }
    .final-decision-wrapper .table-boxs .btns-box {
      text-align: center;
      margin-top: 15px; }
      .final-decision-wrapper .table-boxs .btns-box button {
        color: #ffffff;
        border: none;
        border-radius: 25px;
        padding: 10px;
        min-width: 100px;
        background: #00cdbe; }
    .final-decision-wrapper .table-boxs table {
      width: 100%; }
      .final-decision-wrapper .table-boxs table thead tr {
        background: #e3f7ff; }
        .final-decision-wrapper .table-boxs table thead tr th {
          position: relative;
          background-color: #001061;
          color: #ffffff;
          padding: 12px 0;
          border-right-color: #fff; }
      .final-decision-wrapper .table-boxs table tbody tr td {
        text-align: center;
        padding: 7px 0;
        border-right: 2px solid #fff; }
        .final-decision-wrapper .table-boxs table tbody tr td:last-child {
          border-right: none; }
        .final-decision-wrapper .table-boxs table tbody tr td button {
          background: #00cdbe;
          border-radius: 50px;
          border-style: solid;
          width: 136px; }
        .final-decision-wrapper .table-boxs table tbody tr td:last-child {
          border-right: none; }
    .final-decision-wrapper .table-boxs .table-footer {
      padding: 20px;
      border-top: 1px solid #eee;
      background: #e3f7ff; }
      .final-decision-wrapper .table-boxs .table-footer label {
        margin-bottom: 10px; }
  .final-decision-wrapper .highlights-box-strengths {
    padding: 8px;
    margin-bottom: 30px; }
    .final-decision-wrapper .highlights-box-strengths h4 {
      margin-top: 0;
      color: #19ce35;
      text-align: center; }
    .final-decision-wrapper .highlights-box-strengths.strengths h4 {
      margin-top: 0;
      color: #19ce35; }
    .final-decision-wrapper .highlights-box-strengths.flags h4 {
      margin-top: 0;
      color: #ed5a5a; }
  .final-decision-wrapper .highlights-box-flags {
    padding: 8px;
    margin-bottom: 30px;
    margin-left: 22px; }
    .final-decision-wrapper .highlights-box-flags .box-content {
      margin-top: 5px; }
    .final-decision-wrapper .highlights-box-flags li {
      margin-top: 17px; }
    .final-decision-wrapper .highlights-box-flags h4 {
      margin-top: 0;
      color: #ed5a5a;
      text-align: center; }
    .final-decision-wrapper .highlights-box-flags.strengths h4 {
      margin-top: 0;
      color: #19ce35; }
    .final-decision-wrapper .highlights-box-flags.flags h4 {
      margin-top: 0;
      color: #ff0000; }
  .final-decision-wrapper .filters-box {
    margin-top: 48px; }
    .final-decision-wrapper .filters-box .dropdown {
      margin-top: 0; }
    .final-decision-wrapper .filters-box .btns-box {
      display: flex;
      justify-content: center;
      align-content: center;
      margin-top: 25px; }
      .final-decision-wrapper .filters-box .btns-box svg {
        margin-top: -5px; }
  .final-decision-wrapper .referenceMargin {
    margin-bottom: 5px;
    margin-top: 12px; }
    .final-decision-wrapper .referenceMargin .references-box {
      margin: 5px 5px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      border-radius: 25px;
      overflow: hidden;
      position: relative;
      float: left;
      width: 330px;
      height: 320px;
      background-color: white; }
      .final-decision-wrapper .referenceMargin .references-box .content {
        margin: 20px 0; }
        .final-decision-wrapper .referenceMargin .references-box .content:first-child {
          margin-top: 0; }
        .final-decision-wrapper .referenceMargin .references-box .content:last-child {
          margin-bottom: 0; }
        .final-decision-wrapper .referenceMargin .references-box .content label,
        .final-decision-wrapper .referenceMargin .references-box .content span {
          display: block; }
      .final-decision-wrapper .referenceMargin .references-box .verified {
        background: #00cdbe;
        border-radius: 50px;
        border: solid;
        width: 100px;
        color: #ffffff;
        height: 30px; }
      .final-decision-wrapper .referenceMargin .references-box .verifying {
        border-radius: 50px;
        border: solid;
        width: 100px;
        background-color: #ffffff;
        text-align: center;
        margin-left: -3px;
        border: 1px solid #00cdbe; }
      .final-decision-wrapper .referenceMargin .references-box .edit {
        background-color: #ffffff;
        text-align: center;
        margin-left: 125px;
        border: none; }
      .final-decision-wrapper .referenceMargin .references-box .reference {
        background: #ffffff;
        padding: 5px; }
        .final-decision-wrapper .referenceMargin .references-box .reference h4 {
          font-size: 1.2rem;
          color: #00cdbe;
          border-bottom: 1px solid #e0e0e0;
          padding-bottom: 5px; }
        .final-decision-wrapper .referenceMargin .references-box .reference label,
        .final-decision-wrapper .referenceMargin .references-box .reference span {
          word-wrap: break-word;
          display: block; }
          .final-decision-wrapper .referenceMargin .references-box .reference label address,
          .final-decision-wrapper .referenceMargin .references-box .reference span address {
            line-height: inherit; }
        .final-decision-wrapper .referenceMargin .references-box .reference:last-child {
          margin-bottom: 0; }
        .final-decision-wrapper .referenceMargin .references-box .reference ul {
          margin-bottom: 0; }
          .final-decision-wrapper .referenceMargin .references-box .reference ul li label {
            font-size: 1rem;
            font-weight: bold;
            margin-right: 10px;
            word-wrap: break-word; }
      .final-decision-wrapper .referenceMargin .references-box .reference1 {
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        background: #ffffff;
        padding: 5px; }
        .final-decision-wrapper .referenceMargin .references-box .reference1 .verifying {
          border: 1px solid #00cdbe;
          border-radius: 50px;
          border: solid;
          width: 100px;
          background-color: #ffffff;
          text-align: center;
          margin-left: -3px;
          border: 1px solid #00cdbe; }
        .final-decision-wrapper .referenceMargin .references-box .reference1 h5 {
          font-weight: bold;
          margin-right: 10px;
          word-wrap: break-word; }
        .final-decision-wrapper .referenceMargin .references-box .reference1 .buttonRow {
          margin-top: 10px; }
        .final-decision-wrapper .referenceMargin .references-box .reference1 h4 {
          font-size: 1.2rem;
          color: #00cdbe;
          border-bottom: 1px solid #e0e0e0;
          padding-bottom: 5px; }
        .final-decision-wrapper .referenceMargin .references-box .reference1 span {
          font-size: 1rem;
          word-wrap: break-word; }
          .final-decision-wrapper .referenceMargin .references-box .reference1 span address {
            line-height: inherit; }
        .final-decision-wrapper .referenceMargin .references-box .reference1:last-child {
          margin-bottom: 0; }
        .final-decision-wrapper .referenceMargin .references-box .reference1 ul {
          margin-bottom: 0; }
          .final-decision-wrapper .referenceMargin .references-box .reference1 ul li label {
            font-size: 1rem;
            font-weight: bold;
            margin-right: 10px;
            word-wrap: break-word; }
  .final-decision-wrapper .other-information-box {
    margin-bottom: 20px;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    padding: 15px; }
    .final-decision-wrapper .other-information-box h4 {
      margin-top: 0; }
    .final-decision-wrapper .other-information-box ul {
      margin-bottom: 0; }
  .final-decision-wrapper .file-dropzone-box h4 {
    margin-top: 0; }
  .final-decision-wrapper .file-dropzone-box .dropdown {
    margin-top: 0; }
  .final-decision-wrapper .file-dropzone-box .dropzone {
    background-color: #efefef;
    text-align: center;
    margin-top: 15px;
    padding: 15px 0;
    cursor: pointer; }
    .final-decision-wrapper .file-dropzone-box .dropzone svg {
      color: #bcbcbc; }
    .final-decision-wrapper .file-dropzone-box .dropzone p {
      font-size: 1.2rem;
      line-height: 1.39; }
  .final-decision-wrapper .file-dropzone-box .btns-box {
    text-align: center;
    margin-top: 15px; }
    .final-decision-wrapper .file-dropzone-box .btns-box button {
      color: #ffffff;
      border: none;
      border-radius: 25px;
      padding: 10px;
      min-width: 100px;
      background: #00cdbe; }
  .final-decision-wrapper .cam-box {
    text-align: center; }
    .final-decision-wrapper .cam-box span {
      color: #acacac;
      display: inline-block;
      margin-bottom: 15px; }
    .final-decision-wrapper .cam-box .btns-box {
      margin-top: 15px; }
      .final-decision-wrapper .cam-box .btns-box button {
        color: #ffffff;
        border: none;
        border-radius: 25px;
        padding: 10px 15px;
        background: #00cdbe; }
  .final-decision-wrapper .react-switch-bg {
    border: 3px solid #00cdbe;
    width: 90px !important;
    margin-bottom: 5px !important; }
    .final-decision-wrapper .react-switch-bg span {
      font-weight: bold;
      color: #00cdbe; }
    .final-decision-wrapper .react-switch-bg div:first-child {
      top: 2px !important;
      left: 9px !important; }
    .final-decision-wrapper .react-switch-bg div:last-child {
      top: 2px !important;
      right: 9px !important; }
  .final-decision-wrapper .react-switch-handle {
    width: 45px !important;
    border-radius: 25px !important; }
  .final-decision-wrapper .switch-open .react-switch-handle {
    transform: translateX(45px) !important; }
  .final-decision-wrapper .conditions-list ul li {
    padding: 12px;
    border-radius: 7px;
    border: 1px solid #bcbcbc;
    margin-bottom: 10px; }
    .final-decision-wrapper .conditions-list ul li:last-child {
      margin-bottom: 0; }
  .final-decision-wrapper .decisions-box label {
    font-weight: bold !important; }
  .final-decision-wrapper .decisions-box .decisionContainer {
    margin-top: 21px;
    padding-left: 18px; }
    .final-decision-wrapper .decisions-box .decisionContainer .finalDecisionRow {
      margin-top: 10px;
      margin-bottom: 20px; }
    .final-decision-wrapper .decisions-box .decisionContainer .content-box {
      margin-top: 20px; }
      .final-decision-wrapper .decisions-box .decisionContainer .content-box .content {
        margin: 20px 0; }
        .final-decision-wrapper .decisions-box .decisionContainer .content-box .content:first-child {
          margin-top: 0; }
        .final-decision-wrapper .decisions-box .decisionContainer .content-box .content:last-child {
          margin-bottom: 0; }
        .final-decision-wrapper .decisions-box .decisionContainer .content-box .content label,
        .final-decision-wrapper .decisions-box .decisionContainer .content-box .content span {
          display: block; }
  .final-decision-wrapper .decisions-box .dropdown {
    margin-top: 0; }
  .final-decision-wrapper .decisions-box .radios {
    display: flex;
    align-items: center;
    margin: 10px 0; }
    .final-decision-wrapper .decisions-box .radios label {
      margin-bottom: 0;
      margin-left: 5px;
      cursor: pointer; }
    .final-decision-wrapper .decisions-box .radios h3 {
      font-size: 2.1rem; }
  .final-decision-wrapper .decisions-box .input-field-box {
    margin: 15px 0; }
  .final-decision-wrapper .decisions-box .btns-box {
    display: flex;
    flex-direction: column;
    align-items: flex-end; }
    .final-decision-wrapper .decisions-box .btns-box button {
      color: #ffffff;
      border: none;
      border-radius: 25px;
      width: 100px;
      padding: 10px;
      background: #29a9e0;
      margin-top: 17px;
      margin-right: 15px; }
    .final-decision-wrapper .decisions-box .btns-box .submit-btn {
      background-color: #29a9e0; }
    .final-decision-wrapper .decisions-box .btns-box .upload-btn {
      margin-top: 10px;
      background-color: #00cdbe; }
  .final-decision-wrapper .tab-content {
    border-right: 1px solid #bcbcbc;
    border-bottom: 1px solid #bcbcbc;
    border-left: 1px solid #bcbcbc;
    border-top: 2px solid #e5e5e5;
    border-radius: 25px;
    margin-top: -2px;
    margin-bottom: 15px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    padding: 20px; }
    .final-decision-wrapper .tab-content table {
      width: 100%; }
      .final-decision-wrapper .tab-content table thead tr {
        background-color: #001061;
        color: white;
        border-radius: 0px; }
        .final-decision-wrapper .tab-content table thead tr th {
          border-right: 1px solid white;
          padding: 1em;
          text-align: center; }
      .final-decision-wrapper .tab-content table tbody tr:nth-child(2n + 1) > td {
        background-color: #f0f0f0; }
      .final-decision-wrapper .tab-content table tbody tr td {
        padding: 0.5em;
        text-align: center;
        border-right: 2px solid #fff; }
        .final-decision-wrapper .tab-content table tbody tr td:last-child {
          border-right: none; }
    .final-decision-wrapper .tab-content .doc-wrapper {
      font-size: 1.1rem;
      display: flex;
      border: 1px solid #bcbcbc;
      border-radius: 20px;
      max-width: 121px;
      margin-top: 1%;
      justify-content: flex-end; }
      .final-decision-wrapper .tab-content .doc-wrapper .pdf-icon {
        margin: 3px 3px 0px 2px;
        color: #ff0000;
        padding: 0px; }
      .final-decision-wrapper .tab-content .doc-wrapper .archive-file {
        padding-right: 0px;
        border-radius: 100px;
        height: 25px;
        width: 24px; }
      .final-decision-wrapper .tab-content .doc-wrapper .archive-file:hover {
        cursor: pointer; }
    .final-decision-wrapper .tab-content .actions-box {
      padding: 5px 10px;
      display: flex;
      justify-content: flex-end; }
      .final-decision-wrapper .tab-content .actions-box span {
        padding: 0 10px; }
      .final-decision-wrapper .tab-content .actions-box .icon-download {
        color: #2d9086;
        cursor: pointer; }
      .final-decision-wrapper .tab-content .actions-box .icon-upload {
        color: #001061;
        cursor: pointer; }
  .final-decision-wrapper .nav-tabs {
    margin: 0 30px;
    border-color: #bcbcbc; }
    .final-decision-wrapper .nav-tabs > li {
      margin: 0 4px -1px;
      background-color: #e5e5e5;
      border-radius: 5px 5px 0 0;
      position: relative; }
      .final-decision-wrapper .nav-tabs > li a {
        border-radius: 5px 5px 0 0;
        padding: 12px; }
        .final-decision-wrapper .nav-tabs > li a > div {
          padding: 10px 20px; }
        .final-decision-wrapper .nav-tabs > li a label {
          margin-bottom: 0; }
      .final-decision-wrapper .nav-tabs > li:first-child a > div {
        padding: 18px 20px; }
      .final-decision-wrapper .nav-tabs > li.active::after {
        content: "";
        width: 75%;
        height: 2px;
        background-color: #00cdbe;
        position: absolute;
        bottom: 0;
        left: 13%; }
  .final-decision-wrapper .refresh-btn {
    background-color: #00cdbe;
    float: right;
    border: none;
    border-radius: 20px;
    padding: 0.5em 2em;
    color: #fff;
    margin-bottom: 1em; }
  .final-decision-wrapper .docsNameHeader {
    color: #00cdbe;
    font-size: 1.4em; }
  .final-decision-wrapper .mtl {
    margin-top: 2em; }
  .final-decision-wrapper .btnGreen {
    color: #ffffff;
    border: none;
    border-radius: 25px;
    background-color: #00cdbe;
    padding: 5px 10px;
    margin-bottom: 5px;
    width: 100px;
    height: 30px; }
    .final-decision-wrapper .btnGreen.padding {
      margin: 10px;
      width: auto;
      height: auto;
      padding: 7px 20px; }
  .final-decision-wrapper .blueButton {
    background-color: #29a9e0;
    color: #ffffff;
    border: none;
    border-radius: 25px;
    padding: 5px 10px;
    margin-bottom: 5px;
    width: 100px;
    height: 30px;
    min-width: 120px; }
    .final-decision-wrapper .blueButton.padding {
      margin: 10px;
      width: auto;
      height: auto;
      padding: 7px 20px; }

.overview-applicant-modal.edit-ratio-form1 .cross-icon {
  position: absolute;
  right: 36px;
  top: 9px;
  cursor: pointer; }

.overview-applicant-modal.edit-ratio-form1 .content {
  width: 600px;
  border-radius: 15px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #eeeeee;
  background-color: #ffffff; }

.overview-applicant-modal.edit-ratio-form1 .box-wrapper .input-field:disabled {
  background-color: #fff; }

.overview-applicant-modal.edit-ratio-form1 .box-wrapper .table-box1 {
  overflow-x: scroll;
  margin-top: 20px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
  .overview-applicant-modal.edit-ratio-form1 .box-wrapper .table-box1 .btns-box {
    text-align: center;
    margin-top: 15px; }
    .overview-applicant-modal.edit-ratio-form1 .box-wrapper .table-box1 .btns-box button {
      color: #ffffff;
      border: none;
      border-radius: 25px;
      padding: 10px;
      min-width: 100px;
      background: #00cdbe; }
  .overview-applicant-modal.edit-ratio-form1 .box-wrapper .table-box1 table {
    width: 100%; }
  .overview-applicant-modal.edit-ratio-form1 .box-wrapper .table-box1::-webkit-scrollbar {
    height: 8px; }
  .overview-applicant-modal.edit-ratio-form1 .box-wrapper .table-box1::-webkit-scrollbar-button {
    display: none; }
  .overview-applicant-modal.edit-ratio-form1 .box-wrapper .table-box1::-webkit-scrollbar-track {
    background: transparent; }
  .overview-applicant-modal.edit-ratio-form1 .box-wrapper .table-box1::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #001061; }

.overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table thead::-webkit-scrollbar {
  height: 300px; }

.overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table thead tr {
  background-color: #e5e5e5; }
  .overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table thead tr th {
    border: 1px solid #fff;
    text-align: center;
    padding: 10px;
    background: #001061;
    color: #ffffff; }

.overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody .editIcon {
  height: 20px;
  width: 20px; }

.overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr {
  margin-top: 10px; }
  .overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr td {
    text-align: center;
    padding: 10px;
    border-right: 2px solid #fff; }
    .overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr td:last-child {
      border-right: none; }
    .overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr td .btnIcon {
      background-color: #fafafa;
      border: none;
      float: right; }
    .overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr td ::-webkit-input-placeholder {
      margin-top: 200px; }
    .overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr td :-ms-input-placeholder {
      margin-top: 200px; }
    .overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr td ::-ms-input-placeholder {
      margin-top: 200px; }
    .overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr td ::placeholder {
      margin-top: 200px; }
    .overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr td .pin-code-select-search {
      margin: 10px 0; }
      .overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr td .pin-code-select-search > div {
        border: 1px solid #eee;
        padding: 3px 0;
        box-shadow: none;
        border-radius: 7px;
        height: 1px; }
      .overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr td .pin-code-select-search input:not([type="hidden"]) {
        text-align: left;
        color: #000; }
      .overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr td .pin-code-select-search .select__single-value,
      .overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr td .pin-code-select-search .select__menu {
        text-transform: capitalize; }
      .overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr td .pin-code-select-search .select__indicators {
        display: none; }
      .overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr td .pin-code-select-search .select__placeholder {
        color: #bcbcbc;
        text-align: left; }
      .overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr td .pin-code-select-search .select__control {
        position: relative;
        background: #ffffff;
        border: 1px solid #afafaf;
        box-sizing: border-box;
        border-radius: 5px; }
        .overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr td .pin-code-select-search .select__control::after {
          content: "\f0d7";
          position: absolute;
          right: 10px;
          top: 13px;
          color: #bcbcbc;
          font-size: 1.2rem; }
        .overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr td .pin-code-select-search .select__control.select__control--is-focused {
          box-shadow: 0 0 5px 0 rgba(0, 16, 97, 0.5); }
    .overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr td .residenceStatus {
      font-weight: 800 !important;
      color: #980e18; }
    .overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr td .btn {
      width: 150px;
      color: #ffffff;
      border: none;
      padding: 9px;
      background: #29a9e0;
      border-radius: 5px; }
    .overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr td .select-box {
      width: 138px;
      background: #ffffff;
      border: 1px solid #afafaf;
      box-sizing: border-box;
      border-radius: 5px;
      height: 33px; }

.overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr:nth-child(odd) {
  background: #f0f0f0; }

.overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr:nth-child(even) {
  background: #fafafa; }

.overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table.bank-table tbody tr:last-child {
  border-top: 1px solid #bcbcbc; }

.overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-header {
  padding: 15px;
  border-radius: 15px 15px 0 0; }
  .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-header ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    width: 100%; }
    .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-header ul li {
      margin: 0 10px; }
      .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-header ul li:first-child {
        margin-left: 0; }
      .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-header ul li:last-child {
        margin-right: 0; }
      .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-header ul li h4 {
        margin-top: 0; }
  .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-header h4 {
    margin-top: 0;
    margin-bottom: 0;
    color: #00cdbe; }
  .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-header small {
    letter-spacing: -0.1px;
    display: block; }

.overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-content .input {
  width: 50px; }

.overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-content ul {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0; }
  .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-content ul li {
    padding: 15px;
    border-right: 1px solid #eeeeee;
    flex: 1 1; }
    .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-content ul li label {
      color: #001061;
      margin-bottom: 10px; }
    .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-content ul li span {
      font-size: 2.4rem;
      color: #001061;
      display: flex;
      align-items: center; }
      .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-content ul li span .icon {
        margin-top: -3px; }
    .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-content ul li:last-child {
      border-right: none; }

.overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-header {
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-header h4 {
    color: #00cdbe;
    margin-top: 0; }
  .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-header button {
    color: #ffffff;
    border: none;
    border-radius: 15px;
    background-color: #00cdbe;
    padding: 5px 10px;
    margin-bottom: 5px;
    width: 71px;
    height: 38px; }

.overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-footer {
  background-color: #fff;
  border-radius: 0 0 15px 15px; }
  .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-footer ul {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0; }
    .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-footer ul li {
      padding: 10px 15px;
      border-left: 1px solid #eeeeee;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-footer ul li label .select-box,
      .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-footer ul li span .select-box {
        height: 20px; }
      .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-footer ul li label {
        margin-bottom: 10px; }
      .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-footer ul li span {
        font-size: 2.4rem;
        display: flex;
        align-items: center; }
        .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-footer ul li span .select-box {
          height: 20px; }
    .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-footer ul span {
      font-size: 2.4rem;
      color: #ffffff;
      display: flex;
      align-items: center; }
      .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-footer ul span .icon {
        margin-top: -3px; }
    .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-footer ul:last-child {
      border-top: 0.5px solid rgba(255, 255, 255, 0.5);
      border-radius: 0 0 15px 15px; }
      .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-footer ul:last-child li:first-child {
        border-bottom-left-radius: 15px; }
      .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-footer ul:last-child li:last-child {
        border-bottom-right-radius: 15px; }

.overview-applicant-modal.edit-ratio-form1 .box-wrapper .input-field-box {
  display: inline-block;
  border: 1px solid #eee;
  border-radius: 7px;
  width: 100%;
  padding: 15px 12px;
  font-size: 1rem;
  height: auto;
  color: #bcbcbc; }
  .overview-applicant-modal.edit-ratio-form1 .box-wrapper .input-field-box ::-webkit-input-placeholder {
    color: #bcbcbc; }
  .overview-applicant-modal.edit-ratio-form1 .box-wrapper .input-field-box .dropdown .select-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #bcbcbc;
    border: 1px solid #eee;
    border-radius: 7px;
    padding: 15px 12px;
    text-overflow: ellipsis;
    overflow: hidden; }
  .overview-applicant-modal.edit-ratio-form1 .box-wrapper .input-field-box .input-label {
    margin-top: -35px; }

.overview-applicant-modal.edit-ratio-form1 .box-wrapper .taller {
  margin-left: 10px;
  height: 200px; }

.overview-applicant-modal.edit-ratio-form1 .box-wrapper .input-box1 ::-webkit-input-placeholder {
  color: #bcbcbc; }

.overview-applicant-modal.edit-ratio-form1 .box-wrapper .input-box1 .dropdown .select-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #bcbcbc;
  border: 1px solid #eee;
  border-radius: 7px;
  padding: 15px 12px;
  text-overflow: ellipsis;
  overflow: hidden; }

.overview-applicant-modal.edit-ratio-form1 .box-wrapper .input-box1 .input-label {
  margin-left: 10px;
  height: 300px;
  margin-top: -35px; }

.overview-applicant-modal.edit-ratio-form1 .textArea-input-field {
  margin-bottom: 2px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  border-radius: 5px;
  height: 60px;
  resize: none;
  overflow-y: hidden;
  width: 100%;
  padding: 5px; }

.dashboard-wrapper .user-list-dropdown__control {
  height: 50px; }

.dashboard-wrapper .underwriters-modal {
  width: 500px;
  padding: 20px;
  margin: 15px;
  background-color: #fff;
  border-radius: 20px;
  height: auto; }
  .dashboard-wrapper .underwriters-modal::-webkit-scrollbar {
    height: 3px; }
  .dashboard-wrapper .underwriters-modal::-webkit-scrollbar-button {
    display: none; }
  .dashboard-wrapper .underwriters-modal::-webkit-scrollbar-track {
    background: transparent; }
  .dashboard-wrapper .underwriters-modal::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #001061; }
  .dashboard-wrapper .underwriters-modal .box-header {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .dashboard-wrapper .underwriters-modal .box-header h4 {
      line-height: 20px;
      color: #1d1d1d;
      margin: 0; }
    .dashboard-wrapper .underwriters-modal .box-header .cross-icon {
      color: #bcbcbc;
      cursor: pointer;
      margin-top: 0; }
  .dashboard-wrapper .underwriters-modal h3 {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.73;
    color: #1d1d1d; }
  .dashboard-wrapper .underwriters-modal .underwriters-box .search-box {
    border-bottom: 1px solid #707070; }
    .dashboard-wrapper .underwriters-modal .underwriters-box .search-box span {
      color: #bcbcbc;
      margin-right: 5px; }
    .dashboard-wrapper .underwriters-modal .underwriters-box .search-box input {
      border: none;
      font-size: 14px;
      line-height: 1.14; }
  .dashboard-wrapper .underwriters-modal .underwriters-box .underwriters-list {
    list-style: none;
    max-height: 200px;
    overflow-y: scroll; }
    .dashboard-wrapper .underwriters-modal .underwriters-box .underwriters-list::-webkit-scrollbar {
      width: 3px; }
    .dashboard-wrapper .underwriters-modal .underwriters-box .underwriters-list::-webkit-scrollbar-button {
      display: none; }
    .dashboard-wrapper .underwriters-modal .underwriters-box .underwriters-list::-webkit-scrollbar-track {
      background: transparent; }
    .dashboard-wrapper .underwriters-modal .underwriters-box .underwriters-list::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #001061; }
    .dashboard-wrapper .underwriters-modal .underwriters-box .underwriters-list li {
      margin: 10px 0; }
  .dashboard-wrapper .underwriters-modal button {
    font-size: 14px;
    line-height: 1.19;
    color: #ffffff;
    border: none;
    border-radius: 50px;
    background-color: #00cdbe;
    width: 100px;
    padding: 10px;
    margin-top: 20px; }

.dashboard-wrapper .daterange-bar-wrapper {
  background-color: #001061;
  padding: 10px 0; }
  .dashboard-wrapper .daterange-bar-wrapper .add-btn {
    border-radius: 25px;
    background-color: #00cdbe;
    border: none;
    font-size: 14px;
    line-height: 1.19;
    letter-spacing: 0;
    color: #ffffff;
    padding: 7px 15px;
    display: flex;
    align-items: center;
    float: left; }
    .dashboard-wrapper .daterange-bar-wrapper .add-btn span:last-child {
      text-align: left; }
  .dashboard-wrapper .daterange-bar-wrapper .daterange-box {
    display: flex;
    align-items: center;
    justify-content: flex-end; }
    .dashboard-wrapper .daterange-bar-wrapper .daterange-box .search-icon {
      color: #fff;
      background-color: #00cdbe;
      cursor: pointer;
      padding: 3px 10px 13px;
      display: inline-block; }
    .dashboard-wrapper .daterange-bar-wrapper .daterange-box > span {
      color: #fff;
      line-height: 1.19;
      letter-spacing: 0;
      margin-right: 25px; }
    .dashboard-wrapper .daterange-bar-wrapper .daterange-box button {
      background-color: transparent;
      border: 1px solid #fff;
      line-height: 1.19;
      letter-spacing: normal;
      color: #ffffff;
      padding: 7px 10px;
      min-width: 350px;
      display: flex;
      justify-content: space-around;
      align-items: center; }
    .dashboard-wrapper .daterange-bar-wrapper .daterange-box .icons {
      margin-top: -3px; }
    .dashboard-wrapper .daterange-bar-wrapper .daterange-box .clear-filter {
      color: white;
      border-radius: 10px;
      border: 1px solid white;
      padding: 4px 10px; }
      .dashboard-wrapper .daterange-bar-wrapper .daterange-box .clear-filter:hover {
        cursor: pointer; }

.dashboard-wrapper .applicants-list-wrapper {
  width: 90%;
  margin: 25px auto 30px; }
  .dashboard-wrapper .applicants-list-wrapper ul {
    display: flex;
    justify-content: space-around;
    align-items: center; }
    .dashboard-wrapper .applicants-list-wrapper ul li {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      padding: 0 2px 12px;
      text-align: center;
      border-bottom: 2px solid transparent; }
      .dashboard-wrapper .applicants-list-wrapper ul li.active {
        border-bottom-color: #00cdbe; }
      .dashboard-wrapper .applicants-list-wrapper ul li span:first-child {
        font-size: 24px;
        line-height: 0.94;
        letter-spacing: normal;
        color: #001061;
        margin-bottom: 1px; }
      .dashboard-wrapper .applicants-list-wrapper ul li span:last-child {
        line-height: 1.04;
        letter-spacing: 0;
        color: #001061; }

.dashboard-wrapper .datatable-wrapper {
  margin-bottom: 25px; }
  .dashboard-wrapper .datatable-wrapper .ReactTable .rt-table {
    overflow-x: scroll; }
    .dashboard-wrapper .datatable-wrapper .ReactTable .rt-table::-webkit-scrollbar {
      height: 10px; }
    .dashboard-wrapper .datatable-wrapper .ReactTable .rt-table::-webkit-scrollbar-button {
      display: none; }
    .dashboard-wrapper .datatable-wrapper .ReactTable .rt-table::-webkit-scrollbar-track {
      background: transparent; }
    .dashboard-wrapper .datatable-wrapper .ReactTable .rt-table::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #001061; }
  .dashboard-wrapper .datatable-wrapper .ReactTable .rt-th {
    background-color: #001061;
    color: #fff;
    padding: 10px 0 !important;
    border-right: 1px solid #fff !important; }
    .dashboard-wrapper .datatable-wrapper .ReactTable .rt-th.-sort-asc::after {
      content: '\f0d7';
      position: absolute;
      right: 9px;
      top: 10px; }
    .dashboard-wrapper .datatable-wrapper .ReactTable .rt-th.-sort-desc::after {
      content: '\f0d8';
      position: absolute;
      right: 9px;
      top: 10px; }
  .dashboard-wrapper .datatable-wrapper .ReactTable .rt-tbody .rt-tr {
    cursor: pointer; }
    .dashboard-wrapper .datatable-wrapper .ReactTable .rt-tbody .rt-tr .rt-td {
      text-align: center;
      padding: 25px 0 !important;
      white-space: normal; }
      .dashboard-wrapper .datatable-wrapper .ReactTable .rt-tbody .rt-tr .rt-td .edit-icon {
        color: #5f5f5f;
        border-bottom: 1px solid #5f5f5f;
        padding-bottom: 2px; }
      .dashboard-wrapper .datatable-wrapper .ReactTable .rt-tbody .rt-tr .rt-td .search-icon {
        color: #5f5f5f; }
  .dashboard-wrapper .datatable-wrapper .ReactTable .rt-tr.-odd {
    background-color: #f0f0f0; }
  .dashboard-wrapper .datatable-wrapper .datatable-pagination {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-left: 1px solid rgba(0, 0, 0, 0.1); }
    .dashboard-wrapper .datatable-wrapper .datatable-pagination li {
      flex: 1 1;
      text-align: center; }
      .dashboard-wrapper .datatable-wrapper .datatable-pagination li button {
        width: 100%;
        padding: 10px;
        border: none; }

.header-wrapper .navbar {
  background-color: #fff;
  border: none;
  padding: 15px 0;
  margin-bottom: 0; }
  .header-wrapper .navbar .logo {
    display: inline-block;
    text-align: center;
    float: none;
    padding: 0;
    height: auto; }
    .header-wrapper .navbar .logo img {
      width: 60px;
      height: 40px;
      display: inline-block; }
    .header-wrapper .navbar .logo span {
      line-height: 1.21;
      letter-spacing: normal;
      text-align: center;
      color: #001061;
      display: block; }
  .header-wrapper .navbar .navbar-collapse .navbar-nav {
    margin: 15px 0; }
    .header-wrapper .navbar .navbar-collapse .navbar-nav > li {
      margin: 0 20px; }
      .header-wrapper .navbar .navbar-collapse .navbar-nav > li.fixed {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1001; }
      .header-wrapper .navbar .navbar-collapse .navbar-nav > li a,
      .header-wrapper .navbar .navbar-collapse .navbar-nav > li span {
        font-size: 18px;
        line-height: 1.21;
        letter-spacing: 0;
        color: #001061;
        padding: 0; }
      .header-wrapper .navbar .navbar-collapse .navbar-nav > li.dropdown {
        width: auto; }
        .header-wrapper .navbar .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu {
          padding: 10px 15px;
          border-radius: 5px; }
          .header-wrapper .navbar .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu li a {
            padding: 0 10px; }
          .header-wrapper .navbar .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu li:first-child > a {
            background: transparent; }
            .header-wrapper .navbar .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu li:first-child > a:hover, .header-wrapper .navbar .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu li:first-child > a:focus {
              background: transparent; }
    .header-wrapper .navbar .navbar-collapse .navbar-nav > .open > a {
      background: transparent; }
      .header-wrapper .navbar .navbar-collapse .navbar-nav > .open > a:hover, .header-wrapper .navbar .navbar-collapse .navbar-nav > .open > a:focus {
        background: transparent; }

.searchWrapper {
  display: flex; }
  .searchWrapper .search-icon {
    color: #fff;
    background-color: #00cdbe;
    cursor: pointer;
    margin-left: 1px;
    padding: 9px 15px 13px;
    display: inline-block; }
  .searchWrapper .header-type-search {
    width: 300px;
    border: 1px solid #bcbcbc;
    padding: 10px 12px 12px;
    line-height: 1.19;
    letter-spacing: 0;
    color: #000; }
  .searchWrapper .header-select-search {
    width: 125px; }
    .searchWrapper .header-select-search > div {
      border-radius: 0;
      border: 1px solid #bcbcbc;
      padding: 3px 0 2px;
      box-shadow: none; }
    .searchWrapper .header-select-search input:not([type="hidden"]) {
      line-height: 1.19;
      letter-spacing: 0;
      text-align: left;
      color: #000; }
    .searchWrapper .header-select-search .select__indicators > span {
      display: none; }
    .searchWrapper .header-select-search .select__menu {
      font-size: 14px; }
    .searchWrapper .header-select-search .select__placeholder {
      color: #bcbcbc;
      line-height: 1.19;
      letter-spacing: 0;
      text-align: left; }
  .searchWrapper .header-type-search::-webkit-input-placeholder, .searchWrapper .header-select-search input::-webkit-input-placeholder {
    color: #bcbcbc; }
  .searchWrapper .header-type-search:-ms-input-placeholder, .searchWrapper .header-select-search input:-ms-input-placeholder {
    color: #bcbcbc; }
  .searchWrapper .header-type-search::-ms-input-placeholder, .searchWrapper .header-select-search input::-ms-input-placeholder {
    color: #bcbcbc; }
  .searchWrapper .header-type-search::placeholder,
  .searchWrapper .header-select-search input::placeholder {
    color: #bcbcbc; }

@media (min-width: 768px) {
  .header-wrapper .navbar .navbar-collapse .navbar-nav {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .header-wrapper .navbar .navbar-collapse .navbar-nav li:first-child {
      margin-left: 0; }
    .header-wrapper .navbar .navbar-collapse .navbar-nav li:last-child {
      margin-right: 0; } }

@media (max-width: 768px) {
  .header-wrapper .navbar {
    margin-bottom: 0; }
    .header-wrapper .navbar .navbar-header .logo {
      display: none; } }

.calculateEMI_wrapper {
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(211, 211, 211, 0.2);
  border-radius: 15px;
  margin-left: 10%;
  margin-right: 10%; }
  .calculateEMI_wrapper .topBlueContainer {
    padding: 3.5rem;
    text-align: start;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px; }
    .calculateEMI_wrapper .topBlueContainer .heading_EMI {
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 33px;
      color: #ffffff; }
    .calculateEMI_wrapper .topBlueContainer .subHeadings-EMI_wrapper {
      display: flex; }
      .calculateEMI_wrapper .topBlueContainer .subHeadings-EMI_wrapper .EMI_subHeading_container {
        display: flex;
        margin-top: 2rem;
        margin-right: 5rem; }
        .calculateEMI_wrapper .topBlueContainer .subHeadings-EMI_wrapper .EMI_subHeading_container .EMI_icon_image {
          max-width: 28%;
          margin-right: 1rem; }
        .calculateEMI_wrapper .topBlueContainer .subHeadings-EMI_wrapper .EMI_subHeading_container .subHeading_EMI {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 130%;
          color: #ffffff; }
        .calculateEMI_wrapper .topBlueContainer .subHeadings-EMI_wrapper .EMI_subHeading_container .subHeadings-data {
          font-style: normal;
          font-weight: normal;
          font-size: 22px;
          line-height: 130%;
          color: #ffffff; }
  .calculateEMI_wrapper .bottomContainer {
    display: flex;
    justify-content: space-around;
    padding: 2rem;
    flex-wrap: wrap; }
    .calculateEMI_wrapper .bottomContainer .leftSideContainer {
      text-align: start;
      border-right: #f4f4f4 solid 2px;
      padding-bottom: 3rem; }
      .calculateEMI_wrapper .bottomContainer .leftSideContainer .heading-parameters {
        font-style: normal;
        font-weight: normal;
        font-size: 21px;
        line-height: 25px;
        color: #00cdbe;
        margin-top: 2rem; }
      .calculateEMI_wrapper .bottomContainer .leftSideContainer .label-parameters {
        font-style: normal;
        font-weight: 500;
        line-height: 180%;
        color: #434343;
        margin-top: 2rem; }
      .calculateEMI_wrapper .bottomContainer .leftSideContainer .selectDrag_wrapper {
        display: flex;
        margin-top: -1.5rem; }
        .calculateEMI_wrapper .bottomContainer .leftSideContainer .selectDrag_wrapper .input-range {
          width: 40rem; }
        .calculateEMI_wrapper .bottomContainer .leftSideContainer .selectDrag_wrapper .input-range__label-container {
          color: #8c8c8c;
          font-size: 1.4rem; }
        .calculateEMI_wrapper .bottomContainer .leftSideContainer .selectDrag_wrapper .input-generic__dotted {
          margin-left: 2rem; }
        .calculateEMI_wrapper .bottomContainer .leftSideContainer .selectDrag_wrapper.loan-amount .input-range__label--max {
          transform: translateX(-65px); }
    .calculateEMI_wrapper .bottomContainer .rightSideContainer .pmt-graph {
      margin: 4rem auto;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      font-weight: 500; }
      .calculateEMI_wrapper .bottomContainer .rightSideContainer .pmt-graph .monthlyInstallment {
        position: absolute;
        top: 34%;
        left: 35%; }
        .calculateEMI_wrapper .bottomContainer .rightSideContainer .pmt-graph .monthlyInstallment .emi-amount {
          font-weight: bold;
          font-size: 24px;
          color: #00cdbe; }
        .calculateEMI_wrapper .bottomContainer .rightSideContainer .pmt-graph .monthlyInstallment .emi-label {
          color: #434343;
          font-size: 1.6rem; }
      .calculateEMI_wrapper .bottomContainer .rightSideContainer .pmt-graph .amount-line {
        -webkit-clip-path: polygon(150% 0%, 158% 0%, 30% 96%, 0% 97%, 0% 91%, 29% 91%);
                clip-path: polygon(150% 0%, 158% 0%, 30% 96%, 0% 97%, 0% 91%, 29% 91%);
        position: absolute;
        width: 10rem;
        height: 5rem; }
        .calculateEMI_wrapper .bottomContainer .rightSideContainer .pmt-graph .amount-line.principal {
          bottom: 25%;
          left: 24%;
          background-color: #3670cc; }
        .calculateEMI_wrapper .bottomContainer .rightSideContainer .pmt-graph .amount-line.interest {
          transform: rotate(180deg);
          background-color: #001061;
          top: 6%;
          right: 27%; }
      .calculateEMI_wrapper .bottomContainer .rightSideContainer .pmt-graph .principal-amount__wrapper {
        position: absolute;
        bottom: 18%;
        left: 1%;
        color: #434343; }
        .calculateEMI_wrapper .bottomContainer .rightSideContainer .pmt-graph .principal-amount__wrapper .principal-amount {
          color: #3670cc;
          font-weight: 700; }
      .calculateEMI_wrapper .bottomContainer .rightSideContainer .pmt-graph .interest-amount__wrapper {
        position: absolute;
        top: 4%;
        right: 4%;
        color: #000000; }
        .calculateEMI_wrapper .bottomContainer .rightSideContainer .pmt-graph .interest-amount__wrapper .interest-amount {
          color: #001061;
          font-weight: 700; }
      .calculateEMI_wrapper .bottomContainer .rightSideContainer .pmt-graph .CircularProgressbar {
        width: 90%; }
        .calculateEMI_wrapper .bottomContainer .rightSideContainer .pmt-graph .CircularProgressbar .CircularProgressbar-trail {
          border: solid white 5px; }
        .calculateEMI_wrapper .bottomContainer .rightSideContainer .pmt-graph .CircularProgressbar .CircularProgressbar-path {
          border: solid white 5px; }
    .calculateEMI_wrapper .bottomContainer .rightSideContainer .buttonGeneric_Blue {
      padding: 1.5rem;
      width: auto;
      height: auto; }

@media only screen and (max-width: 500px) {
  .calculateEMI_wrapper {
    margin-left: 1rem;
    margin-right: 1rem; }
    .calculateEMI_wrapper .CircularProgressbar {
      width: 50% !important; }
    .calculateEMI_wrapper .monthlyInstallment {
      width: -webkit-min-content;
      width: -moz-min-content;
      width: min-content;
      margin-left: 10.2rem; }
    .calculateEMI_wrapper .buttonGeneric_Blue {
      margin-top: 11rem !important; } }

.loan-info-box__wrapper .loan-info-box__shadow {
  box-shadow: 0px 15px 50px rgba(158, 158, 158, 0.2) !important; }

.loan-info-box__wrapper .dotted-patch {
  height: 295px;
  z-index: -1;
  position: absolute !important;
  bottom: -5rem;
  right: -12rem; }

.loan-info-box__wrapper .secondary-button__custom {
  padding: 1.5rem 4rem;
  border-radius: 0.5rem;
  background-color: #00cdbe;
  color: #fff;
  border: none;
  outline: none; }

.loan-info-box__wrapper .loan-info__wrapper {
  display: flex;
  justify-content: space-evenly;
  padding: 3rem;
  padding-bottom: 0rem;
  flex-wrap: wrap; }
  .loan-info-box__wrapper .loan-info__wrapper .loan-info__items {
    margin: 2rem;
    margin-bottom: 0.1  rem; }
    .loan-info-box__wrapper .loan-info__wrapper .loan-info__items .loan-info-icon {
      height: 50%;
      padding-bottom: 2rem; }
    .loan-info-box__wrapper .loan-info__wrapper .loan-info__items .loan-info-title {
      color: #434343;
      font-size: 14px;
      margin-bottom: 0.5rem; }
    .loan-info-box__wrapper .loan-info__wrapper .loan-info__items .loan-info-details {
      font-size: 21px;
      color: #001061; }

.loan-info-box__wrapper .curved-borders {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px; }

.SME_bottom_container {
  margin-top: 4rem; }
  .SME_bottom_container .label_avoidRisk {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #07cec0;
    margin-bottom: 2rem; }
  .SME_bottom_container .SME_bottom_card_wrapper {
    display: flex;
    justify-content: flex-start;
    padding: 2rem;
    flex-wrap: wrap; }
    .SME_bottom_container .SME_bottom_card_wrapper .SME_bottom_subcard_wrapper {
      background: #ffffff;
      box-shadow: 0px 5px 20px rgba(211, 211, 211, 0.16);
      border-radius: 10px;
      width: 335px;
      padding: 2rem;
      margin: 10px;
      text-align: left; }
      .SME_bottom_container .SME_bottom_card_wrapper .SME_bottom_subcard_wrapper .label_loanProductTopics {
        font-weight: 500;
        font-size: 18px;
        color: #001061;
        margin-bottom: 1rem; }
      .SME_bottom_container .SME_bottom_card_wrapper .SME_bottom_subcard_wrapper .loanProducts_details {
        font-size: 14px;
        margin-bottom: 1.5rem;
        color: #434343; }
  .SME_bottom_container .button_Blue {
    padding: 1rem 1rem;
    border-radius: 10px;
    background: #3670CC;
    color: #fff;
    border: none;
    outline: none; }

.coming-soon__wrapper {
  background-image: url(/static/media/ComingSoon.5419b9f2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  background-position: center; }
  .coming-soon__wrapper .overlay {
    opacity: 0.6;
    background-color: #000;
    color: #fff;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
    .coming-soon__wrapper .overlay .heading1 {
      font-size: 6rem; }
    .coming-soon__wrapper .overlay .text {
      max-width: 40%;
      font-size: 3rem;
      text-align: center; }

.moratorium-calculator__wrapper {
  width: 100%; }
  .moratorium-calculator__wrapper .header {
    border-top: 5rem solid #001061;
    padding: 2rem; }
    .moratorium-calculator__wrapper .header .heading {
      color: #3670CC;
      font-size: 2.4rem; }
    .moratorium-calculator__wrapper .header .reference img {
      width: 30px;
      margin: 0 .5rem; }
  .moratorium-calculator__wrapper .main__section {
    padding: 1rem; }
    .moratorium-calculator__wrapper .main__section .card__wrapper {
      background: #FFFFFF;
      box-shadow: 0px 5px 20px rgba(114, 114, 114, 0.16);
      border-radius: 10px;
      padding: 2rem;
      margin-bottom: 2rem; }
      .moratorium-calculator__wrapper .main__section .card__wrapper .heading {
        color: #00cdbe;
        font-size: 1.8rem;
        border-bottom: 1px solid #EEF3FA;
        padding-bottom: .5rem; }
      .moratorium-calculator__wrapper .main__section .card__wrapper .translate div.input-range .input-range__label.input-range__label--max {
        transform: translateX(-40px) !important; }
      .moratorium-calculator__wrapper .main__section .card__wrapper .button__container {
        margin: 2rem 0;
        border: 1px solid #EEF3FA;
        max-width: -webkit-max-content;
        max-width: -moz-max-content;
        max-width: max-content; }
        .moratorium-calculator__wrapper .main__section .card__wrapper .button__container .active-tab {
          color: #00cdbe;
          background: #EEF3FA;
          padding: .5rem 1rem; }
        .moratorium-calculator__wrapper .main__section .card__wrapper .button__container .tab {
          color: #8C8C8C;
          background-color: transparent;
          padding: .5rem 1rem; }
        .moratorium-calculator__wrapper .main__section .card__wrapper .button__container .button__generic {
          padding: 1rem;
          margin: 0;
          border-radius: 0;
          cursor: pointer; }
        .moratorium-calculator__wrapper .main__section .card__wrapper .button__container .grey-button {
          padding: 1rem;
          border-radius: 0.5rem;
          color: #000;
          border: none;
          max-width: -webkit-max-content;
          max-width: -moz-max-content;
          max-width: max-content;
          cursor: pointer; }
      .moratorium-calculator__wrapper .main__section .card__wrapper .f-12 {
        font-size: 1.2rem; }
      .moratorium-calculator__wrapper .main__section .card__wrapper .f-14 {
        font-size: 1.4rem !important; }
      .moratorium-calculator__wrapper .main__section .card__wrapper .blue_value {
        color: #3670CC;
        font-size: 3rem;
        font-weight: bold; }
      .moratorium-calculator__wrapper .main__section .card__wrapper .black-heading {
        color: #434343;
        font-weight: bold; }
      .moratorium-calculator__wrapper .main__section .card__wrapper .grey__text {
        color: #AEAEAE;
        font-size: 1.2rem; }
  .moratorium-calculator__wrapper .footer__wrapper {
    background-color: #001061;
    color: #fff;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }

@media only screen and (min-width: 600px) {
  .moratorium-calculator__wrapper {
    max-width: 50%;
    margin: 0 auto; } }

.SME__wrapper .horizontal-space {
  margin: 110px; }

.SME__wrapper .fs-16.width-80 {
  max-width: 80%;
  margin: 0px auto; }

.SME__wrapper .header {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%; }

.SME__wrapper .card__wrapper {
  background: #ffffff;
  box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 100%;
  padding: 1rem 2rem;
  position: relative; }
  .SME__wrapper .card__wrapper.no-gutter {
    padding: 0px; }

.SME__wrapper .position-relative {
  position: relative; }

.SME__wrapper .center {
  align-content: center;
  text-align: center; }
  .SME__wrapper .center.negative-margin {
    position: relative;
    margin-bottom: -33rem; }

.SME__wrapper h3 {
  color: #00cdbe; }

.SME__wrapper .about__wrapper {
  max-width: 80%;
  margin: auto; }
  .SME__wrapper .about__wrapper .about-title {
    font-weight: 500;
    font-size: 32px;
    color: #001061; }
  .SME__wrapper .about__wrapper .about-details {
    font-weight: normal;
    color: #434343; }
    .SME__wrapper .about__wrapper .about-details .highlight-blue {
      color: #07cec0; }

.SME__wrapper .why-loan__wrapper {
  background: #a4a1fb15;
  box-shadow: 0px 0px 10px rgba(191, 191, 191, 0.16); }
  .SME__wrapper .why-loan__wrapper.extra-padding {
    padding: 25rem 0rem 10rem 0rem; }
  .SME__wrapper .why-loan__wrapper .SME_card_container .heading_card_wrapper {
    font-size: 28px;
    color: #001061;
    padding-top: 2rem; }
  .SME__wrapper .why-loan__wrapper .SME_card_container .sub-heading_card_wrapper {
    font-size: 18px;
    margin-top: 1rem;
    color: #434343; }
  .SME__wrapper .why-loan__wrapper .SME_card_container .SME_card_wrapper {
    display: flex;
    margin: 1.3rem;
    flex-wrap: wrap; }
    .SME__wrapper .why-loan__wrapper .SME_card_container .SME_card_wrapper .SME_subCard_wrapper {
      background: #ffffff;
      border-radius: 10px;
      border-right: solid 10px #b3e4f8;
      padding: 2rem;
      margin: 10px 5px;
      width: 350px;
      text-align: left; }
      .SME__wrapper .why-loan__wrapper .SME_card_container .SME_card_wrapper .SME_subCard_wrapper .title {
        font-weight: normal;
        font-size: 20px;
        text-align: left;
        color: #00cdbe;
        margin-bottom: 1.2rem; }

.SME__wrapper .grey-background {
  background: #fafafa;
  margin: 0px;
  padding: 1.5rem; }

.grey-bg {
  position: fixed;
  background: #000000ad;
  z-index: 10005;
  width: 100%;
  height: 100vh; }
  .grey-bg.no-display {
    display: none; }
  .grey-bg .know-more__wrapper {
    background: linear-gradient(69.21deg, #011161 -0.01%, #00cdbe 100%);
    border-radius: 10px;
    width: 70%;
    margin: 0 auto;
    padding: 3rem 5rem 8rem 5rem;
    text-align: center;
    color: white;
    position: absolute;
    z-index: 1500;
    box-shadow: 0px 5px 30px rgba(148, 148, 148, 0.16);
    top: 5%;
    left: 14%; }
    .grey-bg .know-more__wrapper .cross-btn {
      position: absolute;
      right: 1rem;
      top: 1rem;
      cursor: pointer; }
    .grey-bg .know-more__wrapper .biz360-engine {
      padding: 3.5rem; }
    .grey-bg .know-more__wrapper .text__generic {
      max-width: 80%;
      margin: 0 auto;
      margin-bottom: 2rem; }
    .grey-bg .know-more__wrapper .biz360steps {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%; }
      .grey-bg .know-more__wrapper .biz360steps .steps {
        display: flex;
        width: 30%; }
        .grey-bg .know-more__wrapper .biz360steps .steps img {
          height: -webkit-fit-content;
          height: -moz-fit-content;
          height: fit-content; }
        .grey-bg .know-more__wrapper .biz360steps .steps .text {
          text-align: left;
          margin-left: 1rem; }
          .grey-bg .know-more__wrapper .biz360steps .steps .text .head {
            font-size: 0.9rem;
            width: 60%; }
          .grey-bg .know-more__wrapper .biz360steps .steps .text .label {
            font-size: 1.4rem;
            width: 70%; }

@media only screen and (max-width: 500px) {
  .grey-bg {
    width: 100%;
    height: 100vh; }
    .grey-bg .know-more__wrapper {
      width: 95%;
      height: 85%;
      padding: 3rem 2rem 3rem 2rem;
      top: 5%;
      left: 2%;
      overflow-y: auto; }
      .grey-bg .know-more__wrapper .biz360-engine {
        padding: 0.2rem;
        margin-bottom: 3rem; }
      .grey-bg .know-more__wrapper .text__generic {
        max-width: 95%; }
      .grey-bg .know-more__wrapper .biz360steps {
        display: block;
        justify-content: center;
        width: 65%;
        text-align: center;
        margin: 0px auto; }
        .grey-bg .know-more__wrapper .biz360steps .steps {
          display: flex;
          width: 100%;
          text-align: center;
          margin: 2rem 2rem; }
          .grey-bg .know-more__wrapper .biz360steps .steps .text {
            text-align: left;
            margin-left: 1rem; }
            .grey-bg .know-more__wrapper .biz360steps .steps .text .head {
              font-size: 1.2rem;
              width: 60%; }
            .grey-bg .know-more__wrapper .biz360steps .steps .text .label {
              font-size: 1.5rem;
              width: 80%; } }

.advisory__wrapper {
  background-color: #001061;
  min-height: 100vh;
  color: #fff;
  margin-right: 8%;
  padding: 10%; }
  .advisory__wrapper .logo {
    display: flex;
    justify-content: center; }
    .advisory__wrapper .logo .logo-image {
      max-width: 70%; }
  .advisory__wrapper .main__content {
    margin-top: 15%; }
    .advisory__wrapper .main__content h1 {
      text-align: center; }
    .advisory__wrapper .main__content .advisory {
      margin-top: 3rem; }
      .advisory__wrapper .main__content .advisory .advisory-clip {
        height: 20px;
        margin-right: 1rem;
        margin-bottom: .5rem; }

.gst-username__wrapper .button__share-link {
  color: #00cdbe;
  border: 1px solid #00cdbe;
  padding: 0.5rem 0.75rem;
  background: transparent;
  border-radius: 0.5rem;
  margin: 0.5rem 0; }

.preview__wrapper .card__wrapper {
  position: relative; }
  .preview__wrapper .card__wrapper .header {
    border-bottom: 2px solid #00cdbe;
    padding-bottom: 1rem;
    margin-bottom: 1rem; }
  .preview__wrapper .card__wrapper .heading {
    color: #001061; }
  .preview__wrapper .card__wrapper .label {
    color: #343434;
    font-weight: 800; }
  .preview__wrapper .card__wrapper .value {
    color: #343434; }
    .preview__wrapper .card__wrapper .value .address__wrapper-gst {
      line-height: 1.1em; }
      .preview__wrapper .card__wrapper .value .address__wrapper-gst .green-small-text {
        color: #00cdbe;
        font-size: 1.4rem; }
  .preview__wrapper .card__wrapper .edit__button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: 1px solid #00cdbe;
    background: #F5F5FB;
    color: #00cdbe;
    padding: .5rem 1.5rem;
    border-radius: .5rem;
    cursor: pointer; }
  .preview__wrapper .card__wrapper .row {
    margin-top: 2rem; }
  .preview__wrapper .card__wrapper .loan-tenure__wrapper > input {
    width: 35px;
    font-size: 1.2rem; }
  .preview__wrapper .card__wrapper .edit__address-icon {
    color: #00cdbe;
    cursor: pointer;
    margin-left: 6rem; }

.document-upload__wrapper h2 {
  color: #001061;
  margin-top: 3rem;
  font-size: 2.5rem; }

.document-upload__wrapper .card__wrapper {
  width: 90%; }
  .document-upload__wrapper .card__wrapper h3 {
    color: #505050;
    margin: 1rem 0 2rem 0; }
  .document-upload__wrapper .card__wrapper .filenames__box {
    display: flex;
    justify-content: start;
    flex-wrap: wrap; }
    .document-upload__wrapper .card__wrapper .filenames__box .file-name__wrapper {
      display: inline-block;
      border-radius: 100rem;
      border: 1px solid #ff0000;
      padding: .5rem 1rem;
      margin: 1rem .5rem 0 0; }
      .document-upload__wrapper .card__wrapper .filenames__box .file-name__wrapper svg {
        fill: #ff0000;
        margin-right: .5rem; }

.chatbot {
  position: fixed;
  bottom: 25%;
  right: 2.2%;
  z-index: 999; }

.ads-wrapper * {
  font-family: "Open Sans", Roboto !important; }

.ads-wrapper .fadeOut {
  opacity: 0;
  width: 0;
  height: 0;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s; }

.ads-wrapper .fadeIn {
  opacity: 1;
  width: 100px;
  height: 100px;
  transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s; }

.ads-wrapper .background-image {
  height: 100%;
  background: linear-gradient(75.66deg, #001061 1.43%, #00cdbe 100%); }
  .ads-wrapper .background-image .brand_nav {
    padding: 0.5% 10% 0.5% 10%; }
    .ads-wrapper .background-image .brand_nav .brand_container {
      width: 8%; }
  .ads-wrapper .background-image .menu-items {
    color: #ffffff;
    font-weight: 400;
    padding: 10px;
    margin-left: 3.5rem; }
    .ads-wrapper .background-image .menu-items.number {
      color: #212121;
      background: #ffffff;
      border-radius: 5px; }
      .ads-wrapper .background-image .menu-items.number:active {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); }
    .ads-wrapper .background-image .menu-items.number-header {
      display: none; }
    .ads-wrapper .background-image .menu-items .phone {
      color: #00c6bb; }
  .ads-wrapper .background-image .form-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    .ads-wrapper .background-image .form-wrapper .image-wrapper {
      padding: 7rem;
      position: relative; }
      .ads-wrapper .background-image .form-wrapper .image-wrapper .shop-owner {
        position: absolute;
        z-index: 900;
        top: 9%;
        right: 13%; }
      .ads-wrapper .background-image .form-wrapper .image-wrapper .headline {
        background: transparent;
        width: 45%;
        font-size: 36px;
        color: white;
        position: absolute;
        top: 31.5%;
        left: 11%;
        padding: 1.2rem 2rem; }
        .ads-wrapper .background-image .form-wrapper .image-wrapper .headline .b {
          font-size: 36px; }
    .ads-wrapper .background-image .form-wrapper .lead-form__wrapper {
      padding: 5rem; }
      .ads-wrapper .background-image .form-wrapper .lead-form__wrapper .lead-form {
        background-color: #ffffff;
        padding: 3rem 2.5rem;
        border-radius: 10px;
        box-shadow: 0px 10px 80px rgba(0, 0, 0, 0.3); }
        .ads-wrapper .background-image .form-wrapper .lead-form__wrapper .lead-form .heading {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between; }
          .ads-wrapper .background-image .form-wrapper .lead-form__wrapper .lead-form .heading .title {
            font-style: normal;
            font-size: 24px; }
          .ads-wrapper .background-image .form-wrapper .lead-form__wrapper .lead-form .heading .language {
            color: #00c1b8; }
            .ads-wrapper .background-image .form-wrapper .lead-form__wrapper .lead-form .heading .language .lang {
              border: 1px solid #d9d9d9;
              border-radius: 100px;
              padding: 0.5rem;
              padding-left: 1.5rem;
              padding-right: 1.5rem;
              margin: 2rem 0rem 2rem 2rem; }
        .ads-wrapper .background-image .form-wrapper .lead-form__wrapper .lead-form .trust {
          border: 1px solid #00c6bb;
          border-radius: 100px;
          padding-left: 2.5rem;
          padding-right: 1.5rem;
          color: #00c6bb; }
        .ads-wrapper .background-image .form-wrapper .lead-form__wrapper .lead-form .pros {
          position: relative; }
          .ads-wrapper .background-image .form-wrapper .lead-form__wrapper .lead-form .pros .check {
            color: #00c6bb;
            position: absolute;
            top: -6%;
            left: -0.2%; }
      .ads-wrapper .background-image .form-wrapper .lead-form__wrapper .form-content ::-webkit-input-placeholder {
        font-size: 14px; }
      .ads-wrapper .background-image .form-wrapper .lead-form__wrapper .form-content :-ms-input-placeholder {
        font-size: 14px; }
      .ads-wrapper .background-image .form-wrapper .lead-form__wrapper .form-content ::-ms-input-placeholder {
        font-size: 14px; }
      .ads-wrapper .background-image .form-wrapper .lead-form__wrapper .form-content ::placeholder {
        font-size: 14px; }
      .ads-wrapper .background-image .form-wrapper .lead-form__wrapper .form-content .form-title {
        font-weight: 600;
        font-size: 14px;
        color: #000000; }
      .ads-wrapper .background-image .form-wrapper .lead-form__wrapper .form-content .form-fields .row {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start; }
        .ads-wrapper .background-image .form-wrapper .lead-form__wrapper .form-content .form-fields .row .availed-loan {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-evenly;
          align-items: center; }
          .ads-wrapper .background-image .form-wrapper .lead-form__wrapper .form-content .form-fields .row .availed-loan .text {
            font-size: 1.4rem; }
        .ads-wrapper .background-image .form-wrapper .lead-form__wrapper .form-content .form-fields .row .form-field {
          border: 1px solid #e5e5e5;
          border-radius: 10px;
          margin: 1.4rem 1.8rem 0rem 0rem;
          padding: 1.2rem;
          width: 46%; }
        .ads-wrapper .background-image .form-wrapper .lead-form__wrapper .form-content .form-fields .row .css-2b097c-container {
          width: 46%; }
        .ads-wrapper .background-image .form-wrapper .lead-form__wrapper .form-content .form-fields .row .form-select-field {
          margin: 1.4rem 1.8rem 0rem 0rem; }
          .ads-wrapper .background-image .form-wrapper .lead-form__wrapper .form-content .form-fields .row .form-select-field .css-yk16xz-control {
            padding: 0.8rem 1.2rem;
            border-radius: 10px;
            border: 1px solid #e5e5e5; }
          .ads-wrapper .background-image .form-wrapper .lead-form__wrapper .form-content .form-fields .row .form-select-field .css-1hwfws3 {
            padding: 2px 0px; }
          .ads-wrapper .background-image .form-wrapper .lead-form__wrapper .form-content .form-fields .row .form-select-field .css-1wa3eu0-placeholder {
            font-size: 14px; }
          .ads-wrapper .background-image .form-wrapper .lead-form__wrapper .form-content .form-fields .row .form-select-field .css-1okebmr-indicatorSeparator {
            display: none; }
          .ads-wrapper .background-image .form-wrapper .lead-form__wrapper .form-content .form-fields .row .form-select-field .css-1pahdxg-control {
            padding: 0.8rem 1.2rem;
            border-radius: 10px;
            border: 1px solid #e5e5e5; }
      .ads-wrapper .background-image .form-wrapper .lead-form__wrapper .form-content .form-fields .whatsapp-checkbox .green {
        color: #00cdbe; }
      .ads-wrapper .background-image .form-wrapper .lead-form__wrapper .form-content .form-fields .get-loan-btn {
        background: #001061;
        border-radius: 10px;
        width: 100%;
        padding: 1.6rem 0rem;
        color: #ffffff;
        font-weight: 500;
        font-size: 24px; }

.ads-wrapper .why-credochain__wrapper .heading {
  font-weight: 900;
  font-size: 3rem;
  color: #001061; }
  .ads-wrapper .why-credochain__wrapper .heading.mb5 {
    margin-bottom: 5rem; }

.ads-wrapper .why-credochain__wrapper .benefits {
  flex-wrap: wrap;
  justify-content: space-around;
  width: 90%;
  margin-top: 5rem; }
  .ads-wrapper .why-credochain__wrapper .benefits .benefit {
    justify-content: center;
    flex-wrap: wrap;
    width: 20% !important; }
    .ads-wrapper .why-credochain__wrapper .benefits .benefit .text {
      width: 58%;
      margin-left: 1.5rem;
      font-weight: 500;
      font-size: 1.8rem;
      align-self: center; }

.ads-wrapper .testimonies {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; }

.homepage__wrapper .card-wrapper .step-number__mobile {
  display: none; }

.get-loan {
  background: #e5f8f7;
  box-shadow: 0px -10px 20px rgba(166, 166, 166, 0.13);
  padding: 1.5rem 3rem;
  text-align: -webkit-center;
  color: #ffffff;
  font-weight: 500;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0; }
  .get-loan .get-loan-btn {
    padding: 1.5rem 3rem;
    background: #1b3e92;
    border-radius: 30px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }

.ads-thankyou__wrapper {
  height: 100vh; }
  .ads-thankyou__wrapper .content-wrapper {
    margin: 0 auto;
    text-align: -webkit-center;
    padding-top: 14%; }
    .ads-thankyou__wrapper .content-wrapper .content {
      font-size: 4rem; }
      .ads-thankyou__wrapper .content-wrapper .content .sub-content {
        font-size: 3rem; }
    .ads-thankyou__wrapper .content-wrapper .callback {
      font-size: 2.5rem; }

@media only screen and (max-width: 1306px) {
  .ads-wrapper .why-credochain__wrapper .benefits .benefit {
    width: 17%; }
  .ads-wrapper .background-image .form-wrapper .image-wrapper {
    padding: 4rem 4rem 1rem 4rem; }
    .ads-wrapper .background-image .form-wrapper .image-wrapper .image,
    .ads-wrapper .background-image .form-wrapper .image-wrapper .shop-owner {
      display: none; }
    .ads-wrapper .background-image .form-wrapper .image-wrapper .headline {
      background-color: transparent;
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content;
      position: relative;
      top: auto;
      left: auto; }
  .ads-wrapper .homepage__wrapper .credochain-works__wrapper .card-credochain-works__wrapper {
    justify-content: flex-start;
    max-width: 96%; }
    .ads-wrapper .homepage__wrapper .credochain-works__wrapper .card-credochain-works__wrapper .card-wrapper {
      margin-left: 2rem; }
  .ads-wrapper .why-credochain__wrapper .testimonies {
    width: 100%; }
  .chatbot {
    z-index: 1000;
    bottom: 20%;
    right: 5%; } }

@media only screen and (max-width: 700px) {
  .ads-wrapper .background-image .form-wrapper {
    padding-top: 3rem;
    background-image: linear-gradient(75.66deg, rgba(0, 16, 97, 0.8) 1.43%, rgba(0, 205, 190, 0.8) 100%), url(/static/media/storeowner.ae3d8901.png);
    background-size: contain;
    height: 80vh; }
    .ads-wrapper .background-image .form-wrapper .lead-form__wrapper {
      padding: 1rem 0.5rem 3rem 0.5rem; }
      .ads-wrapper .background-image .form-wrapper .lead-form__wrapper .lead-form {
        padding: 1.5rem 1rem 4rem 1rem;
        box-shadow: 0px -7px 30px rgba(0, 0, 0, 0.2); }
        .ads-wrapper .background-image .form-wrapper .lead-form__wrapper .lead-form .pros {
          display: none; }
        .ads-wrapper .background-image .form-wrapper .lead-form__wrapper .lead-form .form-content .form-fields .row .form-field {
          width: 100%;
          margin-right: 0.5rem; }
        .ads-wrapper .background-image .form-wrapper .lead-form__wrapper .lead-form .form-content .form-fields .row .css-2b097c-container {
          width: 100%;
          margin-right: 0.5rem; }
        .ads-wrapper .background-image .form-wrapper .lead-form__wrapper .lead-form .form-content .form-fields .row .availed-loan {
          display: flex; }
          .ads-wrapper .background-image .form-wrapper .lead-form__wrapper .lead-form .form-content .form-fields .row .availed-loan .text {
            margin-left: 1rem;
            width: 17.5rem; }
        .ads-wrapper .background-image .form-wrapper .lead-form__wrapper .lead-form .form-content .form-fields .get-loan-btn {
          padding: 0.5rem; }
        .ads-wrapper .background-image .form-wrapper .lead-form__wrapper .lead-form .heading {
          position: relative; }
          .ads-wrapper .background-image .form-wrapper .lead-form__wrapper .lead-form .heading .title {
            padding-right: 1rem;
            font-size: 20px;
            margin-bottom: 3rem; }
          .ads-wrapper .background-image .form-wrapper .lead-form__wrapper .lead-form .heading .language {
            position: absolute;
            bottom: 70%;
            right: 1%; }
    .ads-wrapper .background-image .form-wrapper .image-wrapper {
      padding: 0.5rem;
      padding-top: 2rem;
      align-self: center;
      top: auto;
      position: relative; }
      .ads-wrapper .background-image .form-wrapper .image-wrapper .headline {
        width: 68%;
        font-size: 2.8rem;
        padding: 2.5rem 1rem 1rem 1rem; }
        .ads-wrapper .background-image .form-wrapper .image-wrapper .headline .b {
          font-size: 2.6rem; }
  .ads-wrapper .background-image .brand_nav {
    background: #001061;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
    .ads-wrapper .background-image .brand_nav .navbar-collapse {
      background: #001061; }
      .ads-wrapper .background-image .brand_nav .navbar-collapse .number {
        display: none; }
    .ads-wrapper .background-image .brand_nav .number-header {
      display: block;
      padding: 0.5rem 1rem; }
  .ads-wrapper .why-credochain__wrapper {
    margin-top: 51rem;
    position: relative; }
    .ads-wrapper .why-credochain__wrapper .heading {
      text-align: left;
      padding-left: 3rem;
      font-size: 3.3rem !important;
      width: 70%; }
    .ads-wrapper .why-credochain__wrapper .simple-steps {
      display: block;
      position: absolute;
      top: 4%;
      right: 8%; }
    .ads-wrapper .why-credochain__wrapper.mt7 {
      margin-top: 4rem;
      padding-top: 1rem; }
    .ads-wrapper .why-credochain__wrapper .tc {
      text-align: left;
      padding-left: 3rem !important;
      font-size: 3.3rem !important; }
    .ads-wrapper .why-credochain__wrapper .benefits {
      width: 100%; }
      .ads-wrapper .why-credochain__wrapper .benefits .benefit {
        width: 100% !important;
        width: 100%;
        padding: 2rem 2rem 2rem 3rem;
        justify-content: left; }
        .ads-wrapper .why-credochain__wrapper .benefits .benefit .text {
          font-weight: bold; }
    .ads-wrapper .why-credochain__wrapper .heading {
      font-size: 2.5rem;
      padding: 10px; }
  .ads-wrapper .homepage__wrapper {
    padding-top: 10rem;
    position: relative; }
    .ads-wrapper .homepage__wrapper .simple-steps {
      display: block;
      position: absolute;
      right: 10%;
      top: 16%; }
    .ads-wrapper .homepage__wrapper .credochain-works__wrapper > h2 {
      text-align: left;
      padding-left: 3rem;
      font-size: 3.3rem !important;
      width: 60%; }
    .ads-wrapper .homepage__wrapper .credochain-works__wrapper .card-credochain-works__wrapper {
      justify-content: center; }
      .ads-wrapper .homepage__wrapper .credochain-works__wrapper .card-credochain-works__wrapper .card-wrapper {
        background: #00cdbe;
        padding-top: 1rem;
        padding-bottom: 1rem;
        max-width: 90%;
        width: -webkit-fill-available;
        margin-left: 0px; }
        .ads-wrapper .homepage__wrapper .credochain-works__wrapper .card-credochain-works__wrapper .card-wrapper .mobile-steps {
          display: none; }
        .ads-wrapper .homepage__wrapper .credochain-works__wrapper .card-credochain-works__wrapper .card-wrapper .step-number__mobile {
          display: block;
          font-family: Roboto !important;
          font-weight: 900;
          position: absolute;
          right: 6%;
          top: 5%;
          font-size: 3rem;
          color: transparent;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: rgba(255, 255, 255, 0.38); }
        .ads-wrapper .homepage__wrapper .credochain-works__wrapper .card-credochain-works__wrapper .card-wrapper .container {
          margin: 0px;
          padding: 0px;
          color: #ffffff !important; }
          .ads-wrapper .homepage__wrapper .credochain-works__wrapper .card-credochain-works__wrapper .card-wrapper .container h2 {
            margin: 0.2rem 0rem 1.2rem 0rem;
            color: #ffffff; }
  .get-loan {
    display: none; }
  .footer__wrapper {
    display: none; } }

.contact-us__wrapper {
  background-image: linear-gradient(72.23deg, rgba(1, 17, 97, 0.8) -0.01%, rgba(0, 205, 190, 0.8) 100%), url(/static/media/contact-us-bg.6dbaa4b6.png);
  height: 100rem;
  background-size: cover; }
  .contact-us__wrapper .contact-us__inner-wrapper {
    padding: 6rem 3rem 5rem 3rem; }
    .contact-us__wrapper .contact-us__inner-wrapper .card_wrapper {
      padding: 3rem 5rem;
      color: #aeaeae;
      max-width: 72rem; }
      .contact-us__wrapper .contact-us__inner-wrapper .card_wrapper .heading {
        color: #aeaeae;
        font-weight: normal;
        font-size: 2.1rem;
        padding-bottom: 0.8rem;
        padding-top: 2rem; }
      .contact-us__wrapper .contact-us__inner-wrapper .card_wrapper .basic-single {
        padding: 18px 10px;
        border-radius: 10px;
        border: 1px solid #d3d3d3;
        width: 100%; }
        .contact-us__wrapper .contact-us__inner-wrapper .card_wrapper .basic-single::-webkit-input-placeholder {
          color: #aeaeae; }
        .contact-us__wrapper .contact-us__inner-wrapper .card_wrapper .basic-single:-ms-input-placeholder {
          color: #aeaeae; }
        .contact-us__wrapper .contact-us__inner-wrapper .card_wrapper .basic-single::-ms-input-placeholder {
          color: #aeaeae; }
        .contact-us__wrapper .contact-us__inner-wrapper .card_wrapper .basic-single::placeholder {
          color: #aeaeae; }
      .contact-us__wrapper .contact-us__inner-wrapper .card_wrapper .css-yk16xz-control {
        border-radius: 10px; }
      .contact-us__wrapper .contact-us__inner-wrapper .card_wrapper .css-1hwfws3 {
        padding: 14px 10px; }
        .contact-us__wrapper .contact-us__inner-wrapper .card_wrapper .css-1hwfws3 .css-1wa3eu0-placeholder {
          color: #aeaeae; }
      .contact-us__wrapper .contact-us__inner-wrapper .card_wrapper .css-6q0nyr-Svg {
        color: #00cbbd; }
      .contact-us__wrapper .contact-us__inner-wrapper .card_wrapper .button {
        background: #00CDBE;
        border-radius: 0.5rem;
        padding: 1rem 3rem;
        color: #FFFFFF;
        margin-top: 4rem;
        font-size: 2.5rem; }
    .contact-us__wrapper .contact-us__inner-wrapper .contact-us__info {
      padding: 7rem 9rem;
      color: #ffffff; }
      .contact-us__wrapper .contact-us__inner-wrapper .contact-us__info .contact-sub-label {
        font-size: 1.8rem; }
      .contact-us__wrapper .contact-us__inner-wrapper .contact-us__info .contact-label {
        font-weight: 500;
        font-size: 3.2rem; }

@media only screen and (max-width: 1170px) {
  .contact-us__wrapper .row .contact-us__inner-wrapper .contact-us__info {
    padding: 0rem 1rem; } }

html {
  font-size: 62.5%;
  overflow-x: hidden; }

* {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  outline: none;
  font-size: 1.6rem; }

